// JsFromRoutes CacheKey b844a1ffc859c9ea5910ffab49e3c8f5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/payment_method/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/payment_method/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/payment_method'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/subscriptions/:subscription_id/payment_method'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/subscriptions/:subscription_id/payment_method'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/subscriptions/:subscription_id/payment_method'),
}
