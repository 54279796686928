import routes from '@/routes'
import { Account } from '@/types'
import { router } from '@inertiajs/react'

export const switchAccount = (account: Account, returnTo: string) => {
  router.visit(
    routes.accounts.switch.path({
      id: account.id,
    }),
    {
      method: 'patch',
      data: { return_to: returnTo },
    }
  )
}
