import routes from '@/routes'
import { Link, router } from '@inertiajs/react'
import {
  CopyIcon,
  GripVertical,
  MoreHorizontal,
  PencilIcon,
  TrashIcon,
  icons,
} from 'lucide-react'
import { forwardRef, useState } from 'react'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Button, buttonVariants } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Icon } from '@/components/ui/icon'
import { Switch } from '@/components/ui/switch'
import Block from '@/types/blocks/block'
import { cn, i18n } from '@/utils'

const DeleteBlockDialog = ({
  open,
  onChange,
  blockId,
}: {
  open: boolean
  onChange: React.Dispatch<React.SetStateAction<boolean>>
  blockId: number
}) => (
  <AlertDialog open={open} onOpenChange={onChange}>
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          {i18n.t('link_in_bio.blockables.index.delete_dialog.title')}
        </AlertDialogTitle>
        <AlertDialogDescription>
          {i18n.t('link_in_bio.blockables.index.delete_dialog.description')}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel>{i18n.t('cancel')}</AlertDialogCancel>
        <Link
          href={routes.linkInBioBlocks.destroy.path({ id: blockId })}
          method="delete"
          as="button"
          type="button"
          preserveState={false}
        >
          <AlertDialogAction
            className={cn(buttonVariants({ variant: 'destructive' }))}
          >
            {i18n.t('delete')}
          </AlertDialogAction>
        </Link>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
)

const BlockRow = forwardRef(
  (
    {
      block,
      icon,
      label,
      ...props
    }: {
      block: Block
      icon: string
      label: string
      'aria-pressed': boolean | undefined
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false)

    return (
      <div
        {...props}
        className={`flex items-center justify-between rounded-xl px-4 py-2 hover:bg-accent sm:min-w-[254px] md:min-w-[318px] lg:min-w-[350px] ${props['aria-pressed'] ? 'bg-gray-100' : ''}`}
      >
        <div ref={ref} className="flex items-center space-x-3">
          <GripVertical className="text-gray-400" size={16} />
          <Icon name={icon as keyof typeof icons} size={20} />
          <span>{label}</span>
        </div>
        <div className="flex items-center space-x-2">
          <Switch
            checked={block.visible}
            onCheckedChange={() => {
              router.patch(
                routes.linkInBioBlocks.update.path({ id: block.id }),
                {
                  block: { visible: !block.visible },
                },
                { preserveState: false }
              )
            }}
          />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">
                  {i18n.t('link_in_bio.blockables.index.block_row.open_menu')}
                </span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="link-in-bio-block-row-no-drag"
              align="end"
            >
              <Link
                href={routes.linkInBioBlockables.edit.path({
                  block_id: block.id,
                })}
              >
                <DropdownMenuItem>
                  <PencilIcon className="mr-2 h-4 w-4" />
                  {i18n.t('link_in_bio.blockables.index.block_row.edit')}
                </DropdownMenuItem>
              </Link>
              <Link
                href={routes.linkInBioBlocksDuplicates.create.path({
                  block_id: block.id,
                })}
                method="post"
                as="button"
                type="button"
                preserveState={false}
              >
                <DropdownMenuItem>
                  <CopyIcon className="mr-2 h-4 w-4" />
                  {i18n.t('link_in_bio.blockables.index.block_row.duplicate')}
                </DropdownMenuItem>
              </Link>
              <DropdownMenuItem
                variant="destructive"
                onSelect={() => setIsAlertDialogOpen(true)}
              >
                <TrashIcon className="mr-2 h-4 w-4" />
                {i18n.t('link_in_bio.blockables.index.block_row.delete')}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          {isAlertDialogOpen && (
            <DeleteBlockDialog
              open={isAlertDialogOpen}
              onChange={setIsAlertDialogOpen}
              blockId={block.id}
            />
          )}
        </div>
      </div>
    )
  }
)

export default BlockRow
