import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import React from 'react'

interface FaqProps {
  question: string
  answer: string
}

interface FaqListProps {
  faqList: FaqProps[]
}

export const FaqList: React.FC<FaqListProps> = ({ faqList }) => {
  return (
    <section id="faq" className="container py-8 sm:py-16 md:w-[800px]">
      <h3 className="mb-5 text-center text-3xl font-semibold">FAQs</h3>

      <Accordion type="single" collapsible className="AccordionRoot">
        {faqList.map((faq, index) => {
          const uniqueValue = `faq-${index}`
          return (
            <AccordionItem key={uniqueValue} value={uniqueValue}>
              <AccordionTrigger className="text-left">
                {faq.question}
              </AccordionTrigger>
              <AccordionContent>{faq.answer}</AccordionContent>
            </AccordionItem>
          )
        })}
      </Accordion>
    </section>
  )
}
