// JsFromRoutes CacheKey d1b8d50441d463c2e9c293d03d200a91
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/cancel/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/cancel/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/cancel'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/subscriptions/:subscription_id/cancel'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/subscriptions/:subscription_id/cancel'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/subscriptions/:subscription_id/cancel'),
}
