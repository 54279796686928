import { AppLogo } from '@/components/shared/app/app-logo'

const AppLoadingScreen = () => {
  return (
    <div className="flex h-screen items-center justify-center bg-background">
      <div className="animate-bounce">
        <div className="rounded-2xl border-[3px] border-border p-2">
          <AppLogo size="2xl" color="primary" />
        </div>
      </div>
    </div>
  )
}

export default AppLoadingScreen
