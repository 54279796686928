import { Form, FormInput, FormSubmit } from '@/components/shared/inertia-form'
import routes from '@/routes'
import { i18n } from '@/utils'

interface ChangePasswordFormData {
  user: {
    current_password: string
    password: string
    password_confirmation: string
  }
}

const data: ChangePasswordFormData = {
  user: {
    current_password: '',
    password: '',
    password_confirmation: '',
  },
}

const NewPasswordForm = () => {
  return (
    <>
      <Form
        model="user"
        method="patch"
        to={routes.settingsPassword.update.path()}
        data={data}
      >
        <FormInput
          name="current_password"
          type="password"
          placeholder={i18n.t('settings.password.index.current_password')}
          autoComplete="current-password"
          helperText={i18n.t('settings.password.index.current_password_help')}
        />
        <FormInput
          name="password"
          type="password"
          placeholder={i18n.t('settings.password.index.new_password')}
          autoComplete="new-password"
          helperText={i18n.t('settings.password.index.minimum_length', {
            count: 6,
          })}
        />
        <FormInput
          name="password_confirmation"
          type="password"
          placeholder={i18n.t('settings.password.index.confirm_new_password')}
          autoComplete="new-password"
        />
        <FormSubmit className="w-min">
          {i18n.t('settings.password.index.update_password')}
        </FormSubmit>
      </Form>
    </>
  )
}

export default NewPasswordForm
