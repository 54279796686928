// JsFromRoutes CacheKey 66e6dc08e694086519b8a394d5fe975e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/app/creator/products'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/products'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/products/:prefix_id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/products/:prefix_id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/products/:prefix_id'),
}
