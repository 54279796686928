import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import routes from '@/routes'
import ContentItem from '@/types/ContentItem'
import { i18n } from '@/utils'
import { router, usePage } from '@inertiajs/react'
import { PlusIcon } from 'lucide-react'
import { useState } from 'react'

export default function AddFileDialog({ productId }: { productId: number }) {
  const { props } = usePage()
  const assignableFileContentItems =
    (props.assignableFileContentItems as ContentItem[]) || []
  const [selectedFileId, setSelectedFileId] = useState<string>('')

  const fetchassignableFileContentItems = (isDialogOpen: boolean) => {
    if (isDialogOpen) router.reload({ only: ['assignableFileContentItems'] })
  }

  const submit = () => {
    router.post(
      routes.productsContentItems.create.path({
        product_id: productId,
      }),
      { content_item_id: selectedFileId },
      { preserveState: false }
    )
  }

  return (
    <Dialog onOpenChange={(isOpen) => fetchassignableFileContentItems(isOpen)}>
      <DialogTrigger asChild>
        <Button variant="outline">
          <PlusIcon className="mr-2 h-4 w-4" />
          {i18n.t('products.content_dialog.add_file.trigger')}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]" showCloseButton={false}>
        <DialogHeader>
          <DialogTitle className="text-center">
            {i18n.t('products.content_dialog.add_file.title')}
          </DialogTitle>
          <DialogDescription className="text-center">
            {i18n.t('products.content_dialog.add_file.description')}
          </DialogDescription>
        </DialogHeader>

        <div>
          <div className="form-group mt-4">
            <Select
              value={selectedFileId}
              onValueChange={(value) => setSelectedFileId(value)}
            >
              <SelectTrigger id="border_type" className="form-control">
                <SelectValue placeholder={'File'} />
              </SelectTrigger>
              <SelectContent>
                {assignableFileContentItems.map((contentItem) => {
                  return (
                    <SelectItem
                      key={contentItem.id}
                      value={contentItem.id.toString()}
                    >
                      {contentItem.polyItem.name}
                    </SelectItem>
                  )
                })}
              </SelectContent>
            </Select>
            {/* <FormError error={errors.borderType} /> */}
          </div>
        </div>

        <DialogFooter>
          <Button type="submit" onClick={() => submit()}>
            {i18n.t('confirm')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
