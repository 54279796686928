import { AppLogo } from '@/components/shared/app/app-logo'
import { Button, buttonVariants } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Link } from '@/components/ui/link'
import { useAuthorization } from '@/hooks/use-authorization'
import routes from '@/routes'
import { cn } from '@/utils'
import { ChevronUp, X } from 'lucide-react'
import React, { useEffect, useState } from 'react'

interface AdminBannerProps {
  onViewAs: (role: string) => void
  onEditPage: () => void
  onGoToDashboard: () => void
  onClose: () => void
}

const AdminBanner: React.FC<AdminBannerProps> = ({ onViewAs, onClose }) => {
  const [isExpanded, setIsExpanded] = useState(() => {
    const storedState = localStorage.getItem('adminBannerExpanded')
    return storedState ? JSON.parse(storedState) : true
  })
  const [currentView, setCurrentView] = useState('Member')

  useEffect(() => {
    localStorage.setItem('adminBannerExpanded', JSON.stringify(isExpanded))
  }, [isExpanded])

  const toggleExpanded = () => setIsExpanded(!isExpanded)

  const handleClose = () => {
    setIsExpanded(false)
    onClose()
  }

  const handleViewAs = (role: string) => {
    setCurrentView(role)
    onViewAs(role)
  }

  const { isCurrentAccountOwner } = useAuthorization()
  return (
    <>
      {isCurrentAccountOwner && (
        <div
          className={`fixed bottom-2 right-2 z-50 rounded-xl border border-border bg-accent transition-all duration-300 lg:bottom-5 lg:right-5 ${isExpanded ? 'left-2 border border-t py-2 lg:left-5' : 'py-1'}`}
        >
          <div
            className={`flex items-center justify-between px-4 ${isExpanded ? '' : 'hidden'}`}
          >
            <div>
              <AppLogo color="background" />
            </div>
            <div className="flex items-center space-x-2">
              <div className="flex items-center space-x-2">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline" size="xs">
                      View As: {currentView}
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem onClick={() => handleViewAs('Admin')}>
                      Admin
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleViewAs('Member')}>
                      Member
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
                <Link
                  href={routes.linkInBioBlockables.index.path()}
                  className={cn(
                    buttonVariants({ variant: 'outline', size: 'xs' })
                  )}
                >
                  Edit Page
                </Link>
                <Link
                  href={routes.dashboard.show.path()}
                  className={cn(
                    buttonVariants({ variant: 'outline', size: 'xs' })
                  )}
                >
                  Go To Dashboard
                </Link>
              </div>
              <Button
                variant="ghost"
                size="sm"
                className=""
                onClick={handleClose}
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          </div>
          <div className={`flex ${isExpanded ? 'hidden' : ''}`}>
            <Button
              variant="ghost"
              size="sm"
              className=""
              onClick={toggleExpanded}
            >
              <AppLogo color="background" />
              <ChevronUp className="ml-1 h-4 w-4" />
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default AdminBanner
