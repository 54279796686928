// JsFromRoutes CacheKey 1f3752efd7494024d980122d42dc68a8
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/accounts'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/api/v1/accounts'),
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/accounts/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/accounts/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/accounts/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/api/v1/accounts/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/api/v1/accounts/:id'),
}
