import { Button, ButtonProps } from '@/components/ui/button'
import { Submit as SubmitButton, useForm } from '@/lib/use-inertia-form'
import { forwardRef } from 'react'

type FormSubmitProps = ButtonProps & {
  children: React.ReactNode
  requiredFields?: string[]
  useAutoDisable?: boolean // New prop to control auto-disable feature
}

const FormSubmit = forwardRef<HTMLButtonElement, FormSubmitProps>(
  (
    { children, disabled, requiredFields, useAutoDisable = true, ...props },
    ref
  ) => {
    const { isDirty } = useForm()

    // Determine if the button should be disabled
    const isDisabled = disabled || (useAutoDisable && !isDirty)

    return (
      <SubmitButton
        component={Button}
        ref={ref}
        disabled={isDisabled}
        requiredFields={requiredFields}
        {...props}
      >
        {children}
      </SubmitButton>
    )
  }
)

FormSubmit.displayName = 'FormSubmit'

export default FormSubmit
