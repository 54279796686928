import AppWrapper from '@/components/shared/app/app-wrapper'
import AppLayout from '@/layouts/app/app-layout'
import WebsiteLayout from '@/layouts/website/website-layout'
import { isProduction, setupGoogleAnalytics, setupSentry } from '@/utils'
import { createInertiaApp } from '@inertiajs/react'
import { createRoot, hydrateRoot } from 'react-dom/client'

setupSentry()

createInertiaApp({
  resolve: (name) => {
    const pages = import.meta.glob('../pages/**/*.tsx', { eager: true })
    const page: any = pages[`../pages/${name}.tsx`]

    /**
     * Inertia doesn't provide a good way to specify Layout templates,
     * so inferring from the render path is the best option we have at the moment
     */

    page.default.layout =
      page.default.layout ||
      (name.startsWith('website/')
        ? (page: any) => <WebsiteLayout>{page}</WebsiteLayout>
        : (page: any) => <AppLayout>{page}</AppLayout>)
    return page
  },

  setup({ el, App, props }) {
    const appContent = (
      <AppWrapper>
        <App {...props} />
      </AppWrapper>
    )

    if (isProduction()) {
      setupGoogleAnalytics()
      hydrateRoot(el, appContent)
    } else {
      createRoot(el).render(appContent)
    }
  },
  progress: {
    color: '#000000',
  },
})
