import { AppLogotype } from '@/components/shared/app/app-logo'
import { Button, buttonVariants } from '@/components/ui/button'
import { Link } from '@/components/ui/link'
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from '@/components/ui/navigation-menu'
import { Separator } from '@/components/ui/separator'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import { useAuth } from '@/hooks/use-auth'
import routes from '@/routes'
import { cn } from '@/utils'
import { Menu } from 'lucide-react'
import React, { useEffect, useState } from 'react'

interface RouteProps {
  href: string
  label: string
}

const routeList: RouteProps[] = [
  {
    href: routes.websiteStatic.index.path(),
    label: 'Home',
  },
  {
    href: routes.websiteStatic.story.path(),
    label: 'Story',
  },
  {
    label: 'Updates',
    href: routes.websiteStatic.updates.path(),
  },
  {
    href: routes.websiteStatic.pricing.path(),
    label: 'Pricing',
  },
  // TODO: Uncomment when pricing and affiliates program is ready
  // {
  //   href: routes.websiteStatic.affiliates.path(),
  //   label: 'Affiliates',
  // },
]

export const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { isLoggedIn } = useAuth()
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const pageHeight =
        document.documentElement.scrollHeight - window.innerHeight
      const threshold = pageHeight * 0.95

      setIsVisible(scrollPosition < threshold)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <header
      className={`max-7xl container sticky top-5 z-40 mx-auto flex items-center justify-between transition-opacity duration-300 md:w-[70%] lg:w-[75%] lg:max-w-screen-lg ${isVisible ? 'opacity-[95%]' : 'pointer-events-none opacity-0'}`}
    >
      <div className="flex w-full items-center justify-between rounded-xl border border-border bg-accent p-2">
        <div className="ml-1 sm:ml-3">
          <AppLogotype url={routes.websiteStatic.index.path()} />
        </div>
        {/* <!-- Mobile --> */}
        <div className="flex items-center lg:hidden">
          <Sheet open={isOpen} onOpenChange={setIsOpen}>
            <SheetTrigger asChild>
              <Menu
                onClick={() => setIsOpen(!isOpen)}
                className="cursor-pointer lg:hidden"
              />
            </SheetTrigger>

            <SheetContent
              side="left"
              className="flex flex-col justify-between rounded-br-2xl rounded-tr-xl border-secondary bg-card"
            >
              <SheetDescription className="sr-only">
                Navigation menu for mobile devices
              </SheetDescription>
              <div>
                <SheetHeader className="mb-4 ml-4">
                  <SheetTitle className="flex items-center">
                    <div className="font-normal">
                      <AppLogotype url={routes.websiteStatic.index.path()} />
                    </div>
                  </SheetTitle>
                </SheetHeader>

                <div className="flex flex-col gap-1">
                  {routeList.map(({ href, label }) => (
                    <Button
                      key={href}
                      onClick={() => setIsOpen(false)}
                      asChild
                      variant="ghost"
                      className="justify-start text-base font-normal text-muted-foreground"
                    >
                      <Link href={href}>{label}</Link>
                    </Button>
                  ))}
                </div>

                <div className="mt-4 flex flex-col gap-3">
                  {isLoggedIn ? (
                    <Link
                      className={cn(buttonVariants({ size: 'lg' }), 'w-full')}
                      href={routes.dashboard.show.path()}
                    >
                      Dashboard
                    </Link>
                  ) : (
                    <>
                      <Link
                        className={cn(buttonVariants({ size: 'lg' }), 'w-full')}
                        href={routes.usersRegistrations.new.path()}
                      >
                        Get started
                      </Link>
                      <Link
                        className={cn(
                          buttonVariants({ size: 'lg', variant: 'secondary' }),
                          'w-full'
                        )}
                        href={routes.usersSessions.new.path()}
                      >
                        Sign in
                      </Link>
                    </>
                  )}
                </div>
              </div>

              <SheetFooter className="flex-col items-start justify-start sm:flex-col">
                <Separator className="mb-2" />
              </SheetFooter>
            </SheetContent>
          </Sheet>
        </div>

        {/* <!-- Desktop --> */}
        <NavigationMenu className="mx-auto hidden lg:block">
          <NavigationMenuList>
            <NavigationMenuItem>
              {routeList.map(({ href, label }) => (
                <NavigationMenuLink key={href} asChild>
                  <Link
                    href={href}
                    className="px-2 text-sm hover:opacity-60 xl:px-3"
                  >
                    {label}
                  </Link>
                </NavigationMenuLink>
              ))}
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>

        {!isLoggedIn ? (
          <div className="hidden lg:flex">
            <Link
              className="px-2 text-sm hover:opacity-60 xl:px-3"
              href={routes.usersSessions.new.path()}
            >
              Sign in
            </Link>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="ml-4 hidden lg:block">
        {isLoggedIn ? (
          <Link
            className={cn(buttonVariants({ variant: 'default', size: 'lg' }))}
            href={routes.dashboard.show.path()}
          >
            Dashboard
          </Link>
        ) : (
          <Link
            className={cn(buttonVariants({ size: 'lg' }))}
            href={routes.usersRegistrations.new.path()}
          >
            Get started
          </Link>
        )}
      </div>
    </header>
  )
}
