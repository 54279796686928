import { Omniauth } from '@/components/features/auth/omniauth'
import { Form, FormInput, FormSubmit } from '@/components/shared/inertia-form'
import useTypedPage from '@/hooks/use-typed-page'
import routes from '@/routes'
import { InertiaSharedProps } from '@/types'
import { i18n } from '@/utils'

type SignupFormProps = InertiaSharedProps & {
  minimumPasswordLength: number
}

interface SignupFormData {
  user: {
    name: string
    email: string
    password: string
    terms_of_service: string
  }
}

const data: SignupFormData = {
  user: {
    name: '',
    email: '',
    password: '',
    terms_of_service: 'true',
  },
}

const SignupForm = () => {
  const { minimumPasswordLength } = useTypedPage<SignupFormProps>().props

  return (
    <Form model="user" to={routes.usersRegistrations.create.path()} data={data}>
      <FormInput
        name="name"
        type="text"
        placeholder={i18n.t('forms.fields.full_name')}
        autoComplete="name"
      />
      <FormInput
        name="email"
        type="email"
        placeholder={i18n.t('forms.fields.email')}
        autoComplete="email"
      />
      <FormInput
        name="password"
        type="password"
        placeholder={i18n.t('forms.fields.password')}
        autoComplete="new-password"
        helperText={`Minimum ${minimumPasswordLength} characters.`}
      />
      <FormSubmit>{i18n.t('sign_up')}</FormSubmit>
      <Omniauth />
    </Form>
  )
}

export default SignupForm
