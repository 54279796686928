import useTypedPage from '@/hooks/use-typed-page'
import { isInitialized, setUser } from '@sentry/react'
import { FC, useEffect } from 'react'

const SentryUserTracking: FC = () => {
  const currentUser = useTypedPage().props.currentUser

  // == Current user tracking
  useEffect(() => {
    if (isInitialized()) {
      if (currentUser) {
        const { id, email } = currentUser
        setUser({ id, email })
      } else {
        setUser(null)
      }
    }
  }, [currentUser])

  return null
}

export default SentryUserTracking
