import { LucideIcon } from 'lucide-react'
import React, { ReactNode } from 'react'

interface Props {
  icon: LucideIcon
  title: string
  description: string
  action: ReactNode
}

const EmptyState: React.FC<Props> = ({
  icon: Icon,
  title: message,
  description: subMessage,
  action,
}) => {
  return (
    <div className="flex h-full flex-1 items-center justify-center rounded-lg border border-dashed p-8 shadow-sm">
      <div className="flex max-w-md flex-col items-center gap-1 text-center">
        <Icon className="h-10 w-10 text-muted-foreground" />
        <h3 className="mt-4 text-lg font-semibold">{message}</h3>
        <p className="text-sm text-muted-foreground">{subMessage}</p>
        {action && <div className="mt-4">{action}</div>}
      </div>
    </div>
  )
}

export default EmptyState
