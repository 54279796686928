// JsFromRoutes CacheKey 3ebb1e318feff466c16aebf64385eb51
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/course/chapters'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/course/chapters'),
  new: /* #__PURE__ */ definePathHelper('get', '/admin/course/chapters/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/course/chapters/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/course/chapters/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/course/chapters/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/course/chapters/:id'),
}
