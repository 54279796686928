import { FaqList } from '@/components/shared/faq-list'
import Meta from '@/components/shared/meta'
import { Input } from '@/components/ui/input'
import { APP_NAME } from '@/constants'
import useTypedPage from '@/hooks/use-typed-page'
import { DollarSign } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const faqList = [
  {
    question: 'What is a Break-even Price Calculator?',
    answer:
      'A Break-even Price Calculator helps you determine the minimum price you should charge to cover your costs and desired profit margin.',
  },
  {
    question: 'How do I calculate the break-even price?',
    answer:
      'To calculate the break-even price, input the product cost, desired profit margin percentage, and platform fee percentage. The calculator will determine the minimum selling price to cover these costs and achieve your desired profit.',
  },
  {
    question: 'What is the product cost?',
    answer:
      'The product cost is the total cost incurred to produce or acquire the product.',
  },
  {
    question: 'What is the desired profit margin?',
    answer:
      'The desired profit margin is the percentage of profit you want to make on top of the product cost.',
  },
  {
    question: 'What is the platform fee?',
    answer:
      'The platform fee is a percentage of the selling price that the platform charges for using its services.',
  },
]

const BreakEvenPriceCalculatorPage: React.FC = () => {
  const { name, description } = useTypedPage().props
  const [productCost, setProductCost] = useState<number>(100)
  const [desiredProfitMargin, setDesiredProfitMargin] = useState<number>(20) // percentage
  const [platformFee, setPlatformFee] = useState<number>(10) // percentage
  const [minSellingPrice, setMinSellingPrice] = useState<string>('133.33')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const calculateMinSellingPrice = () => {
    const profitMarginAmount = (productCost * desiredProfitMargin) / 100
    const totalCost = productCost + profitMarginAmount
    const minPrice = totalCost / (1 - platformFee / 100)
    setMinSellingPrice(formatter.format(minPrice))
  }

  useEffect(() => {
    calculateMinSellingPrice()
  }, [productCost, desiredProfitMargin, platformFee])

  const metaTitle = `${name} | ${APP_NAME}`
  const metaDescription = description as string

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container mx-auto max-w-5xl">
        <div className="overflow-hidden">
          <div className="mx-auto mb-6 max-w-3xl text-center">
            <h1 className="mb-5 font-heading text-5xl font-medium">
              Break-even Price Calculator
            </h1>
            <p className="text-neutral-500">
              Calculate the minimum price you should charge to cover your costs
              and desired profit margin.
            </p>
          </div>
          <div className="rounded-md border border-border bg-accent p-5">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
              <div className="col-span-1">
                <label htmlFor="product_cost" className="font-semibold">
                  Product Cost (USD)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What is the cost of your product?
                </div>
                <Input
                  type="number"
                  id="product_cost"
                  value={productCost}
                  onChange={(e) => setProductCost(Number(e.target.value))}
                  placeholder="Product Cost"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="desired_profit_margin"
                  className="font-semibold"
                >
                  Desired Profit Margin (%)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What percentage of profit do you want to make?
                </div>
                <Input
                  type="number"
                  id="desired_profit_margin"
                  value={desiredProfitMargin}
                  onChange={(e) =>
                    setDesiredProfitMargin(Number(e.target.value))
                  }
                  placeholder="Desired Profit Margin (%)"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="platform_fee" className="font-semibold">
                  Platform Fee Percentage (%)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What percentage does the platform take?
                </div>
                <Input
                  type="number"
                  id="platform_fee"
                  value={platformFee}
                  onChange={(e) => setPlatformFee(Number(e.target.value))}
                  placeholder="Platform Fee (%)"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="mt-6 rounded-md border border-border bg-background px-4 py-5 sm:p-6">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <DollarSign className="mr-2 inline-block" />
                    Minimum Selling Price:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="min-selling-price-value">{minSellingPrice}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FaqList faqList={faqList} />
      </div>
    </>
  )
}

export default BreakEvenPriceCalculatorPage
