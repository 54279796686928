import { Form, FormInput, FormSubmit } from '@/components/shared/inertia-form'
import routes from '@/routes'

interface ChangePasswordFormProps {
  resetPasswordToken: string
}

interface ChangePasswordFormData {
  user: {
    password: string
    password_confirmation: string
    reset_password_token: string
  }
}

const ChangePasswordForm = ({
  resetPasswordToken,
}: ChangePasswordFormProps) => {
  const data: ChangePasswordFormData = {
    user: {
      password: '',
      password_confirmation: '',
      reset_password_token: resetPasswordToken,
    },
  }

  return (
    <Form
      model="user"
      method="put"
      remember={false}
      to={routes.usersPasswords.update.path()}
      data={data}
    >
      <FormInput
        name="password"
        type="password"
        autoComplete="new-password"
        placeholder="New password"
        required
      />
      <FormInput
        name="password_confirmation"
        type="password"
        autoComplete="new-password"
        placeholder="Confirm new password"
        required
      />
      <FormSubmit>Change password</FormSubmit>
    </Form>
  )
}

export default ChangePasswordForm
