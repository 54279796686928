import Meta from '@/components/shared/meta'
import { APP_NAME } from '@/constants'
import StoryContent from '@/content/story.mdx'
import { MDXProvider } from '@mdx-js/react'
import React from 'react'

const StoryPage: React.FC = () => {
  const metaTitle = `Our Story | ${APP_NAME}`
  const metaDescription = 'Make a living doing what you love.'
  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container prose mx-auto mb-6">
        <h1 className="text-center font-heading text-3xl font-normal">
          50M Creators
          <br />
          <span className="font-normal text-muted-foreground">
            Empowering The Next 100M
          </span>
        </h1>

        <MDXProvider>
          <StoryContent />
        </MDXProvider>
      </div>
    </>
  )
}

export default StoryPage
