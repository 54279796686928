import ContinueOnboardingBanner from '@/components/features/onboarding/continue-onboarding-banner'
import { ProductsTable } from '@/components/features/products/index/products-table'
import NewProductDialog from '@/components/features/products/new-product/new-product-dialog'
import EmptyState from '@/components/shared/empty-state'
import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { ContentSection } from '@/layouts/app/sections/content-section'
import routes from '@/routes'
import { Product } from '@/types'
import { i18n } from '@/utils'
import { Package } from 'lucide-react'

interface ProductsPageProps {
  products: Product[]
}

export default function ProductsPage({ products }: ProductsPageProps) {
  const breadcrumbs = [
    {
      title: i18n.t('application.breadcrumbs.home'),
      link: routes.dashboard.show.path(),
    },
    { title: i18n.t('products.breadcrumbs.title') },
  ]

  return (
    <>
      <ContentSection>
        <ContentSection.Header>
          <DefaultNavbar breadcrumbs={breadcrumbs} />
        </ContentSection.Header>
        <ContentSection.Body>
          {products.length === 0 ? (
            <EmptyState
              icon={Package}
              title={i18n.t('products.empty_state.title')}
              description={i18n.t('products.empty_state.description')}
              action={<NewProductDialog />}
            />
          ) : (
            <ProductsTable products={products} />
          )}

          {products.length > 0 && <ContinueOnboardingBanner />}
        </ContentSection.Body>
      </ContentSection>
    </>
  )
}
