import { i18n } from '@/utils'

export default function ReviewStep() {
  return (
    <div className="grid gap-4 py-4">
      <div className="grid grid-cols-4 items-center gap-4">
        <div className="col-span-4 text-center">
          {i18n.t('products.new_product.review_step.title')}
        </div>
      </div>
    </div>
  )
}
