import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel'
import { LinkInBioTemplate } from '@/types'
import { useCallback, useMemo } from 'react'

interface TemplateCarouselProps {
  templates: LinkInBioTemplate[]
  templateId: LinkInBioTemplate['id']
  onChange: (template: LinkInBioTemplate) => void
}

const TemplateCarousel = ({
  templates,
  templateId,
  onChange,
}: TemplateCarouselProps) => {
  const handleSelect = (api: CarouselApi) => {
    if (!api) return

    const index = api.selectedScrollSnap()
    onChange(templates[index])
  }

  const startIndex = useMemo(
    () => templates.findIndex((template) => template.id === templateId),
    []
  )

  const setApi = useCallback((api: CarouselApi) => {
    api?.on('select', handleSelect)
  }, [])

  return (
    <Carousel
      opts={{
        align: 'center',
        startIndex,
      }}
      setApi={setApi}
      className="relative mx-auto mb-8 w-[80%] sm:w-[90%] lg:max-w-screen-xl"
    >
      <CarouselContent>
        {templates.map((template) => (
          <CarouselItem
            key={template.id}
            className="!flex !w-fit flex-col items-center"
          >
            <div className="my-4 overflow-hidden rounded-lg border">
              <img
                src={template.preview.src}
                alt={template.name}
                className="h-[500px] w-80 object-contain"
              />
            </div>
            <h5>{template.name}</h5>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  )
}

export default TemplateCarousel
