import { FormError } from '@/components/shared/form-error'
import { useInertiaInput } from '@/lib/use-inertia-form'
import { useEffect, useState } from 'react'

interface AvatarInputProps {
  avatarSrc?: string
}

const AvatarInput = ({ avatarSrc }: AvatarInputProps) => {
  const { inputName, inputId, setValue, error } = useInertiaInput<File | null>({
    name: 'avatar',
    model: 'user',
  })

  const [preview, setPreview] = useState<string | null>(null)

  const revokeObjectURL = () => {
    if (!preview) return

    URL.revokeObjectURL(preview)
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setValue(e.target.files[0])

      revokeObjectURL()
      setPreview(URL.createObjectURL(e.target.files[0]))
    } else {
      setValue(null)
      setPreview(null)

      revokeObjectURL()
    }
  }

  useEffect(() => {
    return () => {
      revokeObjectURL()
    }
  }, [])

  return (
    <div>
      <div className="flex flex-row items-center gap-4">
        {(avatarSrc || preview) && (
          <img
            src={preview || avatarSrc}
            alt="Avatar"
            className="size-16 min-w-16 rounded-full"
          />
        )}

        <input
          id={inputId}
          name={inputName}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="file:mr-4 file:rounded-full file:border-0 file:bg-accent file:px-4 file:py-2 file:text-sm file:font-semibold file:text-primary hover:file:bg-neutral-100"
        />
      </div>
      <FormError error={error} />
    </div>
  )
}

export default AvatarInput
