import { FormError } from '@/components/shared/form-error'
import { Button } from '@/components/ui/button'
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import routes from '@/routes'
import { i18n } from '@/utils'
import { router, usePage } from '@inertiajs/react'
import { useState } from 'react'

export default function NewFileDialog() {
  const [fileData, setFileData] = useState({
    type: 'Items::DigitalDownload',
    file: {
      files: [] as File[],
      name: '',
    },
  })
  const { errors } = usePage().props

  const submit = () => {
    router.post(routes.contentItems.create.path(), fileData, {
      forceFormData: true,
      preserveState: 'errors',
    })
  }

  return (
    <div>
      <DialogContent className="sm:max-w-[425px]" showCloseButton={false}>
        <DialogHeader>
          <DialogTitle className="text-center">
            {i18n.t('content_items.index.new_digital_download_dialog.title')}
          </DialogTitle>
          <DialogDescription className="text-center">
            {i18n.t(
              'content_items.index.new_digital_download_dialog.description'
            )}
          </DialogDescription>
        </DialogHeader>

        <div>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="item-name" className="col-span-1 text-right">
                {i18n.t('content_items.index.new_digital_download_dialog.name')}
              </Label>
              <Input
                type="text"
                id="item-name"
                className="col-span-3"
                onChange={(e) => {
                  setFileData((prev) => {
                    return {
                      ...prev,
                      file: { ...prev.file, name: e.target.value },
                    }
                  })
                }}
              />
              {errors?.contentItem?.name && (
                <>
                  <div className="col-span-1"></div>
                  <FormError
                    error={errors?.contentItem?.name}
                    className="col-span-3 mt-0"
                  />
                </>
              )}
            </div>

            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="item-files" className="col-span-1 text-right">
                {i18n.t(
                  'content_items.index.new_digital_download_dialog.files'
                )}
              </Label>
              <Input
                type="file"
                multiple={true}
                id="item-files"
                className="col-span-3"
                onChange={(e) => {
                  setFileData((prev) => {
                    return {
                      ...prev,
                      file: {
                        ...prev.file,
                        files: Array.from(e.target.files ?? []),
                      },
                    }
                  })
                }}
              />
              {errors?.contentItem?.files && (
                <>
                  <div className="col-span-1"></div>
                  <FormError
                    error={errors?.contentItem?.files}
                    className="col-span-3 mt-0"
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <DialogFooter>
          <DialogClose>
            <Button variant="outline">{i18n.t('close')}</Button>
          </DialogClose>
          <Button type="button" onClick={submit}>
            {i18n.t('add')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </div>
  )
}
