import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Link } from '@/components/ui/link'
import useTypedPage from '@/hooks/use-typed-page'
import LinkBlockable from '@/types/blocks/link'
import { ExternalLinkIcon, TriangleAlert } from 'lucide-react'
import { useMemo, useState } from 'react'

const sensitiveDomains = ['onlyfans.com', 'pornhub.com']

export default function LinkPreview(props: LinkBlockable) {
  const { buttonRadius, primaryColor, backgroundColor, fontFamily } =
    useTypedPage().props.currentAccount?.linkInBioTemplate?.settings || {}

  const { url, inNewTab, title } = props
  const [isAlertOpen, setIsAlertOpen] = useState(false)

  const sensitiveDomainsRegex = useMemo(() => {
    const pattern = sensitiveDomains
      .map((domain) => domain.replace('.', '\\.'))
      .join('|')
    return new RegExp(`^(https?:\/\/)?(www\.)?(${pattern})`, 'i')
  }, [])

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (sensitiveDomainsRegex.test(url)) {
      e.preventDefault()
      setIsAlertOpen(true)
    }
  }

  const handleProceed = () => {
    window.open(url, inNewTab ? '_blank' : '_self')
    setIsAlertOpen(false)
  }

  return (
    <>
      <Link
        style={{
          borderRadius: buttonRadius,
          backgroundColor: primaryColor,
          color: backgroundColor,
          fontFamily,
        }}
        href={url}
        target={inNewTab ? '_blank' : '_self'}
        className={`flex flex-col p-2 text-center font-semibold`}
        onClick={(e) => handleClick(e as React.MouseEvent<HTMLAnchorElement>)}
      >
        <span className="flex items-center justify-center">
          {inNewTab ? <ExternalLinkIcon className="mr-2 h-4 w-4" /> : null}
          {title}
        </span>
      </Link>
      <SensitiveDomainsDialog
        isOpen={isAlertOpen}
        onOpenChange={setIsAlertOpen}
        onProceed={handleProceed}
      />
    </>
  )
}

const SensitiveDomainsDialog = ({
  isOpen,
  onOpenChange,
  onProceed,
}: {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  onProceed: () => void
}) => {
  return (
    <AlertDialog open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center">
            <TriangleAlert className="mr-2 h-4 w-4" />
            Sensitive Content Warning
          </AlertDialogTitle>
          <AlertDialogDescription>
            You are about to visit a website that may contain sensitive or adult
            content. Are you sure you want to proceed?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={onProceed}>Proceed</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
