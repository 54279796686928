import { linkInBioBlockables } from '@/routes/generated'

import { i18n } from '@/utils'

import { DividerBlock } from '@/types/blocks/blockable'

import {
  Form,
  FormInput,
  FormSlider,
  FormSubmit,
  FormSelect,
} from '@/components/shared/inertia-form'

const BORDER_TYPES = [
  {
    value: 'thin',
    label: i18n.t('link_in_bio.blockables.forms.divider.thin'),
  },
  {
    value: 'thick',
    label: i18n.t('link_in_bio.blockables.forms.divider.thick'),
  },
  {
    value: 'dashed',
    label: i18n.t('link_in_bio.blockables.forms.divider.dashed'),
  },
  {
    value: 'ellipsis',
    label: i18n.t('link_in_bio.blockables.forms.divider.ellipsis'),
  },
  {
    value: 'invisible',
    label: i18n.t('link_in_bio.blockables.forms.divider.invisible'),
  },
]

type DividerFormData = {
  blockable: {
    id?: number
    type: string
    title: string
    vertical_space: number
    border_type: string
  }
}

type DividerFormProps = {
  editedBlock: DividerBlock
  setEditedBlock: (block: DividerBlock) => void
}

const DividerForm = ({ editedBlock, setEditedBlock }: DividerFormProps) => {
  const { blockable, blockableType } = editedBlock
  const { id, title, verticalSpace, borderType } = blockable

  const data: DividerFormData = {
    blockable: {
      id: id,
      type: blockableType,
      title,
      vertical_space: verticalSpace,
      border_type: borderType,
    },
  }

  const handleChange = ({ data }: { data: DividerFormData }) => {
    const {
      blockable: { title, vertical_space, border_type },
    } = data

    setEditedBlock({
      ...editedBlock,
      blockable: {
        id,
        title,
        verticalSpace: vertical_space,
        borderType: border_type,
      },
    })
  }

  return (
    <Form
      model="blockable"
      method={id ? 'put' : 'post'}
      to={
        id
          ? linkInBioBlockables.update.path({ id })
          : linkInBioBlockables.create.path()
      }
      data={data}
      onChange={handleChange}
    >
      <FormInput
        name="title"
        label={i18n.t('link_in_bio.blockables.forms.divider.title')}
        required
      />

      <FormSlider
        label={i18n.t('link_in_bio.blockables.forms.divider.vertical_space')}
        name="vertical_space"
        min={0}
        max={200}
        step={5}
      />

      <FormSelect
        label={i18n.t('link_in_bio.blockables.forms.divider.border_type')}
        name="border_type"
        placeholder={i18n.t('forms.placeholders.select')}
        options={BORDER_TYPES}
      />

      <FormSubmit>{i18n.t('save')}</FormSubmit>
    </Form>
  )
}

export default DividerForm
