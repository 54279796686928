import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Progress } from '@/components/ui/progress'
import routes from '@/routes'
import { router } from '@inertiajs/react'
import React, { useCallback, useRef, useState } from 'react'
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from './canvas-preview'

import useTypedPage from '@/hooks/use-typed-page'
import { DependencyList, useEffect } from 'react'

export function useDebounceEffect(
  fn: () => void,
  waitTime: number,
  deps?: DependencyList
) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn()
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
  }, deps)
}

interface AvatarUploadDialogProps {
  isOpen: boolean
  onClose: () => void
  file: File | null
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

const AvatarUploadDialog: React.FC<AvatarUploadDialogProps> = ({
  isOpen,
  onClose,
  file,
}) => {
  const { currentAccount } = useTypedPage().props
  const [imgSrc, setImgSrc] = useState<string | null>(null)
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale] = useState(1)
  const [rotate] = useState(0)
  const [aspect] = useState<number | undefined>(1)
  const [uploadProgress, setUploadProgress] = useState<number>(0)

  React.useEffect(() => {
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImgSrc(reader.result as string)
      }
      reader.readAsDataURL(file)
    } else {
      setImgSrc(null)
    }
  }, [file])

  const onImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      if (aspect) {
        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, aspect))
      }
    },
    [aspect]
  )

  useDebounceEffect(
    () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        )
      }
    },
    100,
    [completedCrop, scale, rotate]
  )

  const handleConfirm = useCallback(async () => {
    if (previewCanvasRef.current) {
      const canvas = previewCanvasRef.current
      canvas.toBlob((blob) => {
        if (blob) {
          const formData = new FormData()
          formData.append('account[avatar]', blob, 'avatar.jpg')

          router.patch(
            routes.accounts.update.path({ id: currentAccount?.id }),
            formData,
            {
              preserveState: true,
              preserveScroll: true,
              onProgress: (progress) => {
                setUploadProgress(progress?.percentage ?? 0)
              },
            }
          )
        }
      }, 'image/jpeg')
    }
  }, [previewCanvasRef, currentAccount])

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload Profile Picture</DialogTitle>
        </DialogHeader>
        <div className="flex flex-row items-center justify-center p-6">
          {imgSrc && (
            <div className="max-w-[450px flex max-h-[450px]">
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
          )}
          {completedCrop && (
            <div className="mt-4">
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </div>
          )}
          {uploadProgress > 0 && (
            <div className="mt-4 w-full">
              <Progress value={uploadProgress} className="w-full" />
            </div>
          )}
        </div>
        <DialogFooter className="sm:justify-end">
          <Button type="button" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleConfirm}
            disabled={uploadProgress > 0}
          >
            Confirm Upload
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AvatarUploadDialog
