import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { APP_DOMAIN, APP_NAME as appName } from '@/constants'
import { useToast } from '@/hooks/use-toast'
import useTypedPage from '@/hooks/use-typed-page'
import { i18n } from '@/utils'
import { Copy, Facebook, Linkedin, Share, Twitter } from 'lucide-react'
import DividerPreview from './block-previews/divider'
import FaqPreview from './block-previews/faq'
import ImagePreview from './block-previews/image'
import LinkPreview from './block-previews/link'
import TextPreview from './block-previews/text'

import { BLOCKABLE_TYPES } from '@/constants/blocks/available-forms'
import { BlockType } from '@/types/blocks/blockable'

const LinkInBioPreview = ({
  blocks,
  editedBlockId,
  editedBlockRef,
}: {
  blocks: BlockType[]
  editedBlockId: number | null
  editedBlockRef: React.RefObject<HTMLDivElement> | null
}) => {
  const { toast } = useToast()
  const { currentAccount } = useTypedPage().props
  const creatorLink = `${APP_DOMAIN}/${currentAccount?.subdomain}`

  return (
    <>
      <div className="mb-4 flex items-center space-x-2">
        <Input id="link" defaultValue={creatorLink} readOnly />
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            navigator.clipboard.writeText(creatorLink)
            toast({
              title: i18n.t('link_in_bio.blockables.preview.copied_short'),
              description: i18n.t('link_in_bio.blockables.preview.copied_long'),
              duration: 3000,
            })
          }}
        >
          <Copy className="mr-2 h-4 w-4" />
          {i18n.t('link_in_bio.blockables.preview.copy')}
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="sm">
              <Share className="mr-2 h-4 w-4" />
              {i18n.t('link_in_bio.blockables.preview.share')}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem
              onClick={() =>
                navigator.clipboard.writeText(
                  `https://twitter.com/intent/tweet?url=${encodeURIComponent(creatorLink)}`
                )
              }
            >
              <Twitter className="mr-2 h-4 w-4" />
              Twitter
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() =>
                navigator.clipboard.writeText(
                  `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(creatorLink)}`
                )
              }
            >
              <Facebook className="mr-2 h-4 w-4" />
              Facebook
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() =>
                navigator.clipboard.writeText(
                  `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(creatorLink)}`
                )
              }
            >
              <Linkedin className="mr-2 h-4 w-4" />
              LinkedIn
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className="mx-auto flex max-h-[600px] w-80 flex-col rounded-3xl bg-white shadow-lg">
        <div className="space-y-4 overflow-y-scroll p-4">
          <div className="mb-4 flex flex-col items-center">
            <div className="mb-2 h-20 w-20 rounded-full bg-gray-300"></div>
            <h2 className="text-xl font-bold">funnyd</h2>
            <p className="text-center text-sm">
              Master of transitions and travel
            </p>
            <div className="mt-2 flex space-x-2">
              <div className="h-6 w-6 rounded-full bg-gray-300"></div>
              <div className="h-6 w-6 rounded-full bg-gray-300"></div>
            </div>
          </div>

          {blocks.map((block) => {
            return (
              block.visible && (
                <div
                  className={`${block.id === editedBlockId ? 'scroll-mb-4 rounded-lg bg-primary/20' : ''}`}
                  ref={block.id === editedBlockId ? editedBlockRef : null}
                  key={block.id}
                >
                  <>
                    {block.blockableType === BLOCKABLE_TYPES.Link && (
                      <LinkPreview {...block.blockable} />
                    )}
                    {block.blockableType === BLOCKABLE_TYPES.Text && (
                      <TextPreview {...block.blockable} />
                    )}
                    {block.blockableType === BLOCKABLE_TYPES.Divider && (
                      <DividerPreview {...block.blockable} />
                    )}
                    {block.blockableType === BLOCKABLE_TYPES.Faq && (
                      <FaqPreview {...block.blockable} />
                    )}
                    {block.blockableType === BLOCKABLE_TYPES.Image && (
                      <ImagePreview {...block.blockable} />
                    )}
                  </>
                </div>
              )
            )
          })}
        </div>
      </div>

      <div className="mx-auto mt-4 flex w-80 items-center space-x-2">
        <Switch />
        <span>
          {i18n.t('link_in_bio.blockables.preview.hide_logo_footer', {
            app: appName,
          })}
        </span>
        <span className="rounded bg-purple-100 px-2 py-1 text-xs text-purple-800">
          PRO
        </span>
      </div>
    </>
  )
}

export default LinkInBioPreview
