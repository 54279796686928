import ResetPasswordForm from '@/components/features/auth/forms/reset-password-form'
import Meta from '@/components/shared/meta'
import { Link } from '@/components/ui/link'
import { APP_NAME } from '@/constants'
import AuthLayout from '@/layouts/auth-layout'
import routes from '@/routes'
import { ReactNode } from 'react'

const ResetPasswordPage = () => {
  const metaTitle = `Reset password | ${APP_NAME}`
  const metaDescription = `Reset your password for ${APP_NAME}`

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="mb-4 flex flex-col space-y-2">
        <h1 className="text-center text-xl font-semibold tracking-tight">
          Reset your password
        </h1>
      </div>

      <ResetPasswordForm />

      <p className="mt-4 border-t border-border pt-5 text-center text-sm text-muted-foreground">
        <Link
          href={routes.usersConfirmations.new.path()}
          className="underline underline-offset-4 hover:text-primary"
        >
          Didn't receive confirmation instructions?
        </Link>
      </p>
    </>
  )
}

ResetPasswordPage.layout = (page: ReactNode) => <AuthLayout>{page}</AuthLayout>

export default ResetPasswordPage
