// JsFromRoutes CacheKey c60b5c565eca83e37fe28d7f7d3bbb52
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/course/lessons'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/course/lessons'),
  new: /* #__PURE__ */ definePathHelper('get', '/admin/course/lessons/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/course/lessons/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/course/lessons/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/course/lessons/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/course/lessons/:id'),
}
