import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Dialog } from '@/components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Input } from '@/components/ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import routes from '@/routes'
import ContentItem from '@/types/ContentItem'
import { i18n } from '@/utils'
import { Link } from '@inertiajs/react'
import {
  ColumnFiltersState,
  Column as ColumnType,
  Row,
  SortingState,
  Table as TableType,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  ArrowUpDown,
  BookOpenText,
  ChevronDown,
  CopyIcon,
  File,
  MoreHorizontal,
  PlusIcon,
  TrashIcon,
} from 'lucide-react'
import { useState } from 'react'
import NewCourseDialog from './new-course/new-course-dialog'
import NewFileDialog from './new-file-dialog'

interface ContentTableProps {
  items: ContentItem[]
}

type TableColumnProps<T> = {
  table: TableType<T>
  column: ColumnType<T>
}

type TableRowProps<T> = {
  row: Row<T>
}

export const columns = [
  {
    accessorKey: 'name',
    header: ({ column }: TableColumnProps<ContentItem>) => {
      return (
        <Button
          className="p-0"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }: TableRowProps<ContentItem>) => (
      <Link
        className="hover:underline"
        href={routes.contentItems.edit.path({ id: row.original.id })}
      >
        {row.original.polyItem.name}
      </Link>
    ),
  },
  {
    accessorKey: 'typeForDisplay',
    header: 'Content Type',
    cell: ({ row }: TableRowProps<ContentItem>) => (
      <Badge variant="secondary">{row.getValue('typeForDisplay')}</Badge>
    ),
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }: TableRowProps<ContentItem>) => {
      const contentItem = row.original

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>
              <CopyIcon className="mr-2 h-4 w-4" />
              <Link
                method="post"
                href={routes.contentItems.duplicate.path({
                  id: contentItem.id,
                })}
              >
                {i18n.t(
                  'content_items.index.content_items_table.actions.duplicate'
                )}
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem variant="destructive">
              <TrashIcon className="mr-2 h-4 w-4" />
              <Link
                href={routes.contentItems.destroy.path({ id: contentItem.id })}
                method="delete"
                preserveState={false}
              >
                {i18n.t(
                  'content_items.index.content_items_table.actions.delete'
                )}
              </Link>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
  },
]

export function ContentTable({ items }: ContentTableProps) {
  const [itemDialog, setItemDialog] = useState('')
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = useState({})

  const table = useReactTable({
    data: items,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <Input
          placeholder="Search content..."
          value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
          onChange={(event) =>
            table.getColumn('name')?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto mr-2">
              Columns <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>

        <Dialog
          open={itemDialog !== ''}
          onOpenChange={(open) => (!open ? setItemDialog('') : null)}
        >
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="default">
                <PlusIcon className="mr-2 h-4 w-4" />
                Create content
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>Content types</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem onClick={() => setItemDialog('file')}>
                  <File className="mr-2 h-4 w-4" />
                  <span>File</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setItemDialog('course')}>
                  <BookOpenText className="mr-2 h-4 w-4" />
                  <span>Course</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>

          {itemDialog === 'file' && <NewFileDialog />}
          {itemDialog === 'course' && <NewCourseDialog />}
        </Dialog>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {i18n.t('products.products_table.no_results')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{' '}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}
