import { Button } from '@/components/ui/button'
import { Link } from '@/components/ui/link'
import routes from '@/routes'
import { useEffect, useState } from 'react'

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (!hasUserResponded()) {
      setIsVisible(true)
    }
  }, [])

  const hasUserResponded = (): boolean => {
    return localStorage.getItem('cookieConsent') !== null
  }

  const setUserConsent = (value: boolean): void => {
    localStorage.setItem('cookieConsent', value.toString())
  }

  const handleReject = (): void => {
    setUserConsent(false)
    setIsVisible(false)
  }

  const handleAccept = (): void => {
    setUserConsent(true)
    setIsVisible(false)
  }

  if (!isVisible) return null

  return (
    <section className="pointer-events-auto fixed bottom-0 left-0 z-50 flex w-full items-center justify-center border-t border-t-white/20 bg-primary px-[6%] py-3 md:px-[10%] lg:px-[15%]">
      <div className="xs:max-w-lg flex max-w-none sm:max-w-2xl md:max-w-4xl lg:max-w-6xl">
        <p className="m-0 flex items-center pr-6">
          <span className="inline-block text-justify text-[0.8em] text-background">
            We use cookies (and other similar technologies) for many purposes,
            including to improve your experience on our site and for ads and
            analytics. Click "Accept all" to accept these uses. Read more in our{' '}
            <Link
              href={routes.websiteStatic.cookiePolicy.path()}
              className="underline hover:no-underline"
            >
              Cookie Policy
            </Link>
            .
          </span>
        </p>
        <div className="flex flex-col items-center justify-center gap-2 sm:flex-row">
          <Button onClick={handleReject} data-component-name="reject" size="sm">
            Reject all
          </Button>
          <Button
            variant="secondary"
            onClick={handleAccept}
            data-component-name="consent"
            size="sm"
          >
            Accept all
          </Button>
        </div>
      </div>
    </section>
  )
}

export default CookieBanner
