import Meta from '@/components/shared/meta'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Link } from '@/components/ui/link'
import { APP_NAME } from '@/constants'
import routes from '@/routes'
import { PageProps } from '@inertiajs/core'
import { router, usePage } from '@inertiajs/react'
import { useState } from 'react'
import ReactMarkdown from 'react-markdown'

interface ChangelogEntry {
  id: string
  title: string
  markdownDetails: string
  created: string
  publishedAt: string
  labels: Array<{
    id: string
    name: string
  }>
  types: string[]
  url: string
}

interface UpdatesProps {
  entries: ChangelogEntry[]
  hasMore: boolean
  currentSkip: number
  error?: string
}

export default function Changelog() {
  const { entries, hasMore, currentSkip } = usePage<UpdatesProps & PageProps>()
    .props
  const [loading, setLoading] = useState(false)

  const loadMore = () => {
    setLoading(true)
    router.get(
      '/updates/load_more',
      { skip: currentSkip + 1 },
      {
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false),
      }
    )
  }
  const metaTitle = `Updates | ${APP_NAME}`

  return (
    <>
      <Meta title={metaTitle} />
      <h1 className="mb-8 text-center font-heading text-3xl">Updates</h1>
      <div className="container mx-auto max-w-3xl">
        {entries && entries.length > 0 ? (
          <div className="space-y-8">
            {entries.map((entry) => (
              <Card
                className="border-none bg-transparent shadow-none"
                key={entry.id}
              >
                <CardHeader>
                  <div className="mb-2 flex flex-row items-center space-x-2">
                    {entry.types &&
                      entry.types.length > 0 &&
                      entry.types.map((type) => (
                        <Badge key={`${entry.id}-${type}`} variant="secondary">
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                        </Badge>
                      ))}
                  </div>
                  <CardTitle>
                    <Link
                      className="hover:underline"
                      href={entry.url}
                      target="_blank"
                    >
                      {entry.title}
                    </Link>
                  </CardTitle>
                  <p className="text-sm text-muted-foreground">
                    {new Date(entry.publishedAt).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </p>
                </CardHeader>
                <CardContent>
                  <ReactMarkdown className="prose">
                    {entry.markdownDetails}
                  </ReactMarkdown>
                </CardContent>
              </Card>
            ))}
          </div>
        ) : (
          <p className="py-8 text-center text-xl text-muted-foreground">
            No updates available at the moment.
          </p>
        )}
        {hasMore && (
          <div className="mt-8">
            <Button onClick={loadMore} disabled={loading}>
              {loading ? 'Loading...' : 'Load More'}
            </Button>
          </div>
        )}
        <div className="mt-8 flex justify-center">
          <Link href={routes.websiteStatic.index.path()}>
            <Button variant="outline" size="lg">
              Back to Home
            </Button>
          </Link>
        </div>
      </div>
    </>
  )
}
