import { Button } from '@/components/ui/button'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { i18n } from '@/utils'
import { Megaphone } from 'lucide-react'
import React, { useEffect } from 'react'

const Changelog: React.FC = () => {
  useEffect(() => {
    // Load Canny SDK
    const script = document.createElement('script')
    script.src = 'https://canny.io/sdk.js'
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      // Initialize Canny
      if (typeof (window as any).Canny === 'function') {
        ;(window as any).Canny('initChangelog', {
          appID: '669ba62ecddcd3a4023bb9aa',
          position: 'bottom',
          align: 'right',
          theme: 'light',
        })
      }
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            size="icon"
            variant="ghost"
            className="rounded-xl text-muted-foreground"
            data-canny-changelog
          >
            <Megaphone size={20} />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{i18n.t('application.navbar.changelog.title')}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default Changelog
