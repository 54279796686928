// JsFromRoutes CacheKey 5af74f99c56a92bf4e63a4a0d09c1ff0
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/accounts'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/accounts'),
  new: /* #__PURE__ */ definePathHelper('get', '/admin/accounts/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/accounts/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/accounts/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/accounts/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/accounts/:id'),
}
