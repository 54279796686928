// JsFromRoutes CacheKey 3e1c0ac5368e57eaeaabba5fa15ebe64
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/auth/resend-confirmation/new'),
  show: /* #__PURE__ */ definePathHelper('get', '/auth/resend-confirmation'),
  create: /* #__PURE__ */ definePathHelper('post', '/auth/resend-confirmation'),
}
