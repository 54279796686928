import Meta from '@/components/shared/meta'
import { buttonVariants } from '@/components/ui/button'
import { Link } from '@/components/ui/link'
import React from 'react'

interface Tool {
  name: string
  description: string
  slug: string
}

interface Props {
  tools: Tool[]
}

const ToolsPage: React.FC<Props> = ({ tools }) => {
  const metaTitle = `Explore Creator Calculators, Converters & Tools`
  const metaDescription =
    'Browse through our collection of calculators, converters, and tools to help you grow your creator business.'

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container mb-12">
        <div className="flex flex-col">
          <h1 className="mx-auto mb-3 max-w-3xl text-center font-heading text-4xl">
            Creator Calculators & Tools
          </h1>
          <div className="mb-10 text-center text-neutral-500">
            Browse through our collection of free tools to help you grow your
            creator business.
          </div>
          <div
            className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4"
            id="tools_container"
          >
            {tools.map((tool, index) => (
              <div
                key={index}
                className="overflow-hidden rounded-xl border border-border bg-accent p-4 shadow-sm"
              >
                <h2 className="text-primary-600 mb-2 text-xl font-semibold">
                  {tool.name}
                </h2>
                <p className="mb-4 text-sm text-neutral-500">
                  {tool.description}
                </p>
                <Link
                  className={buttonVariants({ variant: 'default' })}
                  href={`/tools/${tool.slug}`}
                >
                  Try it →
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default ToolsPage
