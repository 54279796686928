// JsFromRoutes CacheKey 56969069afb5a4e9fccb92fdeec98063
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  cancel: /* #__PURE__ */ definePathHelper('get', '/auth/cancel'),
  new: /* #__PURE__ */ definePathHelper('get', '/auth/signup'),
  edit: /* #__PURE__ */ definePathHelper('get', '/auth/settings/profile/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/auth'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/auth'),
  create: /* #__PURE__ */ definePathHelper('post', '/auth'),
}
