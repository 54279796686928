import {
  DashboardOverview,
  DashboardWizard,
} from '@/components/features/dashboard'
import { HelpCard } from '@/components/features/dashboard/help-card'
import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { Separator } from '@/components/ui/separator'
import useTypedPage from '@/hooks/use-typed-page'
import { ContentSection } from '@/layouts/app/sections/content-section'
import routes from '@/routes'
import { InertiaSharedProps } from '@/types'
import { i18n } from '@/utils'

interface DashboardPageProps extends InertiaSharedProps {
  wizardStep: string
}

export default function DashboardPage() {
  const { wizardStep } = useTypedPage<DashboardPageProps>().props

  const breadcrumbs = [
    {
      title: i18n.t('application.breadcrumbs.home'),
      link: routes.dashboard.show.path(),
    },
    { title: 'Dashboard' },
  ]

  return (
    <ContentSection>
      <ContentSection.Header>
        <DefaultNavbar breadcrumbs={breadcrumbs} />
      </ContentSection.Header>

      <ContentSection.Body>
        {wizardStep === 'done' ? <DashboardOverview /> : <DashboardWizard />}
        <Separator className="my-4" />
        <HelpCard />
      </ContentSection.Body>
    </ContentSection>
  )
}
