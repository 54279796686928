export const BLOCKABLE_TYPES = {
  Link: 'Blocks::Link',
  Text: 'Blocks::Text',
  Divider: 'Blocks::Divider',
  Faq: 'Blocks::Faq',
  Image: 'Blocks::Image',
  Store: 'Blocks::Store',
} as const

export const BOOLEAN_STRING_OPTIONS = {
  True: 'true',
  False: 'false',
}
