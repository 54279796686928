import { CalEmbed } from '@/components/features/website/cal-embed'
import Meta from '@/components/shared/meta'
import { APP_NAME, BUSINESS_EMAIL } from '@/constants'

export default function TalkToUsPage() {
  const metaTitle = `Talk to us | ${APP_NAME}`
  const metaDescription = 'Schedule a meeting with us'
  const calLink = 'axelgrubba/crevio-demo'

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container lg:mt-8">
        <CalEmbed calLink={calLink} />
        <div className="mt-8 text-center text-sm text-muted-foreground lg:mt-4">
          Wanna just send an email? Get in touch with us at{' '}
          <a
            className="underline"
            href={`mailto:${BUSINESS_EMAIL}?subject=Getting started with ${APP_NAME}`}
          >
            {BUSINESS_EMAIL}
          </a>
        </div>
      </div>
    </>
  )
}
