import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { ContentSection } from '@/layouts/app/sections/content-section'
import routes from '@/routes'
import { i18n } from '@/utils'

export default function Members() {
  const breadcrumbs = [
    {
      title: i18n.t('application.breadcrumbs.home'),
      link: routes.dashboard.show.path(),
    },
    { title: i18n.t('members.breadcrumbs.title') },
  ]

  return (
    <>
      <ContentSection>
        <ContentSection.Header>
          <DefaultNavbar breadcrumbs={breadcrumbs} />
        </ContentSection.Header>
        <ContentSection.Body></ContentSection.Body>
      </ContentSection>
    </>
  )
}
