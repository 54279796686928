// JsFromRoutes CacheKey b0b05c4d2c126ec147727e84486659d5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  billingSettings: /* #__PURE__ */ definePathHelper('patch', '/app/creator/subscriptions/billing_settings'),
  index: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/subscriptions'),
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/subscriptions/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/subscriptions/:id'),
}
