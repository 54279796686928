import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Product } from '@/types'
import { i18n } from '@/utils'
import {
  ArchiveIcon,
  CopyIcon,
  MoreHorizontalIcon,
  TrashIcon,
} from 'lucide-react'

interface ProductActionsMenuProps {
  product: Product
  setIsAlertDialogOpen: (isOpen: boolean) => void
}

export default function ProductActionsMenu({
  setIsAlertDialogOpen,
}: ProductActionsMenuProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">
            {i18n.t('products.products_table.open_menu')}
          </span>
          <MoreHorizontalIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem>
          <ArchiveIcon className="mr-2 h-4 w-4" />
          {i18n.t('products.products_table.actions.archive')}
        </DropdownMenuItem>
        <DropdownMenuItem>
          <CopyIcon className="mr-2 h-4 w-4" />
          {i18n.t('products.products_table.actions.duplicate')}
        </DropdownMenuItem>
        <DropdownMenuItem
          variant="destructive"
          onSelect={() => setIsAlertDialogOpen(true)}
        >
          <TrashIcon className="mr-2 h-4 w-4" />
          {i18n.t('products.products_table.actions.delete')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
