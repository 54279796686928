import { AppLogotype } from '@/components/shared/app/app-logo'
import { buttonVariants } from '@/components/ui/button'
import { Link } from '@/components/ui/link'
import FullScreenLayout from '@/layouts/full-screen-layout'
import routes from '@/routes'
import { cn, i18n } from '@/utils'
import { WrenchIcon } from 'lucide-react'
import { ReactNode } from 'react'

const UnavailablePage = () => {
  return (
    <>
      <div className="flex min-h-screen flex-col items-center justify-center">
        <AppLogotype size="lg" />

        <div className="mt-16 text-center">
          <div className="mb-8 inline-block rounded-xl border border-border p-4 shadow-md">
            <WrenchIcon className="inline-block h-16 w-16 text-neutral-400" />
          </div>
          <h1 className="mb-4 font-heading text-3xl text-primary">
            {i18n.t('link_in_bio.unavailable.title')}
          </h1>
        </div>

        <div className="mt-16 text-center">
          <p className="mb-4 text-muted-foreground">
            {i18n.t('link_in_bio.unavailable.is_your_page')}
          </p>
          <p className="mb-8 text-muted-foreground">
            {i18n.t('link_in_bio.unavailable.login_instruction')}
          </p>

          <div className="flex w-full max-w-xs flex-col gap-4">
            <Link
              className={cn(buttonVariants({ variant: 'default' }), 'w-full')}
              href={routes.usersSessions.new.path()}
            >
              {i18n.t('link_in_bio.unavailable.login_button')}
            </Link>

            <Link
              className={cn(buttonVariants({ variant: 'secondary' }), 'w-full')}
              href={routes.usersRegistrations.new.path()}
            >
              {i18n.t('link_in_bio.unavailable.signup_button')}
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

UnavailablePage.layout = (page: ReactNode) => (
  <FullScreenLayout>{page}</FullScreenLayout>
)

export default UnavailablePage
