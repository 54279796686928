import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { ContentSection } from '@/layouts/app/sections/content-section'

import Preview from '@/components/features/link-in-bio/preview'

import DividerForm from '@/components/features/link-in-bio/forms/divider'
import FaqForm from '@/components/features/link-in-bio/forms/faq'
import ImageForm from '@/components/features/link-in-bio/forms/image/image'
import LinkForm from '@/components/features/link-in-bio/forms/link'
import StoreForm from '@/components/features/link-in-bio/forms/store'
import TextForm from '@/components/features/link-in-bio/forms/text'
import { Button } from '@/components/ui/button'
import { BLOCKABLE_TYPES } from '@/constants/blocks/available-forms'
import routes from '@/routes'
import { Link } from '@inertiajs/react'
import { useEffect, useRef, useState } from 'react'

import { BlockType, StoreBlock } from '@/types/blocks/blockable'

import { InertiaSharedProps } from '@/types'
import { i18n } from '@/utils'

type EditProps = InertiaSharedProps & {
  blocks: BlockType[]
  editedBlockPosition: number
}

export default function Edit(props: EditProps) {
  const { editedBlockPosition } = props
  const newBlock = null
  const [blocks, setBlocks] = useState(props.blocks)
  const [editedBlock, setEditedBlock] = useState(blocks[editedBlockPosition])
  const editedBlockPreviewRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (editedBlockPreviewRef.current) {
      editedBlockPreviewRef.current.scrollIntoView(false)
    }
  }, [editedBlockPreviewRef.current])

  useEffect(() => {
    setBlocks(() => {
      return blocks.map((block) => {
        if (block.id !== editedBlock.id) return block

        return editedBlock
      })
    })
  }, [editedBlock])

  const newBlockExists = newBlock !== undefined && newBlock !== null
  const editedBlockPositionExists =
    editedBlockPosition !== undefined && editedBlockPosition !== null
  const editedBlockId =
    editedBlock?.id && (newBlockExists || editedBlockPositionExists)
      ? editedBlock.id
      : null

  const breadcrumbs = [
    {
      title: i18n.t('application.breadcrumbs.home'),
      link: routes.dashboard.show.path(),
    },
    { title: 'Link-in-Bio', link: routes.linkInBioBlockables.index.path() },
    { title: 'Compose', link: routes.linkInBioBlockables.index.path() },
  ]

  return (
    <ContentSection>
      <ContentSection.Header>
        <DefaultNavbar breadcrumbs={breadcrumbs} />
      </ContentSection.Header>

      <ContentSection.Body>
        <div className="flex flex-wrap">
          <div className="flex w-full">
            <div className="w-full lg:w-1/2">
              <div className="rounded-lg border border-border bg-background p-4">
                <Link href={routes.linkInBioBlockables.index.path()}>
                  <Button variant="outline">{i18n.t('back')}</Button>
                </Link>
                <>
                  {editedBlock.blockableType === BLOCKABLE_TYPES.Link && (
                    <LinkForm
                      editedBlock={editedBlock}
                      setEditedBlock={setEditedBlock}
                    />
                  )}
                  {editedBlock.blockableType === BLOCKABLE_TYPES.Text && (
                    <TextForm
                      editedBlock={editedBlock}
                      setEditedBlock={setEditedBlock}
                    />
                  )}
                  {editedBlock.blockableType === BLOCKABLE_TYPES.Divider && (
                    <DividerForm
                      editedBlock={editedBlock}
                      setEditedBlock={setEditedBlock}
                    />
                  )}
                  {editedBlock.blockableType === BLOCKABLE_TYPES.Faq && (
                    <FaqForm
                      editedBlock={editedBlock}
                      setEditedBlock={setEditedBlock}
                    />
                  )}
                  {editedBlock.blockableType === BLOCKABLE_TYPES.Image && (
                    <ImageForm
                      editedBlock={editedBlock}
                      setEditedBlock={setEditedBlock}
                    />
                  )}
                  {editedBlock.blockableType === BLOCKABLE_TYPES.Store && (
                    <StoreForm
                      editedBlock={editedBlock}
                      setEditedBlock={
                        setEditedBlock as React.Dispatch<
                          React.SetStateAction<StoreBlock>
                        >
                      }
                    />
                  )}
                </>
              </div>
            </div>

            <div className="w-full lg:w-1/2">
              <Preview
                blocks={blocks}
                editedBlockId={editedBlockId}
                editedBlockRef={editedBlockPreviewRef}
              />
            </div>
          </div>
        </div>
      </ContentSection.Body>
    </ContentSection>
  )
}
