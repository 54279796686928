import { FormError } from '@/components/shared/form-error'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { i18n } from '@/utils'
import PricingOption from '@/types/generated/PricingOption'

type PricingTypeStepProps = {
  pricingData: Omit<PricingOption, 'id'>
  setPricingData: (key: keyof PricingOption, value: any) => void
  errors: { [K in keyof PricingOption]?: string | null }
}

export default function PricingTypeStep({
  pricingData,
  setPricingData,
  errors,
}: PricingTypeStepProps) {
  return (
    <div className="grid gap-4 py-4">
      <RadioGroup
        defaultValue={pricingData.type}
        className="grid grid-cols-1 gap-4"
      >
        <div
          className="flex items-center space-x-2 rounded-lg border p-4"
          onClick={() => setPricingData('type', 'paid')}
        >
          <RadioGroupItem
            className="max-w-4s max-h-4 min-h-4 min-w-4"
            value="paid"
            id="paid"
          />
          <Label htmlFor="paid" className="flex flex-col">
            <span className="font-medium">
              {i18n.t('products.new_product.pricing_type_step.paid')}
            </span>
            <span className="text-sm text-gray-500">
              {i18n.t(
                'products.new_product.pricing_type_step.paid_description'
              )}
            </span>
          </Label>
        </div>
        <div
          className="flex items-center space-x-2 rounded-lg border p-4"
          onClick={() => setPricingData('type', 'free')}
        >
          <RadioGroupItem
            className="max-w-4s max-h-4 min-h-4 min-w-4"
            value="free"
            id="free"
          />
          <Label htmlFor="free" className="flex flex-col">
            <span className="font-medium">
              {i18n.t('products.new_product.pricing_type_step.free')}
            </span>
            <span className="text-sm text-gray-500">
              {i18n.t(
                'products.new_product.pricing_type_step.free_description'
              )}
            </span>
          </Label>
        </div>
      </RadioGroup>
      {errors.type && (
        <>
          <div className="col-span-1"></div>
          <FormError error={errors.type} className="col-span-3 mt-0" />
        </>
      )}
    </div>
  )
}
