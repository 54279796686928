import { TrackingCodes } from '@/types'
import React from 'react'

interface InjectedHeadCodesProps {
  trackingCodes: TrackingCodes
}

const InjectedHeadCodes: React.FC<InjectedHeadCodesProps> = ({
  trackingCodes,
}) => {
  if (!trackingCodes) {
    return null
  }

  return (
    <>
      <script dangerouslySetInnerHTML={{ __html: '<!-- Tracking Codes -->' }} />
      {trackingCodes.headerCode && (
        <script
          dangerouslySetInnerHTML={{ __html: trackingCodes.headerCode }}
        />
      )}

      {trackingCodes.googleAnalyticsId && (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${trackingCodes.googleAnalyticsId}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${trackingCodes.googleAnalyticsId}');
            `,
            }}
          />
        </>
      )}

      {trackingCodes.metaPixelId && (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${trackingCodes.metaPixelId}');
              fbq('track', 'PageView');
            `,
            }}
          />
          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              src={`https://www.facebook.com/tr?id=${trackingCodes.metaPixelId}&ev=PageView&noscript=1`}
            />
          </noscript>
        </>
      )}

      {trackingCodes.tiktokPixelId && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
            !function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
              ttq.load('${trackingCodes.tiktokPixelId}');
              ttq.page();
            }(window, document, 'ttq');
          `,
          }}
        />
      )}

      {trackingCodes.twitterPixelId && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
            !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):
            s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            twq('config','${trackingCodes.twitterPixelId}');
          `,
          }}
        />
      )}

      {trackingCodes.pinterestPixelId && (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `
              !function(e){if(!window.pintrk){window.pintrk = function () {
              window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
              n=window.pintrk;n.queue=[],n.version="3.0";var
              t=document.createElement("script");t.async=!0,t.src=e;var
              r=document.getElementsByTagName("script")[0];
              r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
              pintrk('load','${trackingCodes.pinterestPixelId}');
              pintrk('page');
            `,
            }}
          />
        </>
      )}

      {trackingCodes.redditPixelId && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
            !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);
            rdt('init','${trackingCodes.redditPixelId}');
            rdt('track','PageVisit');
          `,
          }}
        />
      )}
    </>
  )
}

export default InjectedHeadCodes
