// JsFromRoutes CacheKey 1493751f3115fc776bd6de244ab1e443
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/plans'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/plans'),
  new: /* #__PURE__ */ definePathHelper('get', '/admin/plans/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/plans/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/plans/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/plans/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/plans/:id'),
}
