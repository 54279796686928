import {
  Form,
  FormInput,
  FormSubmit,
  FormTextarea,
} from '@/components/shared/inertia-form'
import { Separator } from '@/components/ui/separator'
import routes from '@/routes'
import { TrackingCodes } from '@/types'

type TrackingCodesFormProps = {
  trackingCodes: TrackingCodes
}

export default function TrackingCodesForm({
  trackingCodes,
}: TrackingCodesFormProps) {
  const data = {
    tracking_code: {
      google_analytics_id: trackingCodes.googleAnalyticsId || '',
      meta_pixel_id: trackingCodes.metaPixelId || '',
      tiktok_pixel_id: trackingCodes.tiktokPixelId || '',
      twitter_pixel_id: trackingCodes.twitterPixelId || '',
      pinterest_pixel_id: trackingCodes.pinterestPixelId || '',
      reddit_pixel_id: trackingCodes.redditPixelId || '',
      header_code: trackingCodes.headerCode || '',
      footer_code: trackingCodes.footerCode || '',
    },
  }

  const pixelTypes = [
    'google_analytics_id',
    'meta_pixel_id',
    'tiktok_pixel_id',
    'twitter_pixel_id',
    'pinterest_pixel_id',
    'reddit_pixel_id',
  ] as const

  return (
    <Form
      model="tracking_code"
      method="patch"
      to={routes.settingsAdvanced.update.path()}
      data={data}
      className="space-y-6"
    >
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        {pixelTypes.map((pixelType) => (
          <FormInput
            key={pixelType}
            name={pixelType}
            label={pixelType
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
              .replace('Id', 'ID')}
          />
        ))}
      </div>
      <Separator className="my-4" />
      <FormTextarea
        name="header_code"
        label="Header Code"
        rows={5}
        helperText="Add custom scripts or code to be inserted in the <head> section of your page"
      />
      <FormTextarea
        name="footer_code"
        label="Footer Code"
        rows={5}
        helperText="Add custom scripts or code to be inserted before the closing </body> tag of your page"
      />
      <FormSubmit>Update tracking codes</FormSubmit>
    </Form>
  )
}
