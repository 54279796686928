import * as ReactSelect from '@radix-ui/react-select'
import { Check } from 'lucide-react'

import { Option } from './form-select'

interface FormSelectItemProps {
  option: Option
}

const FormSelectItem = ({ option }: FormSelectItemProps) => {
  return (
    <ReactSelect.Item
      value={option.value}
      className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
    >
      <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
        <ReactSelect.ItemIndicator>
          <Check className="h-4 w-4" />
        </ReactSelect.ItemIndicator>
      </span>

      <ReactSelect.ItemText>{option.label}</ReactSelect.ItemText>
    </ReactSelect.Item>
  )
}

export default FormSelectItem
