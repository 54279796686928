// App details
export const APP_NAME = 'Crevio'
export const APP_DOMAIN = 'crevio.co'
export const APP_META_SITE_TITLE = 'Crevio | Easily sell your digital products'
export const APP_META_SITE_DESCRIPTION =
  'Crevio is a simple, yet powerful platform to sell digital products. We make it easy to earn from your first dollar to your millionth dollar online.'
export const APP_META_SITE_IMAGE = '/banner.png'
export const APP_FAVICON_PATH = '/favicon.ico'
export const APP_FAVICON_DEV_PATH = '/favicon_dev.ico'
export const APP_APPLE_TOUCH_ICON_PATH = '/apple-touch-icon.png'
export const APP_WEB_MANIFEST_PATH = '/site.webmanifest'

// Socials
export const SOCIAL_LINKS = {
  X: 'https://x.com/CrevioLabs',
  Instagram: 'https://www.instagram.com/crevio.co',
  TikTok: 'https://www.tiktok.com/@crevio.co',
  LinkedIn: 'https://www.linkedin.com/company/creviohq',
}

// Business details
export const BUSINESS_NAME = 'Soho Media Ltd'
export const BUSINESS_ADDRESS = `128 City Road, London EC1V 2NX, United Kingdom`
export const BUSINESS_EMAIL = 'hello@crevio.co'
export const SUPPORT_EMAIL = 'support@crevio.co'

// Canny
export const CANNY_CHANGELOG_URL = 'https://crevio.canny.io/changelog'
export const CANNY_FEATURE_REQUESTS_URL =
  'https://crevio.canny.io/feature-requests'

export const ONBOARDING_STEPS = {
  Subdomain: 'subdomain',
  ContentNiche: 'content_niches',
  ReferralSource: 'referral_source',
} as const
