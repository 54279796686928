import { Check, ChevronsUpDown, PlusCircle } from 'lucide-react'
import * as React from 'react'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import useTypedPage from '@/hooks/use-typed-page'
import Account from '@/types/generated/Account'
import { cn } from '@/utils'
import { switchAccount } from '@/utils/account'
import { NewAccountDialog } from '../new-account-dialog'

type PopoverTriggerProps = React.ComponentPropsWithoutRef<typeof PopoverTrigger>

interface AccountSwitcherProps extends PopoverTriggerProps {}

export default function AccountSwitcher({ className }: AccountSwitcherProps) {
  const { currentUser, railsContext } = useTypedPage().props
  const [open, setOpen] = React.useState(false)
  const [showNewAccountDialog, setShowNewAccountDialog] = React.useState(false)

  // Use usePage to get the latest currentAccount
  const { currentAccount: latestCurrentAccount } = useTypedPage().props

  // Use the latest currentAccount from usePage
  const selectedAccount = latestCurrentAccount || null

  const accounts = currentUser?.accounts || []

  const handleAccountSwitch = (account: Account) => {
    setOpen(false)
    switchAccount(account, railsContext.path)
  }

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select an account"
            className={cn('w-[200px] justify-between', className)}
          >
            <Avatar className="mr-2 h-5 w-5">
              <AvatarImage
                src={selectedAccount?.avatar?.src}
                alt={selectedAccount?.name}
                className="grayscale"
              />
              <AvatarFallback>
                {selectedAccount?.name ? selectedAccount.name.charAt(0) : ''}
              </AvatarFallback>
            </Avatar>
            {selectedAccount?.name}
            <ChevronsUpDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[240px] p-0">
          <Command>
            <CommandList>
              <CommandEmpty>No account found.</CommandEmpty>
              <CommandGroup>
                {accounts.map((account: Account) => (
                  <CommandItem
                    key={account.id}
                    onSelect={() => handleAccountSwitch(account)}
                    className="text-sm"
                  >
                    <Avatar className="mr-2 h-5 w-5">
                      <AvatarImage
                        src={account.avatar?.src}
                        alt={account.name}
                        className="grayscale"
                      />
                      <AvatarFallback>{account.name.charAt(0)}</AvatarFallback>
                    </Avatar>
                    {account.name}
                    <Check
                      className={cn(
                        'ml-auto h-4 w-4',
                        selectedAccount?.id === account.id
                          ? 'opacity-100'
                          : 'opacity-0'
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
            <CommandSeparator />
            <CommandList>
              <CommandGroup>
                <CommandItem
                  onSelect={() => {
                    setOpen(false)
                    setShowNewAccountDialog(true)
                  }}
                >
                  <PlusCircle className="mr-2 h-5 w-5" />
                  Create Account
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <NewAccountDialog
        open={showNewAccountDialog}
        onOpenChange={setShowNewAccountDialog}
      />
    </>
  )
}
