import { Link } from '@/components/ui/link'
import useTypedPage from '@/hooks/use-typed-page'
import routes from '@/routes'

const ImpersonationBanner = () => {
  const { isImpersonating, currentUser } = useTypedPage().props

  if (!isImpersonating) {
    return null
  }

  return (
    <div className="fixed bottom-0 right-0 z-50 flex items-center justify-center rounded-tl-md bg-primary px-3 py-1.5 text-xs text-background shadow-md">
      <span className="mr-2">
        Logged in as{' '}
        <b>
          <Link
            nonInertia={true}
            className="underline"
            href={routes.adminUsers.show.path({ id: currentUser?.id })}
          >
            {`${currentUser?.name} (${currentUser?.email})`}
          </Link>
        </b>
      </span>
      <Link
        className="ml-1 rounded-md bg-background px-2 py-0.5 font-semibold text-primary hover:bg-background/90"
        href={routes.adminUserImpersonates.destroy.path({
          user_id: currentUser?.id,
        })}
        method="delete"
      >
        Logout
      </Link>
    </div>
  )
}

export default ImpersonationBanner
