'use client'

import { Check, ChevronsUpDown } from 'lucide-react'
import * as React from 'react'

import { FormError } from '@/components/shared/inertia-form/form-error'
import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { NestedObject, useInertiaInput } from '@/lib/use-inertia-form'
import { cn } from '@/utils'

export type Option = { value: string; label: string }

export interface IFormComboboxProps {
  options: Option[]
  label?: string
  name: string
  model?: string
  placeholder?: string
  required?: boolean
}

const FormCombobox = <TForm extends NestedObject = NestedObject>({
  options = [],
  label,
  required,
  name,
  model,
  placeholder = 'Select an option...',
}: IFormComboboxProps) => {
  const { inputName, inputId, value, setValue, error } = useInertiaInput<
    string,
    TForm
  >({
    name,
    model,
  })

  const [open, setOpen] = React.useState(false)

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <Label required={required} htmlFor={inputId}>
          {label}
        </Label>
      )}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between rounded-md shadow-none"
            id={inputId}
            name={inputName}
          >
            {value
              ? options.find((option) => option.value === value)?.label
              : placeholder}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full rounded-md p-2">
          <Command>
            <CommandInput placeholder="Search options..." />
            <CommandList>
              <CommandEmpty>No option found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    onSelect={(currentValue) => {
                      setValue(currentValue === value ? '' : currentValue)
                      setOpen(false)
                    }}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        value === option.value ? 'opacity-100' : 'opacity-0'
                      )}
                    />
                    {option.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <FormError name={name} error={error} />
    </div>
  )
}

export default FormCombobox
