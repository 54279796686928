import { Button } from '@/components/ui/button'

import { Link } from '@/components/ui/link'

import { cn, i18n } from '@/utils'

import {
  Form,
  FormSelect,
  FormSubmit,
  FormTextarea,
} from '@/components/shared/inertia-form'
import { ONBOARDING_STEPS } from '@/constants'
import routes from '@/routes'
import { useMemo, useState } from 'react'

type ContentNicheFormValues = {
  onboarding: {
    content_niche: string
    other_content_niche: string | null
    step: typeof ONBOARDING_STEPS.ContentNiche
  }
}

interface ContentNicheFormProps {
  contentNiches: string[]
  contentNiche?: string
  otherContentNiche?: string
}

const ContentNicheForm = ({
  contentNiches,
  contentNiche,
  otherContentNiche,
}: ContentNicheFormProps) => {
  const [showOtherContentNiche, setShowOtherContentNiche] = useState(
    contentNiche === 'other'
  )

  const data: ContentNicheFormValues = {
    onboarding: {
      content_niche: contentNiche || '',
      other_content_niche: otherContentNiche || null,
      step: ONBOARDING_STEPS.ContentNiche,
    },
  }

  const contentNicheOptions = useMemo(
    () =>
      contentNiches.map((niche) => ({
        label: i18n.t(`onboarding.content_niches.${niche}`),
        value: niche,
      })),
    [contentNiches]
  )

  const handleChange = ({ data }: { data: ContentNicheFormValues }) => {
    const {
      onboarding: { content_niche },
    } = data

    setShowOtherContentNiche(content_niche === 'other')
  }

  return (
    <div className="flex flex-col justify-center gap-4">
      <h2 className="text-2xl font-bold">
        {i18n.t('onboarding.content_niches_title')}
      </h2>

      <Form
        model="onboarding"
        method="patch"
        to={routes.onboarding.update.path()}
        data={data}
        onChange={handleChange}
        remember={false}
      >
        <FormSelect
          name="content_niche"
          placeholder={i18n.t('select_option')}
          options={contentNicheOptions}
          required
        />

        <FormTextarea
          className={cn({ hidden: !showOtherContentNiche })}
          name="other_content_niche"
          placeholder={i18n.t('onboarding.other_placeholder')}
        />

        <div className="flex justify-between">
          <Button asChild variant="secondary">
            <Link
              href={routes.onboarding.show.path({
                query: { step: ONBOARDING_STEPS.Subdomain },
              })}
            >
              {i18n.t('back')}
            </Link>
          </Button>

          <FormSubmit className="self-end">{i18n.t('continue')}</FormSubmit>
        </div>
      </Form>
    </div>
  )
}

export default ContentNicheForm
