import { ContentTable } from '@/components/features/content/content-table'
import ContinueOnboardingBanner from '@/components/features/onboarding/continue-onboarding-banner'
import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { ContentSection } from '@/layouts/app/sections/content-section'
import routes from '@/routes'
import ContentItem from '@/types/ContentItem'
import { i18n } from '@/utils'

const breadcrumbs = [
  {
    title: i18n.t('application.breadcrumbs.home'),
    link: routes.dashboard.show.path(),
  },
  { title: i18n.t('content_items.breadcrumbs.title') },
]

export default function Content(props: { items: ContentItem[] }) {
  const { items } = props

  return (
    <>
      <ContentSection>
        <ContentSection.Header>
          <DefaultNavbar breadcrumbs={breadcrumbs} />
        </ContentSection.Header>
        <ContentSection.Body>
          <ContentTable items={items} />
          {items.length > 0 && <ContinueOnboardingBanner />}
        </ContentSection.Body>
      </ContentSection>
    </>
  )
}
