import { FaqList } from '@/components/shared/faq-list'
import Meta from '@/components/shared/meta'
import { Input } from '@/components/ui/input'
import { APP_NAME } from '@/constants'
import useTypedPage from '@/hooks/use-typed-page'
import { Percent } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const faqList = [
  {
    question: 'What is a Profit Margin Calculator?',
    answer:
      'A Profit Margin Calculator helps you understand your profit margins based on your product pricing and production costs.',
  },
  {
    question: 'How do I calculate the profit margin?',
    answer:
      'To calculate the profit margin, input the product price and production costs. The calculator will show the profit margin percentage.',
  },
  {
    question: 'What is the product price?',
    answer: 'The product price is the amount you charge for your product.',
  },
  {
    question: 'What are production costs?',
    answer:
      'Production costs include all expenses incurred to produce your product, including platform fees.',
  },
]

const ProfitMarginCalculatorPage: React.FC = () => {
  const { name, description } = useTypedPage().props
  const [productPrice, setProductPrice] = useState<number>(100)
  const [productionCosts, setProductionCosts] = useState<number>(50)
  const [profitMargin, setProfitMargin] = useState<number>(50)

  const calculateProfitMargin = () => {
    const margin = ((productPrice - productionCosts) / productPrice) * 100
    setProfitMargin(margin)
  }

  useEffect(() => {
    calculateProfitMargin()
  }, [productPrice, productionCosts])

  const metaTitle = `${name} | ${APP_NAME}`
  const metaDescription = description as string

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container mx-auto max-w-5xl">
        <div className="overflow-hidden">
          <div className="mx-auto mb-6 max-w-3xl text-center">
            <h1 className="mb-5 font-heading text-5xl font-medium">
              Profit Margin Calculator
            </h1>
            <p className="text-neutral-500">
              Helps creators understand their profit margins based on pricing
              and production costs.
            </p>
          </div>
          <div className="rounded-md border border-border bg-accent p-5">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
              <div className="col-span-1">
                <label htmlFor="product_price" className="font-semibold">
                  Product Price (USD)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  How much do you charge for your product?
                </div>
                <Input
                  type="number"
                  id="product_price"
                  value={productPrice}
                  onChange={(e) => setProductPrice(Number(e.target.value))}
                  placeholder="Product Price"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="production_costs" className="font-semibold">
                  Production Costs (USD)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What are your total production costs?
                </div>
                <Input
                  type="number"
                  id="production_costs"
                  value={productionCosts}
                  onChange={(e) => setProductionCosts(Number(e.target.value))}
                  placeholder="Production Costs"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="mt-6 rounded-md border border-border bg-background px-4 py-5 sm:p-6">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <Percent className="mr-2 inline-block" />
                    Profit Margin:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="profit-margin-value">
                      {profitMargin.toFixed(2)}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FaqList faqList={faqList} />
      </div>
    </>
  )
}

export default ProfitMarginCalculatorPage
