import { FaqList } from '@/components/shared/faq-list'
import Meta from '@/components/shared/meta'
import { Input } from '@/components/ui/input'
import { APP_NAME } from '@/constants'
import useTypedPage from '@/hooks/use-typed-page'
import { DollarSign } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const faqList = [
  {
    question: 'What is a Product Pricing Calculator?',
    answer:
      'A Product Pricing Calculator helps you determine the ideal price range for your product based on its type, your audience size, and your desired earnings.',
  },
  {
    question: 'How do I calculate the recommended product price?',
    answer:
      'To calculate the recommended product price, input the product type, audience size, and desired earnings. The calculator will suggest an ideal price range for your product.',
  },
  {
    question: 'What is the product type?',
    answer:
      'The product type refers to the category of your product, such as an ebook, course, or software.',
  },
  {
    question: 'What is the audience size?',
    answer:
      'The audience size is the number of potential customers you can reach with your product.',
  },
  {
    question: 'What are desired earnings?',
    answer:
      'Desired earnings are the total amount of money you aim to make from selling your product.',
  },
]

const ProductPricingCalculatorPage: React.FC = () => {
  const { name, description } = useTypedPage().props
  const [productType, setProductType] = useState<string>('ebook')
  const [audienceSize, setAudienceSize] = useState<number>(1000)
  const [desiredEarnings, setDesiredEarnings] = useState<number>(5000)
  const [recommendedPriceRange, setRecommendedPriceRange] =
    useState<string>('5.00 - 10.00')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const calculateRecommendedPriceRange = () => {
    const minPrice = desiredEarnings / audienceSize
    const maxPrice = minPrice * 2 // Example logic: max price is twice the min price
    setRecommendedPriceRange(
      `${formatter.format(minPrice)} - ${formatter.format(maxPrice)}`
    )
  }

  useEffect(() => {
    calculateRecommendedPriceRange()
  }, [productType, audienceSize, desiredEarnings])

  const metaTitle = `${name} | ${APP_NAME}`
  const metaDescription = description as string

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container mx-auto max-w-5xl">
        <div className="overflow-hidden">
          <div className="mx-auto mb-6 max-w-3xl text-center">
            <h1 className="mb-5 font-heading text-5xl font-medium">
              Product Pricing Calculator
            </h1>
            <p className="text-neutral-500">
              Suggests ideal pricing based on product type, audience size, and
              creator’s goals.
            </p>
          </div>
          <div className="rounded-md border border-border bg-accent p-5">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
              <div className="col-span-1">
                <label htmlFor="product_type" className="font-semibold">
                  Product Type
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What type of product are you selling?
                </div>
                <Input
                  type="text"
                  id="product_type"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                  placeholder="Product Type"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="audience_size" className="font-semibold">
                  Audience Size
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  How many potential customers do you have?
                </div>
                <Input
                  type="number"
                  id="audience_size"
                  value={audienceSize}
                  onChange={(e) => setAudienceSize(Number(e.target.value))}
                  placeholder="Audience Size"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="desired_earnings" className="font-semibold">
                  Desired Earnings (USD)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  How much do you want to earn from this product?
                </div>
                <Input
                  type="number"
                  id="desired_earnings"
                  value={desiredEarnings}
                  onChange={(e) => setDesiredEarnings(Number(e.target.value))}
                  placeholder="Desired Earnings"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="mt-6 rounded-md border border-border bg-background px-4 py-5 sm:p-6">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <DollarSign className="mr-2 inline-block" />
                    Recommended Price Range:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="recommended-price-range-value">
                      {recommendedPriceRange}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FaqList faqList={faqList} />
      </div>
    </>
  )
}

export default ProductPricingCalculatorPage
