import { AppLogotype } from '@/components/shared/app/app-logo'
import { Link } from '@/components/ui/link'
import routes from '@/routes'

export default function ReferralBanner() {
  const referralParams = new URLSearchParams({
    utm_source: 'creviodotco',
    utm_medium: 'website',
    utm_content: 'powered_by_crevio',
    utm_campaign: 'bottom-page-link',
  })

  const referralUrl = `${routes.websiteStatic.index.path()}?${referralParams.toString()}`

  return (
    <div
      id="referral-banner"
      className="mb-8 flex w-full justify-center lg:fixed lg:bottom-5 lg:left-5 lg:right-0 lg:mb-0 lg:w-auto lg:justify-start"
    >
      <Link
        href={referralUrl}
        target="_blank"
        className="flex items-center space-x-3 rounded-xl border border-border bg-background px-4 py-2 text-sm shadow-sm transition-all hover:bg-background hover:shadow-lg"
      >
        <AppLogotype size="xs" />
        <span className="text-[13px] text-muted-foreground">
          Create your own page
        </span>
      </Link>
    </div>
  )
}
