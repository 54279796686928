// JsFromRoutes CacheKey a4fe3598bd7c563523cf3f0899863a55
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  switch: /* #__PURE__ */ definePathHelper('patch', '/app/creator/accounts/:id/switch'),
  index: /* #__PURE__ */ definePathHelper('get', '/app/creator/accounts'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/accounts'),
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/accounts/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/accounts/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/accounts/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/accounts/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/accounts/:id'),
}
