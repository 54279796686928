import { AppLogo, AppLogotype } from '@/components/shared/app/app-logo'
import AccountSwitcher from '@/components/shared/sidebar/account-switcher'
import Nav from '@/components/shared/sidebar/nav'
import {
  bottomSideLinks,
  sidelinks,
} from '@/components/shared/sidebar/sidebar-links'
import { Button } from '@/components/ui/button'
import routes from '@/routes'
import { cn } from '@/utils'
import { Menu, PanelRightOpen, X } from 'lucide-react'
import { useEffect, useState } from 'react'

interface SidebarProps extends React.HTMLAttributes<HTMLElement> {
  isCollapsed: boolean
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Sidebar({
  className,
  isCollapsed,
  setIsCollapsed,
}: SidebarProps) {
  const [navOpened, setNavOpened] = useState(false)

  /* Make body not scrollable when navBar is opened */
  useEffect(() => {
    if (navOpened) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [navOpened])

  return (
    <aside
      className={cn(
        `fixed bottom-0 top-0 z-50 flex w-full flex-col pb-2.5 pl-3 pr-3 pt-4 transition-[width] md:left-3 md:right-auto md:h-svh md:pl-0 ${isCollapsed ? 'md:w-14' : 'md:w-64'}`,
        className
      )}
    >
      {/* Overlay in mobile */}
      <div
        onClick={() => setNavOpened(false)}
        className={`absolute inset-0 transition-[opacity] delay-100 duration-700 ${navOpened ? 'h-svh opacity-100' : 'h-0 opacity-0'} w-full bg-background md:hidden`}
      />

      <div className={`flex h-full flex-col ${navOpened ? 'h-svh' : ''}`}>
        {/* Header */}
        <div
          className={`sticky top-0 z-50 flex items-center justify-between max-sm:py-0 max-sm:pl-1 md:px-2 ${
            !isCollapsed ? 'pt-2.5 md:pl-5' : 'flex-col space-y-2 pt-5'
          } `}
        >
          <div>
            {isCollapsed ? (
              <AppLogo url={routes.dashboard.show.path()} />
            ) : (
              <AppLogotype url={routes.dashboard.show.path()} />
            )}
          </div>
          <div className="hidden md:block">
            <Button
              onClick={() => setIsCollapsed((prev) => !prev)}
              variant="link"
              className="z-50 mt-2 px-2 text-muted-foreground md:mt-0"
            >
              <PanelRightOpen
                className={`h-4 w-4 ${isCollapsed ? 'rotate-180' : ''}`}
              />
            </Button>
          </div>

          {/* Toggle Button in mobile */}
          <Button
            variant="ghost"
            size="icon"
            className="w-6 md:hidden"
            aria-label="Toggle Navigation"
            aria-controls="sidebar-menu"
            aria-expanded={navOpened}
            onClick={() => setNavOpened((prev) => !prev)}
          >
            {navOpened ? <X /> : <Menu />}
          </Button>
        </div>
        <AccountSwitcher
          className={`my-2 w-full ${isCollapsed ? 'hidden' : ''}`}
        />
        {/* Navigation links */}
        <Nav
          id="sidebar-menu"
          className={`z-40 flex-1 overflow-auto ${navOpened ? 'max-h-screen' : 'max-h-0 py-0 md:max-h-screen md:py-2'}`}
          closeNav={() => setNavOpened(false)}
          isCollapsed={isCollapsed}
          links={sidelinks}
        />

        {/* TODO: Add back in when we have paid plans */}
        {/* {!isCollapsed ? <UpgradeCta /> : null} */}

        <Nav
          id="sidebar-bottom-menu"
          className={`z-40 mt-auto ${navOpened ? 'max-h-screen' : 'hidden max-h-0 py-0 md:block md:max-h-screen md:py-2'}`}
          closeNav={() => setNavOpened(false)}
          isCollapsed={isCollapsed}
          links={bottomSideLinks}
        />
      </div>
    </aside>
  )
}
