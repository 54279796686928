import { Toaster } from '@/components/ui/toaster'
import useFlashToast from '@/hooks/use-flash-toast'
import useTypedPage from '@/hooks/use-typed-page'
import ImpersonationBanner from './impersonation-banner'
import Meta from './meta'
import SentryUserTracking from './tracking/sentry-user-tracking'

export interface LayoutWrapperProps {
  children: React.ReactNode
}

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children }) => {
  const { flash } = useTypedPage().props
  useFlashToast(flash)

  return (
    <>
      <SentryUserTracking />
      <Meta />
      {children}
      <Toaster />
      <ImpersonationBanner />
    </>
  )
}

export default LayoutWrapper
