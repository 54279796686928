// JsFromRoutes CacheKey 3475f8d1e18a7cf0b55ce4b0a99895ac
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  markAsRead: /* #__PURE__ */ definePathHelper('patch', '/notifications/mark_as_read'),
  index: /* #__PURE__ */ definePathHelper('get', '/notifications'),
  show: /* #__PURE__ */ definePathHelper('get', '/notifications/:id'),
}
