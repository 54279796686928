// JsFromRoutes CacheKey 38e5c81b5c0628c5448ccb086a950283
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  duplicate: /* #__PURE__ */ definePathHelper('post', '/app/creator/content/:id/duplicate'),
  index: /* #__PURE__ */ definePathHelper('get', '/app/creator/content'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/content'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/content/:id/edit'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/content/:id'),
}
