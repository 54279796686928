import { router } from '@inertiajs/react'
import { FC, useEffect } from 'react'

interface MiniProfiler {
  pageTransition: () => void
}

declare global {
  interface Window {
    MiniProfiler?: MiniProfiler
  }
}

const MiniProfilerTracking: FC = () => {
  useEffect(() => {
    return router.on('start', () => {
      window.MiniProfiler?.pageTransition()
    })
  }, [])
  return null
}

export default MiniProfilerTracking
