import useTypedPage from '@/hooks/use-typed-page'

export const useAuth = () => {
  const { currentUser } = useTypedPage().props

  return {
    currentUser,
    isLoggedIn: !!currentUser,
    isSuperAdmin: currentUser?.admin,
    // Add more auth-related functions here if needed
  }
}
