import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { MoreHorizontal } from 'lucide-react'
import { useState } from 'react'

import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import routes from '@/routes'
import ContentItem from '@/types/ContentItem'
import { i18n } from '@/utils'
import { Link } from '@inertiajs/react'

interface ContentItemsTableProps {
  contentItems: ContentItem[]
  productId: number
}

export function ContentItemsTable({
  contentItems,
  productId,
}: ContentItemsTableProps) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = useState({})

  const columns: ColumnDef<ContentItem>[] = [
    {
      accessorKey: 'name',
      enableHiding: false,
      enableSorting: false,
      header: () => (
        <div>{i18n.t('products.content_items_table.headers.name')}</div>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.original.polyItem.name}</div>
      ),
    },
    {
      accessorKey: 'typeForDisplay',
      enableHiding: false,
      enableSorting: false,
      header: () => (
        <div>{i18n.t('products.content_items_table.headers.type')}</div>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue('typeForDisplay')}</div>
      ),
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const contentItem = row.original

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">
                  {i18n.t('products.content_items_table.actions.open_menu')}
                </span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>
                {i18n.t('products.content_items_table.actions.label')}
              </DropdownMenuLabel>
              <DropdownMenuItem>
                <Link
                  href={routes.productsContentItems.destroy.path({
                    id: contentItem.id,
                    productId: productId,
                  })}
                  method="delete"
                  preserveState={false}
                >
                  {i18n.t('products.content_items_table.actions.remove')}
                </Link>
              </DropdownMenuItem>
              {/* <DropdownMenuSeparator /> */}
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
  ]

  const table = useReactTable({
    data: contentItems,
    columns: columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  return (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableHead>
              )
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              data-state={row.getIsSelected() && 'selected'}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              {i18n.t('products.content_items_table.no_results')}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
