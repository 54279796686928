import React, { useEffect, useState } from 'react'

const SalesPopup: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [amount, setAmount] = useState(0)
  const [timeAgo, setTimeAgo] = useState(0)

  useEffect(() => {
    const showPopup = () => {
      const randomDollarAmount = Math.floor(Math.random() * (50 - 10 + 1) + 10) // 10 to 50 dollars
      const randomTimeAgo = Math.floor(Math.random() * (57 - 2 + 1) + 2) // 2 minutes to 57 minutes
      setAmount(randomDollarAmount)
      setTimeAgo(randomTimeAgo)
      setIsVisible(true)
      setTimeout(() => setIsVisible(false), 5000) // Hide after 5 seconds
    }

    const scheduleNextPopup = () => {
      const randomInterval =
        Math.floor(Math.random() * (600 - 45 + 1) + 45) * 1000 // 45 seconds to 600 seconds
      setTimeout(() => {
        showPopup()
        scheduleNextPopup()
      }, randomInterval)
    }

    scheduleNextPopup()

    return () => {
      // Clean up any ongoing timeouts when component unmounts
      setIsVisible(false)
    }
  }, [])

  if (!isVisible) return null

  return (
    <div
      className={`fixed bottom-4 left-4 z-50 animate-bounce rounded-xl bg-primary px-4 py-3 text-sm text-background shadow-lg transition-all duration-700 ease-out ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
    >
      <div className="font-semibold">
        💰 +${amount.toFixed(2)} earned by creators!
        <div className="mt-1 text-xs text-background/60">
          {' '}
          {timeAgo} mins ago
        </div>
      </div>
    </div>
  )
}

export default SalesPopup
