// JsFromRoutes CacheKey 8e8ea2b73185767a27b332a6f0183475
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/pay/subscriptions'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/pay/subscriptions'),
  new: /* #__PURE__ */ definePathHelper('get', '/admin/pay/subscriptions/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/pay/subscriptions/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/pay/subscriptions/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/pay/subscriptions/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/pay/subscriptions/:id'),
}
