import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import { i18n } from '@/utils'
import { useState } from 'react'
import CourseDeleteDialog from '../dialogs/course-delete-dialog'
import CourseSettingsForm from '../forms/course-settings-form'

export function CourseSettingsSheet() {
  const [isCourseDeleteDialogOpen, setIsCourseDeleteDialogOpen] =
    useState(false)
  return (
    <>
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="sm">
            {i18n.t('courses.edit.settings.title')}
          </Button>
        </SheetTrigger>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>{i18n.t('courses.edit.settings.title')}</SheetTitle>
          </SheetHeader>
          <div className="mt-4">
            <CourseSettingsForm />
            <Separator className="my-4" />
            <div className="flex justify-end">
              <Button
                variant="destructive"
                onClick={() => setIsCourseDeleteDialogOpen(true)}
              >
                Delete course
              </Button>
            </div>
          </div>
        </SheetContent>
      </Sheet>
      <CourseDeleteDialog
        isOpen={isCourseDeleteDialogOpen}
        onClose={() => setIsCourseDeleteDialogOpen(false)}
        onDelete={() => setIsCourseDeleteDialogOpen(false)}
      />
    </>
  )
}
