// JsFromRoutes CacheKey 8eb814a08be5e9b93330f70f83be4ca1
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/pay/customers'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/pay/customers'),
  new: /* #__PURE__ */ definePathHelper('get', '/admin/pay/customers/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/pay/customers/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/pay/customers/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/pay/customers/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/pay/customers/:id'),
}
