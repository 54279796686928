import { Form, FormInput, FormSubmit } from '@/components/shared/inertia-form'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import routes from '@/routes'

interface CreateAccountDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
}

export function NewAccountDialog({
  open,
  onOpenChange,
}: CreateAccountDialogProps) {
  const data = {
    account: {
      name: '',
    },
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create account</DialogTitle>
          <DialogDescription>
            Add a new account to manage products and customers.
          </DialogDescription>
        </DialogHeader>
        <Form model="account" to={routes.accounts.create.path()} data={data}>
          <FormInput
            label="Account name"
            name="name"
            placeholder="Your Name or Brand"
          />
          <DialogFooter>
            <div className="flex items-center justify-end gap-2">
              <Button
                variant="outline"
                onClick={() => onOpenChange(false)}
                type="button"
              >
                Cancel
              </Button>
              <FormSubmit>Continue</FormSubmit>
            </div>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
