import { FaqList } from '@/components/shared/faq-list'
import Meta from '@/components/shared/meta'
import { Input } from '@/components/ui/input'
import { APP_NAME } from '@/constants'
import useTypedPage from '@/hooks/use-typed-page'
import { DollarSign } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const faqList = [
  {
    question: 'What is a Gumroad Fee Calculator?',
    answer:
      'A Gumroad Fee Calculator helps you determine the fees deducted by Gumroad and the net payout you will receive after these fees.',
  },
  {
    question: 'How do I calculate the Gumroad fees?',
    answer:
      'To calculate the Gumroad fees, input the product price. The calculator will determine the Gumroad fee, payment processor fee, and the net payout you will receive.',
  },
  {
    question: 'What is the Gumroad fee?',
    answer:
      'The Gumroad fee is a percentage of the product price that Gumroad charges for using its platform. It is typically 5% of the product price.',
  },
  {
    question: 'What is the payment processor fee?',
    answer:
      'The payment processor fee is a percentage of the product price that the payment processor charges for handling the transaction. It is typically 2.9% of the product price plus a fixed fee of $0.30.',
  },
  {
    question: 'Why is it important to calculate the net payout?',
    answer:
      'Calculating the net payout helps you understand how much money you will actually take home after all fees are deducted, allowing you to make informed decisions about your pricing strategy.',
  },
]

const GumroadFeeCalculatorPage: React.FC = () => {
  const { name, description } = useTypedPage().props
  const [productPrice, setProductPrice] = useState<number>(100)
  const [gumroadFee, setGumroadFee] = useState<string>('5.00')
  const [paymentProcessorFee, setPaymentProcessorFee] = useState<string>('3.20')
  const [netPayout, setNetPayout] = useState<string>('91.80')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const calculateFees = () => {
    const gumroadFeeAmount = productPrice * 0.05
    const paymentProcessorFeeAmount = productPrice * 0.029 + 0.3
    const netPayoutAmount =
      productPrice - gumroadFeeAmount - paymentProcessorFeeAmount
    setGumroadFee(formatter.format(gumroadFeeAmount))
    setPaymentProcessorFee(formatter.format(paymentProcessorFeeAmount))
    setNetPayout(formatter.format(netPayoutAmount))
  }

  useEffect(() => {
    calculateFees()
  }, [productPrice])

  const metaTitle = `${name} | ${APP_NAME}`
  const metaDescription = description as string

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container mx-auto max-w-5xl">
        <div className="overflow-hidden">
          <div className="mx-auto mb-6 max-w-3xl text-center">
            <h1 className="mb-5 font-heading text-5xl font-medium">
              Gumroad Fee Calculator
            </h1>
            <p className="text-neutral-500">
              Shows exact fees deducted by Gumroad based on product price.
            </p>
          </div>
          <div className="rounded-md border border-border bg-accent p-5">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
              <div className="col-span-1">
                <label htmlFor="product_price" className="font-semibold">
                  Product Price (USD)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What is the price of your product?
                </div>
                <Input
                  type="number"
                  id="product_price"
                  value={productPrice}
                  onChange={(e) => setProductPrice(Number(e.target.value))}
                  placeholder="Product Price"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="mt-6 rounded-md border border-border bg-background px-4 py-5 sm:p-6">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <DollarSign className="mr-2 inline-block" />
                    Gumroad Fee:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="gumroad-fee-value">{gumroadFee}</span>
                  </div>
                </div>
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <DollarSign className="mr-2 inline-block" />
                    Payment Processor Fee:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="payment-processor-fee-value">
                      {paymentProcessorFee}
                    </span>
                  </div>
                </div>
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <DollarSign className="mr-2 inline-block" />
                    Net Payout:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="net-payout-value">{netPayout}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FaqList faqList={faqList} />
      </div>
    </>
  )
}

export default GumroadFeeCalculatorPage
