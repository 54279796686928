import {
  Form,
  FormInput,
  FormRadioGroup,
  FormSubmit,
} from '@/components/shared/inertia-form'
import { BOOLEAN_STRING_OPTIONS } from '@/constants/blocks/available-forms'
import { linkInBioBlockables } from '@/routes/generated'
import { LinkBlock } from '@/types/blocks/blockable'
import { i18n } from '@/utils'

interface LinkFormData {
  blockable: {
    id?: number
    type: string
    title: string
    url: string
    in_new_tab: string
  }
}

interface LinkFormProps {
  editedBlock: LinkBlock
  setEditedBlock: (block: LinkBlock) => void
}

const LinkForm = ({ editedBlock, setEditedBlock }: LinkFormProps) => {
  const { blockable, blockableType } = editedBlock
  const { id, title, inNewTab, url } = blockable

  const data: LinkFormData = {
    blockable: {
      id: id,
      type: blockableType,
      title: title || '',
      url: url || '',
      in_new_tab: inNewTab
        ? BOOLEAN_STRING_OPTIONS.True
        : BOOLEAN_STRING_OPTIONS.False,
    },
  }

  const handleChange = ({ data }: { data: LinkFormData }) => {
    const {
      blockable: { title, url, in_new_tab },
    } = data

    setEditedBlock({
      ...editedBlock,
      blockable: {
        ...blockable,
        title,
        url,
        inNewTab: in_new_tab === BOOLEAN_STRING_OPTIONS.True,
      },
    })
  }

  return (
    <Form
      model="blockable"
      method={id ? 'put' : 'post'}
      to={
        id
          ? linkInBioBlockables.update.path({ id })
          : linkInBioBlockables.create.path()
      }
      data={data}
      onChange={handleChange}
    >
      <FormInput
        name="title"
        label={i18n.t('link_in_bio.blockables.forms.link.title')}
        placeholder={i18n.t(
          'link_in_bio.blockables.forms.link.title_placeholder'
        )}
        required
      />

      <FormInput
        name="url"
        label={i18n.t('link_in_bio.blockables.forms.link.url')}
        placeholder={i18n.t(
          'link_in_bio.blockables.forms.link.url_placeholder'
        )}
        required
      />

      <FormRadioGroup
        name="in_new_tab"
        label={i18n.t('link_in_bio.blockables.forms.link.open_in_new_tab')}
        options={[
          { value: BOOLEAN_STRING_OPTIONS.True, label: i18n.t('yes') },
          { value: BOOLEAN_STRING_OPTIONS.False, label: i18n.t('no') },
        ]}
      />
      <FormSubmit>{i18n.t('save')}</FormSubmit>
    </Form>
  )
}

export default LinkForm
