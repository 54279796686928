// JsFromRoutes CacheKey 529a366bb6af90cbaa6c65de06d95fe5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/content/courses'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/content/courses/:prefix_id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/content/courses/:prefix_id'),
}
