// JsFromRoutes CacheKey bfd1b27e2765275a6669b29f762836a7
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/account_users'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/account_users'),
  new: /* #__PURE__ */ definePathHelper('get', '/admin/account_users/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/account_users/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/account_users/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/account_users/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/account_users/:id'),
}
