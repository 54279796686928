import AccountTemplateForm from '@/components/features/link-in-bio/forms/account-template-form'
import { KeyNavItems } from '@/components/features/link-in-bio/key-nav-items'
import Preview from '@/components/features/link-in-bio/preview'
import TemplateCarousel from '@/components/features/link-in-bio/templates/template-carousel.tsx'
import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { ContentSection } from '@/layouts/app/sections/content-section'
import routes from '@/routes'
import { LinkInBioAccountTemplate, LinkInBioTemplate } from '@/types'
import {
  DividerBlock,
  FaqBlock,
  ImageBlock,
  LinkBlock,
  TextBlock,
} from '@/types/blocks/blockable'
import { i18n } from '@/utils'
import { useState } from 'react'

interface TemplatesPageProps {
  templates: LinkInBioTemplate[]
  defaultTemplate?: LinkInBioTemplate
  accountTemplate?: LinkInBioAccountTemplate
  blocks: (LinkBlock | TextBlock | DividerBlock | FaqBlock | ImageBlock)[]
}

const TemplatesPage = ({
  templates,
  defaultTemplate,
  accountTemplate,
  blocks,
}: TemplatesPageProps) => {
  const breadcrumbs = [
    {
      title: i18n.t('application.breadcrumbs.home'),
      link: routes.dashboard.show.path(),
    },
    { title: 'Link-in-Bio', link: routes.linkInBioBlockables.index.path() },
    { title: 'Design' },
  ]

  const [selectedTemplate, setSelectedTemplate] = useState(defaultTemplate)

  return (
    <ContentSection>
      <ContentSection.Header>
        <DefaultNavbar breadcrumbs={breadcrumbs} />
      </ContentSection.Header>
      <ContentSection.Body>
        <KeyNavItems />
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6">
          <div className="col-span-1">
            {templates.length > 0 && selectedTemplate ? (
              <>
                <TemplateCarousel
                  templateId={selectedTemplate.id}
                  onChange={setSelectedTemplate}
                  templates={templates}
                />
                <AccountTemplateForm
                  settings={
                    accountTemplate?.settings || selectedTemplate.settings
                  }
                  templateId={selectedTemplate.id}
                />
              </>
            ) : (
              <div className="text-center">
                {i18n.t('link_in_bio.templates.no_templates')}
              </div>
            )}
          </div>
          <div className="col-span-1">
            <Preview
              blocks={blocks}
              editedBlockId={null}
              editedBlockRef={null}
            />
          </div>
        </div>
      </ContentSection.Body>
    </ContentSection>
  )
}

export default TemplatesPage
