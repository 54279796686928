// JsFromRoutes CacheKey 9b544cc772acc91e77de99a158a8ea74
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/agreements'),
  create: /* #__PURE__ */ definePathHelper('post', '/agreements'),
  new: /* #__PURE__ */ definePathHelper('get', '/agreements/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/agreements/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/agreements/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/agreements/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/agreements/:id'),
}
