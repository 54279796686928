import { FaqQuestion as FaqQuestionType } from '@/types/blocks/faq-question'
import { i18n } from '@/utils'
import { Button } from '@/components/ui/button'
import { TrashIcon } from 'lucide-react'
import { FormTextarea } from '@/components/shared/inertia-form'
import { useInertiaInput } from '@/lib/use-inertia-form'

type FaqQuestionsProps = {
  onRemove: (index: number) => void
}

const FaqQuestions = ({ onRemove }: FaqQuestionsProps) => {
  const { value: faqQuestions } = useInertiaInput<FaqQuestionType[]>({
    name: 'questions',
    model: 'blockable',
  })

  return (
    <>
      {faqQuestions.map((_, index) => (
        <div className="flex gap-3">
          <span>{index + 1}.</span>
          <div className="flex w-full flex-col gap-2">
            <FormTextarea
              name={`questions[${index}].question`}
              placeholder={i18n.t(
                'link_in_bio.blockables.forms.faq.faq_question.question'
              )}
              autoComplete="off"
              required
            />

            <FormTextarea
              name={`questions[${index}].answer`}
              placeholder={i18n.t(
                'link_in_bio.blockables.forms.faq.faq_question.answer'
              )}
              autoComplete="off"
              required
            />
          </div>

          <Button
            type="button"
            variant="destructive"
            size="sm"
            onClick={() => onRemove(index)}
            disabled={index === 0}
          >
            <TrashIcon className="size-4" />
          </Button>
        </div>
      ))}
    </>
  )
}

export default FaqQuestions
