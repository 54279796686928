import { usePage } from '@inertiajs/react'

export default function useCheckActiveNav() {
  const { url } = usePage()

  const checkActiveNav = (nav: string) => {
    const currentPath = url.split('?')[0] // Remove query parameters
    const navPath = nav.endsWith('/') ? nav.slice(0, -1) : nav // Remove trailing slash if present

    if (navPath === '/' && currentPath === '/') return true

    return currentPath.startsWith(navPath)
  }

  return { checkActiveNav }
}
