import { cn, i18n } from '@/utils'
import { ImageIcon, XIcon } from 'lucide-react'

import { FormError } from '@/components/shared/inertia-form/form-error'
import { useInertiaInput } from '@/lib/use-inertia-form'
import { Image } from '@/types'
import { useRef } from 'react'

const ImagePicker = () => {
  const { inputName, inputId, value, setValue, error } = useInertiaInput<
    (Image | File)[]
  >({
    name: 'images',
    model: 'blockable',
  })

  const imagesInput = useRef<HTMLInputElement>(null)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setValue([...value, ...Array.from(e.target.files)])
    }
  }

  const handleRemoveImage = (image: File | Image) => {
    const newImages = Array.from(value).filter((img) => img !== image)
    setValue(newImages)
  }

  return (
    <div className="mt-10 flex">
      <div className="w-full">
        <label
          htmlFor="images"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {i18n.t('link_in_bio.blockables.forms.image.images')}
        </label>
        <FormError
          name={i18n.t('link_in_bio.blockables.forms.image.images')}
          error={error}
        />
        <div className="mt-2">
          <input
            id={inputId}
            name={inputName}
            type="file"
            className="hidden"
            accept="image/*"
            multiple
            onChange={handleFileChange}
            ref={imagesInput}
            value=""
          />

          <div
            className={cn(
              'mt-2 flex cursor-pointer justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10'
            )}
            onClick={() => imagesInput.current?.click()}
          >
            {value.length > 0 ? (
              <>
                {value.map((image, idx) => {
                  const isFile = image instanceof File
                  const src = isFile ? URL.createObjectURL(image) : image.src

                  return (
                    <div
                      key={`image-${idx}`}
                      className="aspect-[3/4] relative w-1/3 cursor-default px-1"
                    >
                      <img className="m-0 h-full object-cover" src={src} />

                      <button
                        type="button"
                        className="absolute right-0 top-0 size-5 -translate-y-1/4 cursor-pointer rounded-full bg-gray-200 text-center text-xs hover:bg-red-600 hover:text-white"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleRemoveImage(image)
                        }}
                      >
                        <XIcon className="size-5 fill-none stroke-current" />
                      </button>
                    </div>
                  )
                })}
              </>
            ) : (
              <div className="text-center">
                <ImageIcon className="mx-auto h-12 w-12 stroke-current text-gray-300" />
                <div className="mt-4 flex text-center text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="images"
                    className="text-black-500 focus-within:ring-black-500 hover:text-black-400 relative w-full cursor-pointer rounded-md bg-white font-semibold focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2"
                  >
                    {i18n.t('link_in_bio.blockables.forms.image.add_images')}
                  </label>
                </div>
                <p className="text-xs leading-5 text-gray-600">
                  {i18n.t(
                    'link_in_bio.blockables.forms.image.add_images_description'
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImagePicker
