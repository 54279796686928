import { Form, FormInput, FormSubmit } from '@/components/shared/inertia-form'
import routes from '@/routes'

type MailingListSignupFormData = {
  static: {
    email: string
  }
}

const data: MailingListSignupFormData = {
  static: {
    email: '',
  },
}

export default function MailingListSignupForm() {
  return (
    <Form
      resetAfterSubmit={true}
      model="static"
      data={data}
      to={routes.websiteStatic.addToMailingList.path()}
    >
      <div className="mx-auto mb-4 mt-12 flex w-full max-w-md flex-col gap-2 rounded-xl border bg-accent p-2 sm:flex-row">
        <FormInput
          type="email"
          name="email"
          className="h-11 min-w-full rounded-xl"
          placeholder="Your email address"
        />
        <FormSubmit
          size="lg"
          useAutoDisable={false}
          className="whitespace-nowrap"
        >
          Join waitlist
        </FormSubmit>
      </div>
    </Form>
  )
}
