// JsFromRoutes CacheKey df6bd29f3d345a76429d382862f621a4
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/auth/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/auth/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/auth'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/api/v1/auth'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/api/v1/auth'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/api/v1/auth'),
}
