import { Omniauth } from '@/components/features/auth/omniauth'
import { Form, FormInput, FormSubmit } from '@/components/shared/inertia-form'
import { Link } from '@/components/ui/link'
import routes from '@/routes'
import { i18n } from '@/utils'

interface LoginFormData {
  user: {
    email: string
    password: string
  }
}

const data: LoginFormData = {
  user: {
    email: '',
    password: '',
  },
}

const LoginForm = () => {
  return (
    <Form model="user" to={routes.usersSessions.create.path()} data={data}>
      <FormInput
        name="email"
        type="email"
        placeholder={i18n.t('forms.fields.email')}
        autoComplete="email"
      />
      <FormInput
        name="password"
        type="password"
        placeholder={i18n.t('forms.fields.password')}
        autoComplete="current-password"
        helperText={
          <>
            Forgot your password?{' '}
            <Link
              href={routes.usersPasswords.new.path()}
              className="underline underline-offset-4 hover:text-primary"
            >
              Reset it
            </Link>
          </>
        }
      />
      <FormSubmit>{i18n.t('log_in')}</FormSubmit>
      <Omniauth />
    </Form>
  )
}

export default LoginForm
