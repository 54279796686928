// JsFromRoutes CacheKey f2a33d0ddf49e4f18b587571a046bcde
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  resend: /* #__PURE__ */ definePathHelper('post', '/app/creator/accounts/:account_id/invitations/:id/resend'),
  index: /* #__PURE__ */ definePathHelper('get', '/app/creator/accounts/:account_id/invitations'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/accounts/:account_id/invitations'),
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/accounts/:account_id/invitations/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/accounts/:account_id/invitations/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/accounts/:account_id/invitations/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/accounts/:account_id/invitations/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/accounts/:account_id/invitations/:id'),
}
