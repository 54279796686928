import { AppLogotype } from '@/components/shared/app/app-logo'
import { cn, i18n } from '@/utils'

interface OnboardingSidebarProps {
  currentStep: string
  onboardingSteps: string[]
  showOnboardingSteps: boolean
}

const OnboardingSidebar = ({
  currentStep,
  onboardingSteps,
  showOnboardingSteps,
}: OnboardingSidebarProps) => {
  return (
    <div className="flex h-full w-full max-w-80 flex-col items-center justify-center border-r border-border bg-accent max-md:hidden">
      <div className="space-y-6">
        <AppLogotype />
        {showOnboardingSteps && (
          <ol className="flex list-inside list-decimal flex-col gap-4 text-sm text-neutral-500">
            {onboardingSteps.map((step) => (
              <li
                key={step}
                className={cn({ 'text-primary': step === currentStep })}
              >
                {i18n.t(`onboarding.steps.${step}`)}
              </li>
            ))}
          </ol>
        )}
      </div>
    </div>
  )
}

export default OnboardingSidebar
