import AvatarUploadDialog from '@/components/features/dashboard/wizard/step-1/avatar-upload-dialog'
import SocialLinksDialog from '@/components/features/dashboard/wizard/step-2/social-links-dialog'
import { Button, buttonVariants } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Link } from '@/components/ui/link'
import useTypedPage from '@/hooks/use-typed-page'
import routes from '@/routes'
import { cn } from '@/utils'
import { ArrowRight, CheckIcon, Upload } from 'lucide-react'
import React, { useRef, useState } from 'react'

interface WizardStepProps {
  step: number
  currentStep: number
  lastStep: boolean
  completed: boolean
  title: string
  description: string
  buttonText: string
  optional: boolean
}

const WizardStep: React.FC<WizardStepProps> = ({
  step,
  currentStep,
  lastStep,
  completed,
  title,
  description,
  buttonText,
  optional,
}) => {
  const { currentAccount, hasAnyContent, hasAnyProducts } = useTypedPage().props

  const fileInputRef = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isSocialLinksDialogOpen, setIsSocialLinksDialogOpen] = useState(false)
  const finished = step < currentStep
  const active = step === currentStep

  const showBottomLine = !lastStep
  const showTopLine = step !== 1

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setSelectedFile(file)
      setIsDialogOpen(true)
    }
  }

  const renderButton = () => {
    if (active && !completed) {
      if (step === 1) {
        return (
          <Button
            className="w-fit"
            size="sm"
            onClick={() => fileInputRef.current?.click()}
          >
            <span className="flex items-center gap-2">
              <Upload className="h-4 w-4" />
              {buttonText}
            </span>
          </Button>
        )
      }
      if (step === 2) {
        return (
          <Button
            className="w-fit"
            size="sm"
            onClick={() => setIsSocialLinksDialogOpen(true)}
          >
            {buttonText}
          </Button>
        )
      }
      if (step === 3) {
        return (
          <>
            {hasAnyContent ? (
              <Link
                href={routes.onboarding.dashboardWizard.path({
                  account_id: currentAccount?.id,
                })}
                method="post"
                className={cn(buttonVariants({ size: 'sm' }), 'w-fit')}
              >
                {buttonText}
              </Link>
            ) : (
              <Link
                href={routes.contentItems.index.path()}
                className={cn(buttonVariants({ size: 'sm' }), 'w-fit')}
              >
                {buttonText}
              </Link>
            )}
          </>
        )
      }
      if (step === 4) {
        return (
          <>
            {hasAnyProducts ? (
              <Link
                href={routes.onboarding.dashboardWizard.path({
                  account_id: currentAccount?.id,
                })}
                method="post"
                className={cn(buttonVariants({ size: 'sm' }), 'w-fit')}
              >
                {buttonText}
              </Link>
            ) : (
              <Link
                href={routes.products.index.path()}
                className={cn(buttonVariants({ size: 'sm' }), 'w-fit')}
              >
                {buttonText}
              </Link>
            )}
          </>
        )
      }
    }

    if (active && completed) {
      const buttonText = optional ? 'Skip' : lastStep ? 'Complete' : 'Next Step'

      return (
        <Link
          href={routes.onboarding.dashboardWizard.path()}
          method="post"
          className="flex w-fit items-center gap-1 text-sm font-medium"
        >
          {buttonText}
          <ArrowRight className="h-4 w-4" />
        </Link>
      )
    }

    return null
  }

  if (currentStep > step && completed) {
    return null
  }

  return (
    <div className="flex items-center gap-4 lg:gap-6">
      <div className="flex flex-col items-center">
        {showTopLine && (
          <div
            className={cn(
              'h-full w-0.5',
              finished ? 'bg-green-500' : 'bg-gray-200'
            )}
          />
        )}
        <div
          className={cn(
            'flex h-8 w-8 items-center justify-center rounded-full border-2',
            finished
              ? 'border-green-500 bg-green-500 text-white'
              : active
                ? 'border-primary bg-white text-primary'
                : 'border-border bg-background text-gray-400'
          )}
        >
          {finished ? <CheckIcon className="h-4 w-4" /> : step}
        </div>
        {showBottomLine && (
          <div
            className={cn(
              'h-full w-0.5',
              finished ? 'bg-green-500' : 'bg-gray-200'
            )}
          />
        )}
      </div>
      <Card className="my-2 w-full bg-accent p-4">
        <div className="flex flex-col gap-4">
          <div>
            <div className="flex items-center gap-2 text-xl font-semibold">
              <h2>{title}</h2>
            </div>
            <p className="text-sm text-gray-500">{description}</p>
          </div>
          {renderButton()}
          {step === 1 && (
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleFileSelect}
            />
          )}
        </div>
      </Card>
      <AvatarUploadDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        file={selectedFile}
      />
      <SocialLinksDialog
        isOpen={isSocialLinksDialogOpen}
        onClose={() => setIsSocialLinksDialogOpen(false)}
      />
    </div>
  )
}

export default WizardStep
