import { FaqList } from '@/components/shared/faq-list'
import Meta from '@/components/shared/meta'
import { Input } from '@/components/ui/input'
import { APP_NAME } from '@/constants'
import useTypedPage from '@/hooks/use-typed-page'
import { LineChart } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const faqList = [
  {
    question: 'What is an Audience Growth Calculator?',
    answer:
      'An Audience Growth Calculator helps you predict how your audience may grow over time based on current metrics.',
  },
  {
    question: 'How do I calculate the projected audience size?',
    answer:
      'To calculate the projected audience size, input the current audience size, growth rate percentage, and time period. The calculator will determine the projected audience size over the specified time period.',
  },
  {
    question: 'What is the current audience size?',
    answer:
      'The current audience size is the number of people currently following or subscribed to your content.',
  },
  {
    question: 'What is the growth rate?',
    answer:
      'The growth rate is the percentage at which your audience is expected to grow over a specified time period.',
  },
  {
    question: 'What is the time period?',
    answer:
      'The time period is the duration over which you want to project the audience growth.',
  },
]

const AudienceGrowthCalculatorPage: React.FC = () => {
  const { name, description } = useTypedPage().props
  const [currentAudienceSize, setCurrentAudienceSize] = useState<number>(1000)
  const [growthRate, setGrowthRate] = useState<number>(5) // percentage
  const [timePeriod, setTimePeriod] = useState<number>(12) // months
  const [projectedAudienceSize, setProjectedAudienceSize] =
    useState<string>('1795.86')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const calculateProjectedAudienceSize = () => {
    const growthFactor = 1 + growthRate / 100
    const projectedSize =
      currentAudienceSize * Math.pow(growthFactor, timePeriod)
    setProjectedAudienceSize(formatter.format(projectedSize))
  }

  useEffect(() => {
    calculateProjectedAudienceSize()
  }, [currentAudienceSize, growthRate, timePeriod])

  const metaTitle = `${name} | ${APP_NAME}`
  const metaDescription = description as string

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container mx-auto max-w-5xl">
        <div className="overflow-hidden">
          <div className="mx-auto mb-6 max-w-3xl text-center">
            <h1 className="mb-5 font-heading text-5xl font-medium">
              Audience Growth Calculator
            </h1>
            <p className="text-neutral-500">
              Predict how your audience may grow over time based on current
              metrics.
            </p>
          </div>
          <div className="rounded-md border border-border bg-accent p-5">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
              <div className="col-span-1">
                <label
                  htmlFor="current_audience_size"
                  className="font-semibold"
                >
                  Current Audience Size
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What is the size of your current audience?
                </div>
                <Input
                  type="number"
                  id="current_audience_size"
                  value={currentAudienceSize}
                  onChange={(e) =>
                    setCurrentAudienceSize(Number(e.target.value))
                  }
                  placeholder="Current Audience Size"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="growth_rate" className="font-semibold">
                  Growth Rate (%)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What is the expected growth rate of your audience?
                </div>
                <Input
                  type="number"
                  id="growth_rate"
                  value={growthRate}
                  onChange={(e) => setGrowthRate(Number(e.target.value))}
                  placeholder="Growth Rate (%)"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="time_period" className="font-semibold">
                  Time Period (months)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  Over how many months do you want to project the growth?
                </div>
                <Input
                  type="number"
                  id="time_period"
                  value={timePeriod}
                  onChange={(e) => setTimePeriod(Number(e.target.value))}
                  placeholder="Time Period (months)"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="mt-6 rounded-md border border-border bg-background px-4 py-5 sm:p-6">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <LineChart className="mr-2 inline-block" />
                    Projected Audience Size:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="projected-audience-size-value">
                      {projectedAudienceSize}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FaqList faqList={faqList} />
      </div>
    </>
  )
}

export default AudienceGrowthCalculatorPage
