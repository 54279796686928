import { Link as InertiaLink, InertiaLinkProps } from '@inertiajs/react'
import React, { forwardRef } from 'react'

// Custom Link component that can handle both Inertia.js and external links

interface LinkProps extends InertiaLinkProps {
  nonInertia?: boolean
  children: React.ReactNode
}

const isExternal = (href: string): boolean => {
  return /^(https?:|mailto:)/.test(href)
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ href, children, target, nonInertia, ...props }, ref) => {
    if (isExternal(href) || target === '_blank' || nonInertia === true) {
      return (
        <a
          href={href}
          target={target}
          ref={ref}
          {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
        >
          {children}
        </a>
      )
    }

    return (
      <InertiaLink href={href} ref={ref} {...props}>
        {children}
      </InertiaLink>
    )
  }
)

Link.displayName = 'Link'

export { Link }
