import { useEffect } from 'react'
import { router, useForm, usePage } from '@inertiajs/react'
import routes from '@/routes'
import { i18n } from '@/utils'

import StoreBlockable from '@/types/blocks/store'
import { StoreBlock } from '@/types/blocks/blockable'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'

import { toSnakeCase, deepTransform } from '@/utils/objects'
import { FormError } from '@/components/shared/form-error'

type StoreFormProps = {
  editedBlock: StoreBlock
  setEditedBlock: React.Dispatch<React.SetStateAction<StoreBlock>>
}

export default function LinkForm({
  editedBlock,
  setEditedBlock,
}: StoreFormProps) {
  const { props } = usePage()
  const { blockable } = editedBlock
  const { id, title } = blockable
  const { data, setData } = useForm({
    blockable: {
      type: editedBlock.blockableType,
      ...blockable,
    },
  })
  const errors = (props.errors ?? {}) as Partial<
    Record<keyof StoreBlockable, string[] | undefined>
  >

  const updateFormData = (newBlock: StoreBlock) => {
    setData({
      blockable: {
        ...newBlock.blockable,
        type: newBlock.blockableType,
      },
    })
  }

  useEffect(() => {
    updateFormData(editedBlock)
  }, [editedBlock])

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!!id) {
      router.patch(routes.linkInBioBlockables.update.path({ id: id }), {
        ...deepTransform(data, toSnakeCase),
      })
    } else {
      router.post(routes.linkInBioBlockables.create.path(), {
        ...deepTransform(data, toSnakeCase),
      })
    }
  }

  return (
    <form onSubmit={submitForm}>
      <div className="form-group mt-4">
        <Label htmlFor="title">Title</Label>
        <Input
          type="text"
          id="title"
          value={title}
          className={`form-control ${errors.title ? 'border-red-500' : ''}`}
          onChange={(e) => {
            setEditedBlock((block) => {
              const newBlock = { ...block }
              newBlock.blockable.title = e.target.value
              return newBlock
            })
          }}
        />
        <FormError error={errors.title} />
      </div>

      <Button type="submit" value={i18n.t('save')} className="mt-4">
        {i18n.t('save')}
      </Button>
    </form>
  )
}
