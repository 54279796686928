import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { buttonVariants } from '@/components/ui/button'
import useTypedPage from '@/hooks/use-typed-page'
import routes from '@/routes'
import Course from '@/types/generated/ContentItems/Course'
import { cn, i18n } from '@/utils'
import { router } from '@inertiajs/react'
import { useEffect, useState } from 'react'

interface CourseDeleteDialogProps {
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}

export default function CourseDeleteDialog({
  isOpen,
  onClose,
  onDelete,
}: CourseDeleteDialogProps) {
  const course = useTypedPage().props.course as Course

  const [isCourseDeleteDialogOpen, setIsCourseDeleteDialogOpen] =
    useState(false)

  useEffect(() => {
    setIsCourseDeleteDialogOpen(isOpen)
  }, [isOpen])

  const handleCourseDelete = () => {
    router.delete(
      routes.contentItems.destroy.path({
        id: course.contentItemId,
      }),
      {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
          onDelete()
          onClose()
        },
      }
    )
  }

  const handleClose = () => {
    setIsCourseDeleteDialogOpen(false)
    onClose()
  }

  return (
    <AlertDialog open={isCourseDeleteDialogOpen} onOpenChange={handleClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {i18n.t('courses.destroy.dialog.title')}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {i18n.t('courses.destroy.dialog.description')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{i18n.t('cancel')}</AlertDialogCancel>
          <AlertDialogAction
            className={cn(buttonVariants({ variant: 'destructive' }))}
            onClick={handleCourseDelete}
          >
            {i18n.t('delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
