// JsFromRoutes CacheKey 412d5a3764e80a3695299d93667e5a21
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/app/creator/api_tokens'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/api_tokens'),
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/api_tokens/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/api_tokens/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/api_tokens/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/api_tokens/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/api_tokens/:id'),
}
