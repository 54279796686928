import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Link } from '@/components/ui/link'
import { useAuth } from '@/hooks/use-auth'
import useTypedPage from '@/hooks/use-typed-page'
import routes from '@/routes'
import { i18n, isDevelopment } from '@/utils'
import { ChevronDown, ExternalLink, Settings, User } from 'lucide-react'

export function UserNav() {
  const { currentUser, isSuperAdmin } = useAuth()
  const currentAccount = useTypedPage().props.currentAccount

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 items-center space-x-1.5 rounded-xl py-5 md:px-2"
        >
          <Avatar className="h-8 w-8 border border-border">
            <AvatarImage
              src={currentUser?.avatar?.src}
              alt={currentUser?.name}
            />
            <AvatarFallback>
              {currentUser?.name ? currentUser.name.charAt(0) : ''}
            </AvatarFallback>
          </Avatar>
          <ChevronDown className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {currentUser?.name}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {currentUser?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem>
            <ExternalLink className="mr-2 h-4 w-4" />
            <Link
              href={routes.linkInBioPublicBio.show.path({
                account_subdomain: currentAccount?.subdomain,
              })}
              target="_blank"
            >
              Visit Creator Page
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Settings className="mr-2 h-4 w-4" />
            <Link href={routes.settingsAccounts.index.path()}>
              {i18n.t('application.navbar.avatar_settings.accounts')}
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <User className="mr-2 h-4 w-4" />
            <Link href={routes.settingsProfile.index.path()}>
              {i18n.t('application.navbar.avatar_settings.profile')}
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        {/* Development */}
        <DropdownMenuSeparator />
        {isSuperAdmin && (
          <>
            <DropdownMenuItem className="font-semibold">
              {i18n.t('application.navbar.avatar_settings.admin')}
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Link href="/admin" target="_blank">
                {i18n.t('application.navbar.avatar_settings.dashboard')}
              </Link>
            </DropdownMenuItem>
            {/* Assuming Sidekiq is always available in the React environment */}
            <DropdownMenuItem>
              <Link href="/admin/sidekiq" target="_blank">
                Sidekiq
              </Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
          </>
        )}
        {isDevelopment() && (
          <>
            <DropdownMenuItem className="font-semibold">
              Development
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Link
                href="/dev/letter_opener"
                target="_blank"
                rel="noopener noreferrer"
              >
                Letter Opener
              </Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
          </>
        )}
        {/* Sign Out */}
        <DropdownMenuItem>
          <Link
            href={routes.usersSessions.destroy.path()}
            method="delete"
            as="button"
            type="button"
          >
            {i18n.t('application.navbar.avatar_settings.sign_out')}
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
