import SidebarNav from '@/components/features/settings/sidebar-nav'
import { Separator } from '@/components/ui/separator'
import * as React from 'react'

interface SettingsSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  desc: string
  action?: React.ReactNode
}

const SettingsSection = ({
  title,
  desc,
  children,
  action,
}: SettingsSectionProps) => {
  return (
    <>
      <div className="flex flex-1 flex-col space-y-8 md:space-y-2 md:overflow-hidden lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="top-0 lg:sticky lg:w-1/5">
          <SidebarNav />
        </aside>
        <div className="flex w-full p-1 pr-4 md:overflow-y-hidden">
          <div className="flex flex-1 flex-col">
            <div className="flex-none">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-lg font-medium">{title}</h3>
                  <p className="text-sm text-muted-foreground">{desc}</p>
                </div>
                {action && <div>{action}</div>}
              </div>
            </div>
            <Separator className="my-4 flex-none" />
            <div className="faded-bottom -mx-4 flex-1 overflow-auto scroll-smooth px-4 pt-2 md:pb-16">
              <div className="lg:max-w-xl">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { SettingsSection }
