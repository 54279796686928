// JsFromRoutes CacheKey 2662e2f8159d6623e9332de3d24fcf6e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/short_links'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/short_links'),
  new: /* #__PURE__ */ definePathHelper('get', '/admin/short_links/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/short_links/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/short_links/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/short_links/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/short_links/:id'),
}
