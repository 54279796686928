import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Link } from '@/components/ui/link'
import { BLOCKABLE_TYPES } from '@/constants/blocks/available-forms'
import routes from '@/routes'
import { i18n } from '@/utils'
import {
  FileQuestionIcon,
  ImageIcon,
  LinkIcon,
  LucideIcon,
  SquareSplitVerticalIcon,
  StoreIcon,
  TextIcon,
} from 'lucide-react'
import React from 'react'

type PickableBlocksModalProps = {
  blockTypes: { value: string; humanized: string }[]
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  hasProductWithContent: boolean
}

const blockableIcons: { [key: string]: LucideIcon } = {
  'Blocks::Link': LinkIcon,
  'Blocks::Text': TextIcon,
  'Blocks::Divider': SquareSplitVerticalIcon,
  'Blocks::Faq': FileQuestionIcon,
  'Blocks::Image': ImageIcon,
  'Blocks::Store': StoreIcon,
}

export default function PickableBlocksModal({
  blockTypes,
  open,
  setOpen,
  hasProductWithContent,
}: PickableBlocksModalProps) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-xl md:max-w-3xl lg:max-w-5xl">
        <DialogHeader>
          <DialogTitle>
            {i18n.t('link_in_bio.blockables.modal.add_one')}
          </DialogTitle>
        </DialogHeader>
        <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
          {blockTypes.map((type) => {
            const Icon = blockableIcons[type.value]
            const isStore = type.value === BLOCKABLE_TYPES.Store
            const isDisabled = isStore && !hasProductWithContent

            const Content = () => (
              <>
                <div className="flex items-center rounded-xl border border-border px-4 py-2 hover:bg-accent">
                  <div className="flex items-center justify-center rounded-xl">
                    <Icon className="h-6 w-6" />
                  </div>
                  <div className="ml-3 flex">
                    <div className="flex flex-col">
                      <div className="text-sm font-semibold">
                        {type.humanized}
                      </div>
                      <p className="text-sm">
                        TODO: Add description. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.
                      </p>
                    </div>
                    <div className="relative right-0 top-0">+</div>
                  </div>
                  {isDisabled && (
                    <div className="absolute bottom-[-5px] left-[-5px] right-[-5px] top-[-5px] flex items-center justify-center rounded-md bg-black/5 backdrop-blur-sm">
                      <div className="rounded-md bg-white/90 p-1">
                        <span>
                          {i18n.t(
                            'link_in_bio.blockables.modal.store_add_content'
                          )}
                        </span>
                        <Link href={routes.products.index.path()}>
                          <Button size="xs" className="ml-2">
                            {i18n.t('link_in_bio.blockables.modal.click_here')}
                          </Button>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )

            return (
              <div className="relative flex justify-center lg:justify-stretch">
                {isDisabled ? (
                  <Content />
                ) : (
                  <Link
                    href={
                      isDisabled
                        ? ''
                        : routes.linkInBioBlockables.new.path({
                            query: { 'blockable[type]': type.value },
                          })
                    }
                    disabled={isDisabled}
                    key={type.value}
                    className="flex cursor-pointer justify-center text-black lg:justify-stretch"
                  >
                    <Content />
                  </Link>
                )}
              </div>
            )
          })}
        </div>
      </DialogContent>
    </Dialog>
  )
}
