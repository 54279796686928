// JsFromRoutes CacheKey 6ec1225ab5e71c2259c907486865c371
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/resume/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/resume/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/resume'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/subscriptions/:subscription_id/resume'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/subscriptions/:subscription_id/resume'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/subscriptions/:subscription_id/resume'),
}
