import BlockRow from '@/components/features/link-in-bio/block-row'
import { KeyNavItems } from '@/components/features/link-in-bio/key-nav-items'
import PickableBlocksModal from '@/components/features/link-in-bio/pickable-blocks-modal'
import SortableBlockRow from '@/components/features/link-in-bio/sortable-block-row'
import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { Button } from '@/components/ui/button'
import { ContentSection } from '@/layouts/app/sections/content-section'

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { useState } from 'react'
// @ts-expect-error lib has no types
import { patch } from '@rails/request.js'

import routes from '@/routes'

import Preview from '@/components/features/link-in-bio/preview'

import {
  DividerBlock,
  FaqBlock,
  ImageBlock,
  LinkBlock,
  TextBlock,
} from '@/types/blocks/blockable'

import ContinueOnboardingBanner from '@/components/features/onboarding/continue-onboarding-banner'
import { Card } from '@/components/ui/card'
import { InertiaSharedProps } from '@/types'
import { i18n } from '@/utils'
import { PlusIcon } from 'lucide-react'

const rowsData: { [key: string]: { icon: string; label: string } } = {
  'Blocks::Link': {
    icon: 'Link',
    label: 'Link',
  },
  'Blocks::Text': {
    icon: 'Text',
    label: 'Text',
  },
  'Blocks::Divider': {
    icon: 'TableRowsSplit',
    label: 'Divider',
  },
  'Blocks::Faq': {
    icon: 'CircleHelp',
    label: 'Faq',
  },
  'Blocks::Image': {
    icon: 'Image',
    label: 'Image',
  },
  'Blocks::Store': {
    icon: 'Store',
    label: 'Store',
  },
}

const breadcrumbs = [
  {
    title: i18n.t('application.breadcrumbs.home'),
    link: routes.dashboard.show.path(),
  },
  { title: 'Link-in-Bio', link: routes.linkInBioBlockables.index.path() },
  { title: 'Compose' },
]

type IndexProps = InertiaSharedProps & {
  blockTypes: {
    value: string
    humanized: string
  }[]
  blocks: (LinkBlock | TextBlock | DividerBlock | FaqBlock | ImageBlock)[]
  hasProductWithContent: boolean
}

class CustomPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as const,
      handler: ({ nativeEvent: event }: { nativeEvent: PointerEvent }) => {
        const target = event.target as HTMLElement
        const shouldBePrevented =
          !event.isPrimary ||
          event.button !== 0 ||
          target?.closest('.link-in-bio-block-row-no-drag')
        if (shouldBePrevented) return false

        return true
      },
    },
  ]
}

export default function Index(props: IndexProps) {
  const [pickableListOpen, setPickableListOpen] = useState(false)
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const [blocks, setBlocks] = useState(props.blocks)
  const { blockTypes, hasProductWithContent } = props

  const sensors = useSensors(
    useSensor(CustomPointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  )

  const handleDragStart = (event: DragStartEvent) =>
    setActiveId(event.active.id)

  const updatePosition = async ({
    blocksBeforeUpdate,
    newPosition,
    updatedBlockId,
  }: {
    blocksBeforeUpdate: (
      | LinkBlock
      | TextBlock
      | DividerBlock
      | FaqBlock
      | ImageBlock
    )[]
    newPosition: number
    updatedBlockId: UniqueIdentifier | number
  }) => {
    const response = await patch(
      routes.linkInBioBlocksPositions.update.path({ block_id: updatedBlockId }),
      {
        body: JSON.stringify({
          block: {
            id: updatedBlockId,
            position: newPosition,
          },
        }),
        contentType: 'application/json',
        responseKind: 'application/json',
      }
    )

    if (!response.ok) setBlocks(() => blocksBeforeUpdate)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (!active || !over) return

    let requestData: {
      blocksBeforeUpdate: (
        | LinkBlock
        | TextBlock
        | DividerBlock
        | FaqBlock
        | ImageBlock
      )[]
      newPosition: number
      updatedBlockId: number
    } | null = null

    if (active.id !== over.id) {
      setBlocks((blocks) => {
        const updatedBlock = blocks.find((block) => block.id === active.id)
        if (!updatedBlock) return blocks

        const oldIndex = blocks.indexOf(updatedBlock)

        const overBlock = blocks.find((block) => block.id === over.id)
        if (!overBlock) return blocks

        const newIndex = blocks.indexOf(overBlock)

        requestData = {
          blocksBeforeUpdate: blocks,
          newPosition: newIndex,
          updatedBlockId: updatedBlock.id,
        }

        return arrayMove(blocks, oldIndex, newIndex)
      })
    }

    setActiveId(null)
    if (!requestData) return

    updatePosition(requestData)
  }

  const activeBlock = blocks.find((block) => block.id === activeId)

  return (
    <ContentSection>
      <ContentSection.Header>
        <DefaultNavbar breadcrumbs={breadcrumbs} />
      </ContentSection.Header>

      <ContentSection.Body>
        <KeyNavItems />

        {pickableListOpen && (
          <PickableBlocksModal
            blockTypes={blockTypes}
            open={pickableListOpen}
            setOpen={setPickableListOpen}
            hasProductWithContent={hasProductWithContent}
          />
        )}

        <div className="flex flex-wrap">
          <div className="flex w-full">
            <div className="w-full lg:w-1/2">
              <Card className="rounded-xl border border-border bg-background p-4">
                <Button
                  onClick={() => setPickableListOpen((open) => !open)}
                  className="w-full"
                >
                  <PlusIcon className="mr-2 h-4 w-4" />
                  {i18n.t('link_in_bio.blockables.index.add_block')}
                </Button>

                <div className="pt-4">
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={blocks}
                      strategy={verticalListSortingStrategy}
                    >
                      {blocks.map((block) => (
                        <SortableBlockRow
                          key={block.id}
                          block={block}
                          icon={rowsData[block.blockableType].icon}
                          label={rowsData[block.blockableType].label}
                        />
                      ))}
                    </SortableContext>
                    <DragOverlay>
                      {activeId && activeBlock ? (
                        <div className="bg-gray-100">
                          <BlockRow
                            block={activeBlock}
                            icon={rowsData[activeBlock.blockableType].icon}
                            label={rowsData[activeBlock.blockableType].label}
                            aria-pressed={true}
                          />
                        </div>
                      ) : null}
                    </DragOverlay>
                  </DndContext>
                </div>
              </Card>

              {blocks.length > 0 && !hasProductWithContent && (
                <ContinueOnboardingBanner />
              )}
            </div>

            <div className="w-full lg:w-1/2">
              <Preview
                blocks={blocks}
                editedBlockId={null}
                editedBlockRef={null}
              />
            </div>
          </div>
        </div>
      </ContentSection.Body>
    </ContentSection>
  )
}
