// JsFromRoutes CacheKey dd657756b9f464885ba69db3fdb698ec
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/app/creator/link_shortener/short_links'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/link_shortener/short_links'),
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/link_shortener/short_links/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/link_shortener/short_links/:slug/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/link_shortener/short_links/:slug'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/link_shortener/short_links/:slug'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/link_shortener/short_links/:slug'),
}
