import { Link } from '@/components/ui/link'
import useTypedPage from '@/hooks/use-typed-page'
import routes from '@/routes'
import { AlertCircle } from 'lucide-react'

const EmailConfirmationBanner = () => {
  const { currentUser } = useTypedPage().props

  if (currentUser?.confirmedAt) return null

  return (
    <div className="mx-2 mt-2 flex items-center space-x-3 rounded-xl border-none bg-yellow-100 px-4 py-2 text-center text-sm text-yellow-600">
      <AlertCircle className="h-4 w-4" />
      <div className="flex items-center justify-center">
        Please confirm your email address.
        <Link
          target="_blank"
          href={routes.usersConfirmations.new.path()}
          className="ml-2 underline"
        >
          Resend confirmation instructions
        </Link>
      </div>
    </div>
  )
}

export default EmailConfirmationBanner
