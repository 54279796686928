import { Button } from '@/components/ui/button'

import {
  Form,
  FormSubmit,
  FormTextarea,
} from '@/components/shared/inertia-form'
import { Link } from '@/components/ui/link'
import { ONBOARDING_STEPS } from '@/constants'
import routes from '@/routes'
import { cn, i18n } from '@/utils'

type ReferralSourceFormValues = {
  onboarding: {
    referral_source: string
    step: typeof ONBOARDING_STEPS.ReferralSource
  }
}

interface ReferralSourceFormProps {
  withBackButton?: boolean
  referralSource?: string
}

const ReferralSourceForm = ({
  withBackButton = true,
  referralSource,
}: ReferralSourceFormProps) => {
  const data: ReferralSourceFormValues = {
    onboarding: {
      referral_source: referralSource || '',
      step: ONBOARDING_STEPS.ReferralSource,
    },
  }

  return (
    <div className="flex flex-col justify-center gap-4">
      <h2 className="text-2xl font-bold">
        {i18n.t('onboarding.referral_source_title')}
      </h2>

      <Form
        model="onboarding"
        method="patch"
        to={routes.onboarding.update.path()}
        data={data}
        remember={false}
      >
        <FormTextarea
          name="referral_source"
          placeholder={i18n.t('onboarding.referral_source_placeholder')}
          required
          rows={6}
        />

        <div
          className={cn('flex', {
            'justify-between': withBackButton,
            'justify-end': !withBackButton,
          })}
        >
          {withBackButton && (
            <Button asChild variant="secondary">
              <Link
                href={routes.onboarding.show.path({
                  query: { step: ONBOARDING_STEPS.ContentNiche },
                })}
              >
                {i18n.t('back')}
              </Link>
            </Button>
          )}

          <FormSubmit className="self-end">{i18n.t('continue')}</FormSubmit>
        </div>
      </Form>
    </div>
  )
}

export default ReferralSourceForm
