import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { i18n } from '@/utils'
import { FormError } from '@/components/shared/form-error'
import PricingOption from '@/types/generated/PricingOption'

type PricingDetailsStepProps = {
  pricingData: Omit<PricingOption, 'id'>
  setPricingData: (key: keyof PricingOption, value: any) => void
  errors: { [K in keyof PricingOption]?: string | null }
}

export default function PricingDetailsStep({
  pricingData,
  setPricingData,
  errors,
}: PricingDetailsStepProps) {
  return (
    <div className="grid gap-4 py-4">
      <div className="grid grid-cols-4 items-center gap-4">
        <Label htmlFor="product-option-name" className="col-span-1 text-right">
          {i18n.t('products.new_product.pricing_details_step.pricing_name')}
        </Label>
        <Input
          type="text"
          id="product-option-name"
          placeholder="Pricing option name"
          className="col-span-3"
          onChange={(e) => setPricingData('name', e.target.value)}
          value={pricingData.name}
        />
        {errors.name && (
          <>
            <div className="col-span-1"></div>
            <FormError error={errors.name} className="col-span-3 mt-0" />
          </>
        )}
      </div>

      {pricingData.type === 'paid' && (
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="product-price" className="col-span-1 text-right">
            {i18n.t('products.new_product.pricing_details_step.product_price')}
          </Label>
          <Input
            type="number"
            min={0.01}
            id="product-price"
            placeholder="Product Price"
            className="col-span-3"
            onChange={(e) => setPricingData('price', e.target.value)}
            value={pricingData.price}
          />
          {errors.price && (
            <>
              <div className="col-span-1"></div>
              <FormError error={errors.price} className="col-span-3 mt-0" />
            </>
          )}
        </div>
      )}

      {pricingData.type === 'paid' && (
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="product-currency" className="col-span-1 text-right">
            {i18n.t('products.new_product.pricing_details_step.currency')}
          </Label>
          <Select
            value={pricingData.currency}
            onValueChange={(value) => setPricingData('currency', value)}
            defaultValue="USD"
          >
            <SelectTrigger
              id="product-currency"
              className={`form-control col-span-3 ${false ? 'border-red-500' : ''}`}
            >
              <SelectValue placeholder="Currency" />
            </SelectTrigger>
            {/* TODO: change later */}
            <SelectContent>
              <SelectItem value="USD">USD</SelectItem>
              <SelectItem value="GBP">GBP</SelectItem>
              <SelectItem value="EUR">EUR</SelectItem>
            </SelectContent>
          </Select>
          {errors.currency && (
            <>
              <div className="col-span-1"></div>
              <FormError error={errors.currency} className="col-span-3 mt-0" />
            </>
          )}
        </div>
      )}

      <div className="grid grid-cols-4 items-center gap-4">
        <Label className="col-span-1 text-right">Waitlist</Label>
        <RadioGroup className="flex">
          <Label htmlFor="product-waitlist-yes">{i18n.t('yes')}</Label>
          <RadioGroupItem
            className="min-h-4 min-w-4"
            id="product-waitlist-yes"
            value="true"
            onClick={() => setPricingData('waitlist', true)}
            checked={pricingData.waitlist === true}
          />
          <Label htmlFor="product-waitlist-no" className="ml-2">
            {i18n.t('no')}
          </Label>
          <RadioGroupItem
            className="min-h-4 min-w-4"
            id="product-waitlist-no"
            value="false"
            onClick={() => setPricingData('waitlist', false)}
            checked={pricingData.waitlist === false}
          />
        </RadioGroup>
        {errors.waitlist && (
          <>
            <div className="col-span-1"></div>
            <FormError error={errors.waitlist} className="col-span-3 mt-0" />
          </>
        )}
      </div>

      <div className="grid grid-cols-4 items-center gap-4">
        <Label className="col-span-1 text-right">Free trial</Label>
        <RadioGroup className="flex">
          <Label htmlFor="product-free-trial-yes">{i18n.t('yes')}</Label>
          <RadioGroupItem
            className="min-h-4 min-w-4"
            id="product-free-trial-yes"
            value="true"
            onClick={() => setPricingData('freeTrial', true)}
            checked={pricingData.freeTrial === true}
          />
          <Label htmlFor="product-free-trial-no" className="ml-2">
            {i18n.t('no')}
          </Label>
          <RadioGroupItem
            className="min-h-4 min-w-4"
            id="product-free-trial-no"
            value="false"
            onClick={() => setPricingData('freeTrial', false)}
            checked={pricingData.freeTrial === false}
          />
        </RadioGroup>
        {errors.freeTrial && (
          <>
            <div className="col-span-1"></div>
            <FormError error={errors.freeTrial} className="col-span-3 mt-0" />
          </>
        )}
      </div>
    </div>
  )
}
