import AddFileDialog from '@/components/features/products/content-dialogs/add-file'
import { ContentItemsTable } from '@/components/features/products/content-items-table'
import PricingOptionsTable from '@/components/features/products/edit/pricing-options-table'
import NewPricingOptionDialog from '@/components/features/products/new-pricing-option'
import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Link } from '@/components/ui/link'
import { ContentSection } from '@/layouts/app/sections/content-section'
import routes from '@/routes'
import { Product } from '@/types'
import ContentItem from '@/types/ContentItem'
import ContentItemsTypes from '@/types/generated/ContentItemsTypes'
import { i18n } from '@/utils'
import { AppWindow, Eye, EyeOff, Package, Plus } from 'lucide-react'

interface ProductEditPageProps {
  product: Product
  contentItemsTypes: ContentItemsTypes[]
  assignableFileContentItems: ContentItem[]
}

export default function ProductEditPage(props: ProductEditPageProps) {
  const { product, contentItemsTypes } = props
  const { pricingOptions, items: contentItems } = product

  const breadcrumbs = [
    {
      title: i18n.t('application.breadcrumbs.home'),
      link: routes.dashboard.show.path(),
    },
    {
      title: i18n.t('products.breadcrumbs.title'),
      link: routes.products.index.path(),
    },
    { title: product.name },
  ]

  return (
    <ContentSection>
      <ContentSection.Header>
        <DefaultNavbar breadcrumbs={breadcrumbs} />
      </ContentSection.Header>
      <ContentSection.Body className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">{product.name}</h1>
          <Link
            href={routes.products.update.path({
              prefix_id: product.prefixId,
            })}
            method="patch"
            data={{ product: { visible: !product.visible } }}
          >
            {product.visible ? (
              <Button variant="outline" className="flex items-center space-x-2">
                <Eye className="h-4 w-4" />
                <span>{i18n.t('products.edit.visible')}</span>
              </Button>
            ) : (
              <Button variant="outline" className="flex items-center space-x-2">
                <EyeOff className="h-4 w-4" />
                <span>{i18n.t('products.edit.hidden')}</span>
              </Button>
            )}
          </Link>
        </div>

        <Card>
          <CardHeader>
            <CardTitle>
              {i18n.t('products.edit.pricing_options_title')}
            </CardTitle>
            <CardDescription>
              {i18n.t('products.edit.pricing_options_description')}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="mb-4 flex justify-end">
              <NewPricingOptionDialog product={product} />
            </div>
            {pricingOptions.length > 0 && (
              <PricingOptionsTable pricingOptions={pricingOptions} />
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>{i18n.t('products.edit.content_title')}</CardTitle>
            <CardDescription>
              {i18n.t('products.edit.content_description')}
            </CardDescription>
          </CardHeader>
          {contentItems.length > 0 ? (
            <CardContent>
              <ContentItemsTable
                contentItems={contentItems as ContentItem[]}
                productId={product.id}
              />
            </CardContent>
          ) : (
            <CardContent className="flex h-48 flex-col items-center justify-center">
              <Package className="h-12 w-12 text-muted-foreground" />
              <p className="mt-4 text-lg font-medium">
                {i18n.t('products.edit.no_items_included')}
              </p>
              <p className="text-muted-foreground">
                {i18n.t('products.edit.add_content_description')}
              </p>
            </CardContent>
          )}
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>
              {contentItemsTypes.length === 0 && product.items.length === 0
                ? i18n.t('products.edit.add_content')
                : i18n.t('products.edit.add_more_content')}
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-3">
            {contentItemsTypes.map((type, index) => (
              <div
                key={index}
                className="flex items-center justify-between rounded-xl border p-4 shadow-sm"
              >
                <div className="flex items-center space-x-4">
                  <AppWindow className="h-8 w-8 text-muted-foreground" />
                  <div>
                    <h3 className="text-sm font-medium">{type.name}</h3>
                    <p className="text-sm text-muted-foreground">
                      {type.description}
                    </p>
                  </div>
                </div>
                {type.name === 'DigitalDownload' ? (
                  <AddFileDialog productId={product.id} />
                ) : null}
              </div>
            ))}

            {contentItemsTypes.length === 0 && (
              <div className="flex h-48 flex-col items-center justify-center">
                <Package className="h-12 w-12 text-muted-foreground" />
                <p className="mt-4 text-lg font-medium">
                  {product.items.length === 0
                    ? i18n.t('products.edit.no_content_added')
                    : i18n.t('products.edit.all_items_added')}
                </p>
                <p className="text-muted-foreground">
                  {i18n.t('products.edit.click_to_add_content')}
                </p>
                <Link href={routes.contentItems.index.path()}>
                  <Button size="sm" className="mt-4">
                    <Plus className="h-4 w-4" />
                    <span className="ml-2">
                      {product.items.length === 0
                        ? i18n.t('products.edit.add_content')
                        : i18n.t('products.edit.add_more_content')}
                    </span>
                  </Button>
                </Link>
              </div>
            )}
          </CardContent>
        </Card>
      </ContentSection.Body>
    </ContentSection>
  )
}
