import { useState } from 'react'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import routes from '@/routes'
import { i18n } from '@/utils'
import { Trash } from 'lucide-react'
import { Link } from '@inertiajs/react'

type DeletePricingOptionProps = {
  pricingOptionName: string
  pricingOptionId: number
}

export default function DeletePricingOption({
  pricingOptionName,
  pricingOptionId,
}: DeletePricingOptionProps) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon">
          <Trash className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]" showCloseButton={false}>
        <DialogHeader>
          <DialogTitle className="text-center">
            {i18n.t('pricing_options.delete_dialog.are_you_sure')}
          </DialogTitle>
          <DialogDescription className="pt-2 text-center">
            {i18n.t('pricing_options.delete_dialog.description', {
              pricing_option_name: pricingOptionName,
            })}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={() => setIsOpen(false)}>
            {i18n.t('back')}
          </Button>
          <Link
            href={routes.pricingOptions.destroy.path({ id: pricingOptionId })}
            method="delete"
          >
            <Button variant="destructive">{i18n.t('confirm')}</Button>
          </Link>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
