// JsFromRoutes CacheKey 124bcb24c63b8e4b571818e6341760ad
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/content'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/content/:prefix_id/edit'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/content/:prefix_id'),
}
