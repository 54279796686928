import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { CourseLesson } from '@/types'
import { i18n } from '@/utils'
import { MoreHorizontal } from 'lucide-react'

interface LessonActionsMenuProps {
  lesson: CourseLesson
  setIsLessonDeleteDialogOpen: (isOpen: boolean) => void
}

export default function LessonActionsMenu({
  lesson,
  setIsLessonDeleteDialogOpen,
}: LessonActionsMenuProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="" asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Open menu</span>
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {lesson.published === true ? (
          <DropdownMenuItem>Revert to draft</DropdownMenuItem>
        ) : (
          <DropdownMenuItem>Publish</DropdownMenuItem>
        )}
        <DropdownMenuItem>Duplicate</DropdownMenuItem>
        <DropdownMenuItem
          variant="destructive"
          onSelect={() => setIsLessonDeleteDialogOpen(true)}
        >
          {i18n.t('products.products_table.actions.delete')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
