import { FaqList } from '@/components/shared/faq-list'
import Meta from '@/components/shared/meta'
import { Input } from '@/components/ui/input'
import { APP_NAME } from '@/constants'
import useTypedPage from '@/hooks/use-typed-page'
import { DollarSign } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const faqList = [
  {
    question: 'What is a Refund Impact Calculator?',
    answer:
      'A Refund Impact Calculator helps you estimate how refunds impact your net earnings after accounting for platform fees.',
  },
  {
    question: 'How do I calculate the adjusted earnings after refunds?',
    answer:
      'To calculate the adjusted earnings, input the total sales, refund percentage, and platform fee percentage. The calculator will determine your net earnings after accounting for refunds and platform fees.',
  },
  {
    question: 'What is the total sales?',
    answer:
      'The total sales is the total revenue generated from selling your products.',
  },
  {
    question: 'What is the refund percentage?',
    answer:
      'The refund percentage is the percentage of total sales that are refunded.',
  },
  {
    question: 'What is the platform fee?',
    answer:
      'The platform fee is a percentage of the earnings that the platform charges for using its services.',
  },
]

const RefundImpactCalculatorPage: React.FC = () => {
  const { name, description } = useTypedPage().props
  const [totalSales, setTotalSales] = useState<number>(1000)
  const [refundPercentage, setRefundPercentage] = useState<number>(5) // percentage
  const [platformFee, setPlatformFee] = useState<number>(10) // percentage
  const [adjustedEarnings, setAdjustedEarnings] = useState<string>('855.00')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const calculateAdjustedEarnings = () => {
    const refundAmount = (totalSales * refundPercentage) / 100
    const earningsAfterRefund = totalSales - refundAmount
    const adjustedEarnings =
      earningsAfterRefund - (earningsAfterRefund * platformFee) / 100
    setAdjustedEarnings(formatter.format(adjustedEarnings))
  }

  useEffect(() => {
    calculateAdjustedEarnings()
  }, [totalSales, refundPercentage, platformFee])

  const metaTitle = `${name} | ${APP_NAME}`
  const metaDescription = description as string

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container mx-auto max-w-5xl">
        <div className="overflow-hidden">
          <div className="mx-auto mb-6 max-w-3xl text-center">
            <h1 className="mb-5 font-heading text-5xl font-medium">
              Refund Impact Calculator
            </h1>
            <p className="text-neutral-500">
              Estimate how refunds impact your net earnings after accounting for
              platform fees.
            </p>
          </div>
          <div className="rounded-md border border-border bg-accent p-5">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
              <div className="col-span-1">
                <label htmlFor="total_sales" className="font-semibold">
                  Total Sales (USD)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What is the total revenue generated from selling your
                  products?
                </div>
                <Input
                  type="number"
                  id="total_sales"
                  value={totalSales}
                  onChange={(e) => setTotalSales(Number(e.target.value))}
                  placeholder="Total Sales"
                  className=""
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="refund_percentage" className="font-semibold">
                  Refund Percentage (%)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What percentage of total sales are refunded?
                </div>
                <Input
                  type="number"
                  id="refund_percentage"
                  value={refundPercentage}
                  onChange={(e) => setRefundPercentage(Number(e.target.value))}
                  placeholder="Refund Percentage (%)"
                  className=""
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="platform_fee" className="font-semibold">
                  Platform Fee Percentage (%)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What percentage does the platform take?
                </div>
                <Input
                  type="number"
                  id="platform_fee"
                  value={platformFee}
                  onChange={(e) => setPlatformFee(Number(e.target.value))}
                  placeholder="Platform Fee (%)"
                  className=""
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="mt-6 rounded-md border border-border bg-background px-4 py-5 sm:p-6">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <DollarSign className="mr-2 inline-block" />
                    Adjusted Earnings:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="adjusted-earnings-value">{adjustedEarnings}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FaqList faqList={faqList} />
      </div>
    </>
  )
}

export default RefundImpactCalculatorPage
