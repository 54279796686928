import {
  APP_APPLE_TOUCH_ICON_PATH,
  APP_FAVICON_DEV_PATH,
  APP_FAVICON_PATH,
  APP_META_SITE_DESCRIPTION,
  APP_META_SITE_IMAGE,
  APP_META_SITE_TITLE,
  APP_NAME,
  APP_WEB_MANIFEST_PATH,
} from '@/constants'
import { isProduction } from '@/utils'
import { Head } from '@inertiajs/react'
import { FC, ReactNode } from 'react'

export interface MetaProps {
  title?: string | null
  description?: string | null
  imageUrl?: string | null
  noIndex?: boolean
  children?: ReactNode
}

const Meta: FC<MetaProps> = ({
  title = APP_META_SITE_TITLE,
  description = APP_META_SITE_DESCRIPTION,
  imageUrl = APP_META_SITE_IMAGE,
  noIndex,
  children,
}) => {
  const metaTitle = title ? title : APP_META_SITE_TITLE
  const metaDescription = description ? description : APP_META_SITE_DESCRIPTION

  const faviconUrl = isProduction() ? APP_FAVICON_PATH : APP_FAVICON_DEV_PATH
  const appleTouchIconUrl = APP_APPLE_TOUCH_ICON_PATH
  const manifestUrl = APP_WEB_MANIFEST_PATH

  return (
    <>
      <Head>
        {/* SEO */}
        <title>{metaTitle}</title>
        <meta
          head-key="description"
          name="description"
          content={metaDescription}
        />

        {/* Open Graph */}
        <meta
          head-key="og:site_name"
          property="og:site_name"
          content={APP_NAME}
        />
        <meta head-key="og:type" property="og:type" content="website" />
        <meta head-key="og:title" property="og:title" content={metaTitle} />
        <meta
          head-key="og:description"
          property="og:description"
          content={metaDescription}
        />
        {!!imageUrl && (
          <meta head-key="og:image" property="og:image" content={imageUrl} />
        )}

        {/* Twitter */}
        <meta head-key="twitter:card" name="twitter:card" content="summary" />
        <meta
          head-key="twitter:title"
          name="twitter:title"
          content={metaTitle}
        />
        <meta
          head-key="twitter:description"
          name="twitter:description"
          content={metaDescription}
        />
        {!!imageUrl && (
          <meta
            head-key="twitter:image"
            name="twitter:image"
            content={imageUrl}
          />
        )}

        {/* Robots */}
        {noIndex && <meta head-key="robots" name="robots" content="noindex" />}

        {/* Favicons */}
        <link
          head-key="favicon"
          rel="icon"
          href={faviconUrl}
          type="image/x-icon"
        />
        <link
          head-key="apple-touch-icon"
          rel="apple-touch-icon"
          href={appleTouchIconUrl}
          type="image/png"
        />
        <link head-key="manifest" rel="manifest" href={manifestUrl} />
        {children}
      </Head>
    </>
  )
}

export default Meta
