import { linkInBioBlockables } from '@/routes/generated'
import { i18n } from '@/utils'

import { TextBlock } from '@/types/blocks/blockable'

import {
  Form,
  FormInput,
  FormTextarea,
  FormRadioGroup,
  FormSubmit,
  FormSelect,
} from '@/components/shared/inertia-form'
import { BOOLEAN_STRING_OPTIONS } from '@/constants/blocks/available-forms'
import TextBlockable from '@/types/blocks/text'

const HORIZONTAL_ALIGNMENTS = [
  {
    value: 'left',
    label: i18n.t('link_in_bio.blockables.forms.text.left'),
  },
  {
    value: 'center',
    label: i18n.t('link_in_bio.blockables.forms.text.center'),
  },
  {
    value: 'right',
    label: i18n.t('link_in_bio.blockables.forms.text.right'),
  },
]

type TextFormData = {
  blockable: {
    id?: number
    type: string
    title: string
    headline: string
    body: string
    horizontal_alignment: TextBlockable['horizontalAlignment']
    background: string
  }
}

type TextFormProps = {
  editedBlock: TextBlock
  setEditedBlock: (block: TextBlock) => void
}

const TextForm = ({ editedBlock, setEditedBlock }: TextFormProps) => {
  const { blockable, blockableType } = editedBlock
  const { id, title, headline, body, horizontalAlignment, background } =
    blockable

  const data: TextFormData = {
    blockable: {
      id: id,
      type: blockableType,
      title,
      headline,
      body,
      horizontal_alignment: horizontalAlignment,
      background: background
        ? BOOLEAN_STRING_OPTIONS.True
        : BOOLEAN_STRING_OPTIONS.False,
    },
  }

  const handleChange = ({ data }: { data: TextFormData }) => {
    const {
      blockable: {
        id,
        title,
        headline,
        body,
        horizontal_alignment,
        background,
      },
    } = data

    setEditedBlock({
      ...editedBlock,
      blockable: {
        id,
        title,
        headline,
        body,
        horizontalAlignment: horizontal_alignment,
        background: background === BOOLEAN_STRING_OPTIONS.True,
      },
    })
  }

  return (
    <Form
      model="blockable"
      method={id ? 'put' : 'post'}
      to={
        id
          ? linkInBioBlockables.update.path({ id })
          : linkInBioBlockables.create.path()
      }
      data={data}
      onChange={handleChange}
    >
      <FormInput
        name="title"
        label={i18n.t('link_in_bio.blockables.forms.text.title')}
        required
      />

      <FormInput
        name="headline"
        label={i18n.t('link_in_bio.blockables.forms.text.headline')}
        required
      />

      <FormTextarea
        name="body"
        label={i18n.t('link_in_bio.blockables.forms.text.body')}
        required
      />

      <FormSelect
        label={i18n.t('link_in_bio.blockables.forms.text.horizontal_alignment')}
        name="horizontal_alignment"
        placeholder={i18n.t('forms.placeholders.select')}
        options={HORIZONTAL_ALIGNMENTS}
      />

      <FormRadioGroup
        label={i18n.t('link_in_bio.blockables.forms.text.is_background')}
        name="background"
        options={[
          { value: BOOLEAN_STRING_OPTIONS.True, label: i18n.t('yes') },
          { value: BOOLEAN_STRING_OPTIONS.False, label: i18n.t('no') },
        ]}
      />

      <FormSubmit>{i18n.t('save')}</FormSubmit>
    </Form>
  )
}

export default TextForm
