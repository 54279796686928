import Meta from '@/components/shared/meta'
import { Button } from '@/components/ui/button'
import FullScreenLayout from '@/layouts/full-screen-layout'
import { i18n } from '@/utils'
import { router } from '@inertiajs/react'
import { ReactNode } from 'react'

interface ErrorPageProps {
  status: number
}

const ErrorPage = ({ status }: ErrorPageProps) => {
  const title = i18n.t(`error_pages.title.${status}`, {
    default: i18n.t('error_pages.title.default'),
  })
  const description = i18n.t(`error_pages.description.${status}`, {
    default: '',
  })

  return (
    <>
      <Meta title={i18n.t('error_pages.page_title')} />
      <div className="h-svh">
        <div className="m-auto flex h-full w-full flex-col items-center justify-center gap-2">
          <h1 className="font-heading text-[7rem] leading-tight">{status}</h1>
          <span className="text-xl font-medium">{title}</span>
          <p className="text-center text-muted-foreground">{description}</p>
          <div className="mt-6 flex gap-4">
            {status !== 503 && (
              <>
                <Button variant="outline" onClick={() => window.history.back()}>
                  {i18n.t('error_pages.buttons.go_back')}
                </Button>
                <Button onClick={() => router.visit('/')}>
                  {i18n.t('error_pages.buttons.back_to_home')}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

ErrorPage.layout = (page: ReactNode) => (
  <FullScreenLayout>{page}</FullScreenLayout>
)

export default ErrorPage
