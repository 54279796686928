// JsFromRoutes CacheKey 20c11bcad594c54fc1dca1728464f012
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/accounts/:account_id/social_links'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/accounts/:account_id/social_links/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/accounts/:account_id/social_links/:id'),
}
