// JsFromRoutes CacheKey 88800677053a011cf01399f8ca3f4767
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  dashboardWizard: /* #__PURE__ */ definePathHelper('post', '/app/creator/onboarding/dashboard_wizard'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/onboarding'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/onboarding'),
}
