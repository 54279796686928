// JsFromRoutes CacheKey dc8de97fb627cd5753c60343dc84b4b7
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/connected_accounts'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/connected_accounts'),
  new: /* #__PURE__ */ definePathHelper('get', '/admin/connected_accounts/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/connected_accounts/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/connected_accounts/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/connected_accounts/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/connected_accounts/:id'),
}
