import { FormError } from '@/components/shared/inertia-form/form-error'
import { Label } from '@/components/ui/label'
import {
  NestedObject,
  UseFormProps,
  useInertiaInput,
} from '@/lib/use-inertia-form'
import { cn } from '@/utils'
import * as ReactSlider from '@radix-ui/react-slider'
import { type SliderProps as ReactSliderProps } from '@radix-ui/react-slider'
import { ReactNode } from 'react'

interface FormSliderProps<TForm extends NestedObject = NestedObject>
  extends Omit<ReactSliderProps, 'onChange'> {
  label?: string
  helperText?: string | ReactNode
  name: string
  model?: string
  onChange?: (value: number, form: UseFormProps<TForm>) => void
}

const FormSlider = <TForm extends NestedObject = NestedObject>({
  label,
  name,
  model,
  helperText,
  className,
  onChange,
  ...props
}: FormSliderProps<TForm>) => {
  const { form, inputName, inputId, value, setValue, error } = useInertiaInput<
    number,
    TForm
  >({ name, model })

  return (
    <div className="flex flex-col gap-4">
      {label && <Label htmlFor={inputId}>{label}</Label>}

      <ReactSlider.Root
        id={inputId}
        name={inputName}
        onValueChange={(newValue) => {
          if (onChange) onChange(newValue[0], form)
          setValue(newValue[0])
        }}
        value={value ? [value] : undefined}
        className={cn(
          'relative flex w-full touch-none select-none items-center',
          className
        )}
        {...props}
      >
        <ReactSlider.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-secondary">
          <ReactSlider.Range className="absolute h-full bg-primary" />
        </ReactSlider.Track>
        <ReactSlider.Thumb className="block h-5 w-5 rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
      </ReactSlider.Root>

      {helperText && (
        <p className="text-xs text-muted-foreground">{helperText}</p>
      )}

      <FormError name={label || name} error={error} />
    </div>
  )
}

export default FormSlider
