// JsFromRoutes CacheKey 2f92241c06b902d41fe8e1899dc78414
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/pause/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/pause/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/pause'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/subscriptions/:subscription_id/pause'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/subscriptions/:subscription_id/pause'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/subscriptions/:subscription_id/pause'),
}
