import NameStep from '@/components/features/products/new-product/steps/name-step'
import PricingDetailsStep from '@/components/features/products/new-product/steps/pricing-details-step'
import PricingTypeStep from '@/components/features/products/new-product/steps/pricing-type-step'
import ReviewStep from '@/components/features/products/new-product/steps/review-step'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Progress } from '@/components/ui/progress'
import useTypedPage from '@/hooks/use-typed-page'
import routes from '@/routes'
import { Product } from '@/types'
import PricingOption from '@/types/generated/PricingOption'
import { i18n } from '@/utils'
import { deepTransform, toSnakeCase } from '@/utils/objects'
import { router } from '@inertiajs/react'
import { PlusIcon } from 'lucide-react'
import { useEffect, useState } from 'react'

const steps = [
  {
    title: i18n.t('products.new_product.dialog.steps.zero.title'),
    description: i18n.t('products.new_product.dialog.steps.zero.description'),
  },
  {
    title: i18n.t('products.new_product.dialog.steps.one.title'),
    description: i18n.t('products.new_product.dialog.steps.one.description'),
  },
  {
    title: i18n.t('products.new_product.dialog.steps.two.title'),
    description: i18n.t('products.new_product.dialog.steps.two.description'),
  },
  {
    title: i18n.t('products.new_product.dialog.steps.three.title'),
    description: i18n.t('products.new_product.dialog.steps.three.description'),
  },
]

export const initialProductData = {
  name: '',
  pricingOptionsAttributes: [
    {
      type: 'paid',
      price: 0,
      name: '',
      waitlist: false,
      freeTrial: false,
      currency: 'USD',
    },
  ],
}

type ProductErrors = { [K in keyof Product]?: string | null } & {
  'pricingOptions.type'?: string | null
  'pricingOptions.name'?: string | null
  'pricingOptions.price'?: string | null
  'pricingOptions.currency'?: string | null
  'pricingOptions.waitlist'?: string | null
  'pricingOptions.freeTrial'?: string | null
}

export default function NewProductDialog() {
  const errors = useTypedPage().props.errors ?? {}
  const productErrors = (errors.product ?? {}) as ProductErrors
  const [currentStep, setCurrentStep] = useState(0)
  const [productData, setProductData] = useState(initialProductData)

  useEffect(() => {
    if (!Object.keys(productErrors).length) return

    const productErrorsNames = Object.keys(productErrors)
    const handledErrors = [
      'name',
      'pricingOptions.type',
      'pricingOptions.name',
      'pricingOptions.price',
      'pricingOptions.currency',
      'pricingOptions.waitlist',
      'pricingOptions.freeTrial',
    ]
    const unhandledErrorExists =
      productErrorsNames.filter((error) => !handledErrors.includes(error))
        .length > 0

    if (productErrors?.name) {
      setCurrentStep(() => 0)
    } else if (productErrors['pricingOptions.type']) {
      setCurrentStep(() => 1)
    } else if (
      productErrorsNames.includes('pricingOptions.name') ||
      productErrorsNames.includes('pricingOptions.price') ||
      productErrorsNames.includes('pricingOptions.currency') ||
      productErrorsNames.includes('pricingOptions.waitlist') ||
      productErrorsNames.includes('pricingOptions.freeTrial')
    ) {
      setCurrentStep(() => 2)
    } else if (unhandledErrorExists) {
      router.visit(routes.products.index.path(), { preserveState: false })
      // toast({ title: "An error occurred while creating your product", description: "Please try again" })
    }
  }, [errors])

  const handleNext = () => setCurrentStep((prevStep) => prevStep + 1)
  const handleBack = () => setCurrentStep((prevStep) => prevStep - 1)

  const handleSubmit = () => {
    router.post(
      routes.products.create.path(),
      { product: deepTransform(productData, toSnakeCase) },
      { preserveState: 'errors' }
    )
  }

  const onPricingAttributeChange = (
    property: keyof PricingOption,
    value: any
  ) => {
    setProductData((currentData) => {
      return {
        ...currentData,
        pricingOptionsAttributes: [
          { ...currentData.pricingOptionsAttributes[0], [property]: value },
        ],
      }
    })
  }
  const pricingData = productData.pricingOptionsAttributes[0] as Omit<
    PricingOption,
    'id'
  >

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>
          <PlusIcon className="mr-2 h-4 w-4" />
          {i18n.t('products.new_product.dialog.trigger')}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]" showCloseButton={false}>
        <Progress value={(currentStep / steps.length) * 100} />
        <DialogHeader>
          <DialogTitle className="text-center">
            {steps[currentStep].title}
          </DialogTitle>
          <DialogDescription className="text-center">
            {steps[currentStep].description}
          </DialogDescription>
        </DialogHeader>

        {currentStep === 0 ? (
          <NameStep
            productData={productData}
            setProductData={setProductData}
            errors={productErrors}
          />
        ) : currentStep === 1 ? (
          <PricingTypeStep
            pricingData={pricingData}
            setPricingData={onPricingAttributeChange}
            errors={{
              type: productErrors['pricingOptions.type'],
            }}
          />
        ) : currentStep === 2 ? (
          <PricingDetailsStep
            pricingData={pricingData}
            setPricingData={onPricingAttributeChange}
            errors={{
              name: productErrors['pricingOptions.name'],
              price: productErrors['pricingOptions.price'],
              currency: productErrors['pricingOptions.currency'],
              waitlist: productErrors['pricingOptions.waitlist'],
              freeTrial: productErrors['pricingOptions.freeTrial'],
            }}
          />
        ) : currentStep === 3 ? (
          <ReviewStep />
        ) : null}

        <DialogFooter>
          {currentStep > 0 ? (
            <Button variant="outline" onClick={handleBack}>
              {i18n.t('back')}
            </Button>
          ) : (
            <DialogClose asChild>
              <Button variant="outline">{i18n.t('back')}</Button>
            </DialogClose>
          )}

          {currentStep < steps.length - 1 ? (
            <Button type="button" onClick={handleNext}>
              {i18n.t('continue')}
            </Button>
          ) : (
            <Button type="submit" onClick={handleSubmit}>
              {i18n.t('confirm')}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
