import LoginForm from '@/components/features/auth/forms/login-form'
import Meta from '@/components/shared/meta'
import { Link } from '@/components/ui/link'
import { APP_NAME } from '@/constants'
import AuthLayout from '@/layouts/auth-layout'
import routes from '@/routes'
import { ReactNode } from 'react'

const LoginPage = () => {
  const metaTitle = `Sign in | ${APP_NAME}`
  const metaDescription = `Sign in to your ${APP_NAME} account`

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="mb-4 flex flex-col space-y-2">
        <h1 className="text-center text-xl font-semibold tracking-tight">
          Sign in to {APP_NAME}
        </h1>
      </div>
      <div className="mb-5">
        <LoginForm />
      </div>
      <p className="mt-4 border-t border-border pt-5 text-center text-sm text-muted-foreground">
        New to {APP_NAME}?{' '}
        <Link
          href={routes.usersRegistrations.new.path()}
          className="underline underline-offset-4 hover:text-primary"
        >
          Create an account
        </Link>
      </p>
    </>
  )
}

LoginPage.layout = (page: ReactNode) => <AuthLayout>{page}</AuthLayout>

export default LoginPage
