import { i18n } from '@/utils'

import { ImageBlock } from '@/types/blocks/blockable'

import ImagePicker from './image-picker'
import {
  Form,
  FormInput,
  FormRadioGroup,
  FormSelect,
  FormSlider,
  FormSubmit,
  FormTextarea,
} from '@/components/shared/inertia-form'
import { linkInBioBlockables } from '@/routes/generated'
import { BOOLEAN_STRING_OPTIONS } from '@/constants/blocks/available-forms'
import { Image } from '@/types'
import { UseFormProps } from '@/lib/use-inertia-form'

const ASPECT_RATIOS = [
  { value: 'auto', label: 'Auto' },
  { value: '1:1', label: '1:1' },
  { value: '16:9', label: '16:9' },
]

const LAYOUTS = [
  { value: 'column', label: 'Column' },
  { value: 'carousel', label: 'Carousel' },
]

interface ImageFormData {
  blockable: {
    id?: number
    type: string
    title: string
    headline: string
    description: string
    aspect_ratio: string
    scale: number
    layout: string
    mobile_stretch: string
    images: (Image | File | string)[]
  }
}

type ImageFormProps = {
  editedBlock: ImageBlock
  setEditedBlock: (block: ImageBlock) => void
}
const ImageForm = ({ editedBlock, setEditedBlock }: ImageFormProps) => {
  const { blockable, blockableType } = editedBlock
  const {
    id,
    title,
    headline,
    description,
    aspectRatio,
    scale,
    layout,
    mobileStretch,
    images,
  } = blockable

  const data: ImageFormData = {
    blockable: {
      id: id,
      type: blockableType,
      title: title || '',
      headline: headline || '',
      description: description || '',
      aspect_ratio: aspectRatio || ASPECT_RATIOS[0].value,
      scale: scale || 0,
      layout: layout || LAYOUTS[0].value,
      mobile_stretch: mobileStretch
        ? BOOLEAN_STRING_OPTIONS.True
        : BOOLEAN_STRING_OPTIONS.False,
      images: images || [],
    },
  }

  const handleChange = ({ data }: { data: ImageFormData }) => {
    const {
      blockable: {
        title,
        headline,
        description,
        aspect_ratio,
        scale,
        layout,
        mobile_stretch,
        images,
      },
    } = data

    setEditedBlock({
      ...editedBlock,
      blockable: {
        id,
        title,
        headline,
        description,
        aspectRatio: aspect_ratio,
        scale,
        layout,
        mobileStretch: mobile_stretch === BOOLEAN_STRING_OPTIONS.True,
        images: images as (File | Image)[],
      },
    })
  }
  const handleSubmit = ({ transform }: UseFormProps<ImageFormData>) => {
    transform((data) => ({
      blockable: {
        ...data.blockable,
        images: (data.blockable.images as (File | Image)[]).map((image) =>
          image instanceof File ? image : image.signedId
        ),
      },
    }))
  }

  return (
    <Form
      model="blockable"
      method={id ? 'put' : 'post'}
      to={
        id
          ? linkInBioBlockables.update.path({ id })
          : linkInBioBlockables.create.path()
      }
      data={data}
      onChange={handleChange}
      railsAttributes={false}
      onSubmit={handleSubmit}
    >
      <FormInput
        name="title"
        label={i18n.t('link_in_bio.blockables.forms.image.title')}
        required
      />

      <FormInput
        name="headline"
        label={i18n.t('link_in_bio.blockables.forms.image.headline')}
      />

      <FormTextarea
        name="description"
        label={i18n.t('link_in_bio.blockables.forms.image.description')}
      />

      <FormSelect
        name="aspect_ratio"
        label={i18n.t('link_in_bio.blockables.forms.image.aspect_ratio')}
        placeholder={i18n.t('forms.placeholders.select')}
        options={ASPECT_RATIOS}
      />

      <FormSlider
        label={i18n.t('link_in_bio.blockables.forms.image.scale')}
        name="scale"
        min={0}
        max={100}
        step={5}
      />

      <FormSelect
        name="layout"
        label={i18n.t('link_in_bio.blockables.forms.image.layout')}
        placeholder={i18n.t('forms.placeholders.select')}
        options={LAYOUTS}
      />

      <FormRadioGroup
        name="mobile_stretch"
        label={i18n.t('link_in_bio.blockables.forms.image.mobile_stretch')}
        options={[
          { value: BOOLEAN_STRING_OPTIONS.True, label: i18n.t('yes') },
          { value: BOOLEAN_STRING_OPTIONS.False, label: i18n.t('no') },
        ]}
      />

      <ImagePicker />

      <FormSubmit>{i18n.t('save')}</FormSubmit>
    </Form>
  )
}

export default ImageForm
