import { APP_NAME } from '@/constants'
import creatorsStoryPhoto from '@assets/images/creators_story_photo.png'

A decade ago, creator platforms emerged as a way for individuals to share their passion projects online. Blogs flourished. YouTube channels grew. Social media influencers rose to prominence. The creator economy entered a new era — content started to become a viable career path.

These platforms were initially designed for hobbyists and enthusiasts. Gradually, they evolved into ecosystems and became the lifeblood of an entire generation of digital entrepreneurs, a whopping 50M creators.

<img src={creatorsStoryPhoto} alt="1" />

However, simultaneously, creators watched algorithms change overnight. Marketers had to juggle multiple tools. Somehow, creators have lost control over their most valuable asset: their relationship with their audience.

We think it's time for a change. The creator economy must return to its rightful owners: the creators themselves.

Introducing, **{APP_NAME}**. An all-in-one platform for creators to grow, monetize, and manage their audience.

<h2 className="text-center font-heading text-2xl font-normal">
  ✦ FIVE ✦ REASONS ✦ TO LOVE ✦ {APP_NAME.toUpperCase()} ✦
</h2>

### Creator-Centric

Forget the old 'platform vs independence' dilemma — here's a better proposition: We build the software. We provide the infrastructure. But you own your audience and your content. You can customize the platform, integrate with your existing tools, or simply join a community that will empower you to take control of your digital presence.

### Easy Monetization

Say goodbye to cobbling together payment processors, link-in-bio tools, and digital marketing platforms. {APP_NAME} seamlessly integrates all the tools you need to turn your passion into profit, from one-time digital product sales to recurring memberships and exclusive experiences.

### Data Ownership

Your audience data is your most valuable asset. With {APP_NAME}, you're not just another creator on a platform — you're building your own digital empire. Own your data, understand your audience, and make informed decisions to grow your business.

### Flexible Content Delivery

Whether you're a podcaster, writer, video creator, or all of the above, {APP_NAME} adapts to your content style. Deliver your creations in any format, on any schedule, to any segment of your audience.

### Transparent Pricing

Free while in beta. Simple and aligned with your success, after we fully launch.

{/* TODO: Uncomment when we have pricing */}
{/* No more surprises or hidden fees eating into your hard-earned revenue. Our pricing is straightforward, scalable, and designed to grow with your success. */}

---

<div className="text-xl text-primary">
  Hundreds of creators have already built thriving businesses with {APP_NAME}.
  You also deserve to make a living doing what you love.
</div>

**Happy selling!**<br />
**Axel Grubba, Founder @ {APP_NAME}**
