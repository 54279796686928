import {
  Form,
  FormInput,
  FormSubmit,
  FormSwitch,
  FormTextarea,
} from '@/components/shared/inertia-form'
import { Button } from '@/components/ui/button'
import { SheetClose } from '@/components/ui/sheet'
import useTypedPage from '@/hooks/use-typed-page'
import routes from '@/routes'
import { InertiaSharedProps } from '@/types'
import Course from '@/types/generated/ContentItems/Course'
import { i18n } from '@/utils'

type CourseSettingsFormProps = InertiaSharedProps & {
  course: Course
  validation: {
    nameMaxLength: number
    taglineMaxLength: number
    descriptionMaxLength: number
  }
}

export default function CourseSettingsForm() {
  const { course, validation } = useTypedPage<CourseSettingsFormProps>().props

  const data = {
    course: {
      name: course.name || '',
      tagline: course.tagline || '',
      description: course.description || '',
      cover_image: course.coverImage || '',
      published: course.published ?? false,
    },
  }

  return (
    <>
      {/* TODO: Fix showing existing values in the form - not clear why it doesn't work */}
      <Form
        model="course"
        method="patch"
        to={routes.contentItemsCourses.update.path({
          prefix_id: course.prefixId,
        })}
        data={data}
      >
        <FormInput
          label={i18n.t('courses.edit.settings.name')}
          name="name"
          placeholder={i18n.t('courses.edit.settings.name')}
          maxCharCount={validation.nameMaxLength}
        />
        <FormInput
          label={i18n.t('courses.edit.settings.tagline')}
          name="tagline"
          placeholder={i18n.t('courses.edit.settings.tagline')}
          maxCharCount={validation.taglineMaxLength}
        />
        <FormTextarea
          label={i18n.t('courses.edit.settings.description')}
          name="description"
          placeholder={i18n.t('courses.edit.settings.description_placeholder')}
          maxCharCount={validation.descriptionMaxLength}
        />
        <FormInput
          label={i18n.t('courses.edit.settings.cover_image')}
          name="cover_image"
          type="file"
          accept="image/*"
        />
        <FormSwitch
          label={i18n.t('courses.edit.settings.published')}
          name="published"
        />
        <div className="flex justify-end gap-2">
          <SheetClose>
            <FormSubmit>{i18n.t('save')}</FormSubmit>
          </SheetClose>
          <SheetClose asChild>
            <Button variant="outline">{i18n.t('cancel')}</Button>
          </SheetClose>
        </div>
      </Form>
    </>
  )
}
