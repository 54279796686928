import useTypedPage from '@/hooks/use-typed-page'

export const useAuthorization = () => {
  const { currentUser, currentAccount } = useTypedPage().props

  return {
    isCurrentAccountOwner: currentUser?.id === currentAccount?.ownerId,
    // Add more authorization-related checks here
  }
}
