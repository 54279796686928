import MiniProfilerTracking from '@/components/shared/tracking/mini-profiler-tracking'
import ActionCableProvider from '@/contexts/action-cable-provider'
import { ThemeProvider } from '@/contexts/theme-provider'
import { PostHogProvider } from 'posthog-js/react'
import React, { FC, PropsWithChildren } from 'react'

const options = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
}

export type AppWrapperProps = PropsWithChildren

const AppWrapper: FC<AppWrapperProps> = ({ children }) => (
  <React.StrictMode>
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_API_KEY}
      options={options}
    >
      <ActionCableProvider>
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
          {children}
        </ThemeProvider>
      </ActionCableProvider>
    </PostHogProvider>
    <MiniProfilerTracking />
  </React.StrictMode>
)

export default AppWrapper
