import { FormError } from '@/components/shared/inertia-form/form-error'
import { Label } from '@/components/ui/label'
import {
  NestedObject,
  UseFormProps,
  useInertiaInput,
} from '@/lib/use-inertia-form'
import { cn } from '@/utils'
import React, { ReactNode, useEffect, useState } from 'react'

interface FormTextareaProps<TForm extends NestedObject = NestedObject>
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  label?: string
  name: string
  model?: string
  onChange?: (
    value: string,
    form: UseFormProps<TForm>,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void
  helperText?: string | ReactNode
  resizable?: boolean
  maxCharCount?: number
}

const FormTextarea = <TForm extends NestedObject = NestedObject>({
  label,
  helperText,
  name,
  required,
  onChange,
  id,
  model,
  className,
  resizable = false,
  maxCharCount,
  ...props
}: FormTextareaProps<TForm>) => {
  const { form, inputName, inputId, value, setValue, error } = useInertiaInput<
    string,
    TForm
  >({ name, model })

  const [charCount, setCharCount] = useState(value?.length || 0)

  useEffect(() => {
    setCharCount(value?.length || 0)
  }, [value])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value
    setValue(newValue)
    setCharCount(newValue.length)

    // Call the custom onChange handler if provided
    if (onChange) {
      onChange(newValue, form, e)
    }
  }

  const charCountText = maxCharCount
    ? `${charCount}/${maxCharCount}`
    : `${charCount} characters`

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <Label required={required} htmlFor={id || inputId}>
          {label}
        </Label>
      )}
      <textarea
        id={id || inputId}
        name={inputName}
        onChange={handleChange}
        value={value}
        required={required}
        maxLength={maxCharCount}
        className={cn(
          'flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          { 'border-destructive': error },
          { 'resize-none': !resizable },
          className
        )}
        {...props}
      />
      {(helperText || maxCharCount) && (
        <div className="flex justify-between text-xs text-muted-foreground">
          <div>{helperText && helperText}</div>
          <div>{maxCharCount && charCountText}</div>
        </div>
      )}
      <FormError name={label || name} error={error} />
    </div>
  )
}

export default FormTextarea
