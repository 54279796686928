// JsFromRoutes CacheKey 1e006a03ca2971b2392f1d0eca810a3a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/user/connected_accounts'),
  create: /* #__PURE__ */ definePathHelper('post', '/user/connected_accounts'),
  new: /* #__PURE__ */ definePathHelper('get', '/user/connected_accounts/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/user/connected_accounts/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/user/connected_accounts/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/user/connected_accounts/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/user/connected_accounts/:id'),
}
