import AppLoadingScreen from '@/components/shared/app/app-loading-screen'
import LayoutWrapper from '@/components/shared/layout-wrapper'
import Sidebar from '@/components/shared/sidebar'
import useIsCollapsed from '@/hooks/use-is-collapsed'
import React, { PropsWithChildren, useEffect, useState } from 'react'

export type AppLayoutProps = PropsWithChildren

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useIsCollapsed()

  // This is used to ensure the loading screen is visible for a minimum amount of time before the app is rendered the first time
  const [isAppReady, setIsAppReady] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAppReady(true)
    }, 1000) // Adjust this value as needed

    return () => clearTimeout(timer)
  }, [])

  if (!isAppReady) {
    return (
      <LayoutWrapper>
        <AppLoadingScreen />
      </LayoutWrapper>
    )
  }

  return (
    <LayoutWrapper>
      <div className="relative h-full overflow-hidden bg-accent antialiased md:p-4">
        <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        <main
          id="content"
          className={`overflow-x-hidden bg-background pt-16 shadow-sm transition-[margin] md:overflow-y-hidden md:rounded-2xl md:border md:pt-0 ${isCollapsed ? 'md:ml-14' : 'md:ml-64'} min-h-[calc(100vh-2rem)]`}
        >
          {children}
        </main>
      </div>
    </LayoutWrapper>
  )
}

export default AppLayout
