import { FaqList } from '@/components/shared/faq-list'
import Meta from '@/components/shared/meta'
import { Button, buttonVariants } from '@/components/ui/button'
import { Link } from '@/components/ui/link'
import { APP_NAME, SUPPORT_EMAIL } from '@/constants'
import routes from '@/routes'
import { cn } from '@/utils'
import { ArrowRight } from 'lucide-react'

const faqList = [
  {
    question: `What is the pricing for ${APP_NAME}?`,
    answer:
      'Our pricing is simple. We offer a free tier while in beta. After the beta, we will have a paid tier.',
  },
  {
    question: `I have more questions about ${APP_NAME}. How can I get in touch?`,
    answer: `Sure, you can contact us at ${SUPPORT_EMAIL}.`,
  },
]

export default function PricingPage() {
  const metaTitle = `Pricing | ${APP_NAME}`
  const metaDescription = `${APP_NAME}'s pricing`

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container">
        <div className="min-h-[950px]">
          <h1 className="mb-1 mt-20 text-center text-[90px] font-medium leading-none tracking-tighter md:text-[140px]">
            Free
          </h1>

          <h3 className="mb-1 text-center text-[90px] font-medium leading-none tracking-tighter text-muted-foreground md:text-[140px]">
            while in beta
          </h3>

          <div className="mt-8 text-center text-xl text-muted-foreground">
            Simple and aligned with your success, after we fully launch.
          </div>

          <div className="relative flex flex-col items-center text-center">
            <div className="mt-12">
              <div className="flex items-center space-x-4">
                <Link
                  href={routes.websiteStatic.talkToUs.path()}
                  className={cn(
                    buttonVariants({ variant: 'outline', size: 'lg' })
                  )}
                >
                  Talk to us
                </Link>

                <Link
                  target="_blank"
                  rel="noreferrer"
                  href={routes.usersRegistrations.new.path()}
                >
                  <Button size="lg">
                    Get Started
                    <ArrowRight className="ml-2 size-5 transition-transform group-hover/arrow:translate-x-1" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container -mt-[350px]">
          <FaqList faqList={faqList} />
        </div>
      </div>

      {/* <Testimonials /> */}
    </>
  )
}
