import { APP_DOMAIN, APP_NAME, ONBOARDING_STEPS } from '@/constants'
import { i18n } from '@/utils'

import { Form, FormInput, FormSubmit } from '@/components/shared/inertia-form'
import routes from '@/routes'

type SubdomainFormValues = {
  onboarding: {
    subdomain: string
    step: typeof ONBOARDING_STEPS.Subdomain
  }
}

interface SubdomainFormProps {
  subdomain?: string
}

const SubdomainForm = ({ subdomain }: SubdomainFormProps) => {
  const data: SubdomainFormValues = {
    onboarding: {
      subdomain: subdomain || '',
      step: ONBOARDING_STEPS.Subdomain,
    },
  }

  return (
    <div className="flex flex-col justify-center gap-4">
      <h2 className="text-2xl font-bold">
        {i18n.t('onboarding.subdomain_title')}
      </h2>
      <p className="text-sm text-gray-500">
        {i18n.t('onboarding.subdomain_description', { app: APP_NAME })}
      </p>

      <Form
        model="onboarding"
        method="patch"
        to={routes.onboarding.update.path()}
        data={data}
        remember={false}
      >
        <div className="flex w-full">
          <span className="inline-flex h-10 items-center rounded-l-md border border-r-0 border-border bg-accent px-3 text-sm text-neutral-500">
            {APP_DOMAIN}/
          </span>
          <FormInput
            name="subdomain"
            placeholder="my-profile"
            required
            className="rounded-l-none"
          />
        </div>

        <FormSubmit useAutoDisable={false} className="self-end">
          {i18n.t('continue')}
        </FormSubmit>
      </Form>
    </div>
  )
}

export default SubdomainForm
