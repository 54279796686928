import { useToast } from '@/hooks/use-toast'
import { InertiaSharedProps } from '@/types'
import { useEffect } from 'react'

function useToastAlert(flash: InertiaSharedProps['flash']): void {
  const { toast } = useToast()

  useEffect(() => {
    if (flash && flash.success) {
      toast({
        title: 'Success',
        description: flash.success,
        variant: 'success',
      })
    }

    if (flash && flash.notice) {
      toast({
        title: 'Notice',
        description: flash.notice,
        variant: 'notice',
      })
    }

    if (flash && flash.alert) {
      toast({
        title: 'Alert',
        description: flash.alert,
        variant: 'alert',
      })
    }

    if (flash && flash.error) {
      toast({
        title: 'Error',
        description: flash.error,
        variant: 'error',
      })
    }
  }, [flash, toast])
}

export default useToastAlert
