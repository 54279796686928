import { linkInBioBlockables } from '@/routes/generated'

import FaqQuestions from './faq-questions'
import { i18n } from '@/utils'

import { FaqBlock } from '@/types/blocks/blockable'

import { Button } from '@/components/ui/button'

import { PlusIcon } from 'lucide-react'
import {
  Form,
  FormInput,
  FormSubmit,
  FormSelect,
} from '@/components/shared/inertia-form'
import { FaqQuestion } from '@/types/blocks/faq-question'
import { FormError } from '@/components/shared/form-error'
import { useState } from 'react'
import { UseFormProps } from '@/lib/use-inertia-form'

const STYLES = [
  {
    value: 'regular',
    label: i18n.t('link_in_bio.blockables.forms.faq.styles.regular'),
  },
  {
    value: 'accordion',
    label: i18n.t('link_in_bio.blockables.forms.faq.styles.accordion'),
  },
]

type FaqFormData = {
  blockable: {
    id?: number
    type: string
    title: string
    headline: string
    description: string
    style: string
    questions: FaqQuestion[]
  }
}

type FaqFormProps = {
  editedBlock: FaqBlock
  setEditedBlock: (block: FaqBlock) => void
}

const FaqForm = ({ editedBlock, setEditedBlock }: FaqFormProps) => {
  const { blockable, blockableType } = editedBlock
  const { id, title, headline, description, style, questionsAttributes } =
    blockable

  const [errors, setErrors] = useState<
    Partial<Record<string, string | string[]>>
  >({})

  const [removedQuestions, setRemovedQuestions] = useState<FaqQuestion[]>([])

  const data: FaqFormData = {
    blockable: {
      id: id,
      type: blockableType,
      title,
      headline,
      description,
      style,
      questions: questionsAttributes,
    },
  }

  const handleAddQuestion = () => {
    const newBlock = { ...editedBlock }
    newBlock.blockable.questionsAttributes.push({
      question: '',
      answer: '',
    })

    setEditedBlock(newBlock)
  }

  const handleRemoveQuestion = (position: number) => {
    const newBlock = { ...editedBlock }
    const question = newBlock.blockable.questionsAttributes[position]

    if (question.id) {
      setRemovedQuestions((prev) => [...prev, { ...question, _destroy: true }])
    }

    newBlock.blockable.questionsAttributes.splice(position, 1)

    setEditedBlock(newBlock)
  }

  const handleChange = ({ data }: { data: FaqFormData }) => {
    const {
      blockable: { title, headline, description, style, questions },
    } = data

    setEditedBlock({
      ...editedBlock,
      blockable: {
        id,
        title,
        headline,
        description,
        style,
        questionsAttributes: questions,
      },
    })
  }

  const handleSubmit = ({ transform }: UseFormProps<FaqFormData>) => {
    transform((data) => ({
      blockable: {
        ...data.blockable,
        questions: [...data.blockable.questions, ...removedQuestions],
      },
    }))
  }

  return (
    <Form
      model="blockable"
      method={id ? 'put' : 'post'}
      to={
        id
          ? linkInBioBlockables.update.path({ id })
          : linkInBioBlockables.create.path()
      }
      data={data}
      onChange={handleChange}
      onError={({ errors }) => setErrors(errors)}
      onSubmit={handleSubmit}
    >
      <FormInput
        name="title"
        label={i18n.t('link_in_bio.blockables.forms.faq.title')}
        required
      />

      <FormInput
        name="headline"
        label={i18n.t('link_in_bio.blockables.forms.faq.headline')}
        required
      />

      <FormInput
        name="description"
        label={i18n.t('link_in_bio.blockables.forms.faq.description')}
        required
      />

      <FormSelect
        name="style"
        label={i18n.t('link_in_bio.blockables.forms.faq.style')}
        placeholder={i18n.t('forms.placeholders.select')}
        options={STYLES}
      />

      <div className="flex flex-col gap-2">
        <span className="text-sm font-medium leading-none">
          {i18n.t('link_in_bio.blockables.forms.faq.questions')}:
        </span>
        <FormError
          name={i18n.t(
            'link_in_bio.blockables.forms.faq.faq_question.question'
          )}
          error={errors['blockable.questions']}
        />
        <div className="flex flex-col gap-4">
          <FaqQuestions onRemove={handleRemoveQuestion} />
        </div>
      </div>

      <Button
        type="button"
        variant="outline"
        className="flex w-fit"
        onClick={handleAddQuestion}
      >
        <PlusIcon className="mr-2 size-4" />
        {i18n.t('link_in_bio.blockables.forms.faq.add_question')}
      </Button>

      <FormSubmit>{i18n.t('save')}</FormSubmit>
    </Form>
  )
}

export default FaqForm
