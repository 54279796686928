// JsFromRoutes CacheKey bdf418a9d24213c7a18c0d5e753c98c1
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/course/enrollments'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/course/enrollments'),
  new: /* #__PURE__ */ definePathHelper('get', '/admin/course/enrollments/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/course/enrollments/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/course/enrollments/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/course/enrollments/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/course/enrollments/:id'),
}
