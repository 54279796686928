import NewPasswordForm from '@/components/features/settings/password/new-password-form'
import Meta from '@/components/shared/meta'
import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { ContentSection } from '@/layouts/app/sections/content-section'
import { SettingsSection } from '@/layouts/app/sections/settings-section'
import routes from '@/routes'
import { settingsProfile } from '@/routes/generated'
import { User } from '@/types'
import { i18n } from '@/utils'

interface SettingsPasswordPageProps {
  currentUser: User
}

const breadcrumbs = [
  {
    title: i18n.t('application.breadcrumbs.home'),
    link: routes.dashboard.show.path(),
  },
  { title: 'Settings', link: settingsProfile.index.path() },
  { title: 'Password' },
]

export default function SettingsPagePassword({
  currentUser,
}: SettingsPasswordPageProps) {
  if (!currentUser) return null

  return (
    <>
      <Meta title="Password Settings" />
      <ContentSection>
        <ContentSection.Header>
          <DefaultNavbar breadcrumbs={breadcrumbs} />
        </ContentSection.Header>
        <ContentSection.Body>
          <SettingsSection
            title="Change Password"
            desc="Change your password here."
          >
            <NewPasswordForm />
          </SettingsSection>
        </ContentSection.Body>
      </ContentSection>
    </>
  )
}
