import DividerBlockable from '@/types/blocks/divider'

const borderClasses: { [key: string]: string } = {
  thin: 'border-solid border-t',
  thick: 'border-solid border-t-2',
  dashed: 'border-dashed border-t',
  ellipsis: 'border-dotted border-t',
  invisible: 'border-none',
}

export default function DividerPreview(props: DividerBlockable) {
  const { verticalSpace, borderType } = props

  return (
    <div className="flex items-center" style={{ height: `${verticalSpace}px` }}>
      <div
        className={`w-full border border-border ${borderClasses[borderType]}`}
      ></div>
    </div>
  )
}
