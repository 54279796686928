import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { buttonVariants } from '@/components/ui/button'
import { CourseLesson } from '@/types'
import { cn, i18n } from '@/utils'
import { useEffect, useState } from 'react'

interface LessonDeleteDialogProps {
  lesson: CourseLesson
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}

export default function LessonDeleteDialog({
  isOpen,
  onClose,
}: LessonDeleteDialogProps) {
  const [isLessonDeleteDialogOpen, setIsLessonDeleteDialogOpen] =
    useState(false)

  useEffect(() => {
    setIsLessonDeleteDialogOpen(isOpen)
  }, [isOpen])

  // TODO: uncomment this function
  // const handleLessonDelete = () => {
  //   router.delete(
  //     routes.coursesLessons.destroy.path({
  //       prefix_id: lesson.prefixId,
  //     }),
  //     {
  //       preserveState: true,
  //       preserveScroll: true,
  //       onSuccess: () => {
  //         onDelete()
  //         onClose()
  //       },
  //     }
  //   )
  // }

  const handleClose = () => {
    setIsLessonDeleteDialogOpen(false)
    onClose()
  }

  return (
    <AlertDialog open={isLessonDeleteDialogOpen} onOpenChange={handleClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {i18n.t('course_lessons.destroy.dialog.title')}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {i18n.t('course_lessons.destroy.dialog.description')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{i18n.t('cancel')}</AlertDialogCancel>
          <AlertDialogAction
            className={cn(buttonVariants({ variant: 'destructive' }))}
            // onClick={handleLessonDelete} TODO: uncomment this line
          >
            {i18n.t('delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
