import { Form, FormSwitch } from '@/components/shared/inertia-form'
import { useInertiaForm } from '@/lib/use-inertia-form'
import routes from '@/routes'
import Course from '@/types/generated/ContentItems/Course'
import { i18n } from '@/utils'

export default function CoursePublishedToggleForm({
  course,
}: {
  course: Course
}) {
  const form = useInertiaForm({
    course: {
      published: course.published,
    },
  })

  const handleToggle = (checked: boolean) => {
    form.setData('course.published', checked)
    form.patch(
      routes.contentItemsCourses.update.path({
        prefix_id: course.prefixId,
      })
    )
  }

  return (
    <Form
      model="course"
      method="patch"
      to={routes.contentItemsCourses.update.path({
        prefix_id: course.prefixId,
      })}
      data={form.data}
    >
      <FormSwitch
        label={i18n.t('courses.edit.settings.published')}
        name="published"
        onChange={handleToggle}
      />
    </Form>
  )
}
