import { buttonVariants } from '@/components/ui/button'
import { Link } from '@/components/ui/link'
import routes from '@/routes'
import { cn } from '@/utils'
import { DollarSign } from 'lucide-react'

export function FooterCTA() {
  // const marqueeText = `DON'T MISS OUT! CREATOR ECONOMY IS BOOMING. CREATE, GROW, MONETIZE. JOIN THE MOVEMENT.`

  return (
    <>
      <div id="footer-cta" className="container pt-16">
        <div className="rounded-3xl border-2 border-primary bg-primary px-10 py-16">
          <div className="flex flex-col items-center text-center">
            <h2 className="mb-4 font-heading text-4xl text-background">
              Turn Your Passion into Profit
            </h2>
            <p className="mb-8 max-w-2xl text-xl text-background/60">
              Join thousands of creators who are monetizing their skills and
              content.
            </p>
            <Link
              href={routes.usersRegistrations.new.path()}
              className={cn(
                buttonVariants({ variant: 'secondary', size: 'lg' }),
                'text-lg font-semibold'
              )}
            >
              <DollarSign className="mr-2 h-6 w-6" />
              Start Earning Now
            </Link>
          </div>

          {/* TODO: Think if we want to use this marquee */}

          {/* <div className="relative mt-10 flex overflow-x-hidden text-background">
            <div className="animate-marquee whitespace-nowrap text-3xl font-semibold">
              {marqueeText}
            </div>
            <div className="absolute top-0 animate-marquee2 whitespace-nowrap pl-2 text-3xl font-semibold">
              {marqueeText}
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}
