import MailingListSignupForm from '@/components/features/website/forms/mailing-list-signup-form'
import { Badge } from '@/components/ui/badge'

export default function HomePage() {
  return (
    <>
      {/* TODO: Swap this for actual homepage content */}
      <div className="container flex min-h-[500px] flex-col items-center justify-center lg:min-h-[600px]">
        <Badge variant="outline" className="mb-4 rounded-xl px-4 py-1 text-sm">
          Beta
        </Badge>
        <h1 className="mb-6 text-center font-heading text-4xl leading-tight sm:text-6xl md:text-7xl lg:text-8xl">
          Empowering Creators
        </h1>
        <h2 className="max-w-[600px] text-center text-xl font-medium text-muted-foreground sm:text-2xl md:text-3xl">
          All-in-One Platform for Growing Your Audience and Monetizing Your
          Content
        </h2>
        <MailingListSignupForm />
      </div>
      {/* <HeroSection />
      <BenefitsSection />
      <FeaturesSection />
      <ServicesSection />
      <BentoSection />
      <TestimonialSection />
      <PricingSection />
      <FAQSection /> */}
    </>
  )
}
