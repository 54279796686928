import DeletePricingOptionDialog from '@/components/features/products/delete-pricing-option'
import { Badge } from '@/components/ui/badge'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import PricingOption from '@/types/generated/PricingOption'
import { i18n } from '@/utils'

type PricingOptionsTableProps = {
  pricingOptions: PricingOption[]
}

export default function PricingOptionsTable({
  pricingOptions,
}: PricingOptionsTableProps) {
  return (
    <Table>
      <TableHeader className="bg-background">
        <TableRow>
          <TableHead>
            {i18n.t('products.pricing_options_table.headers.price')}
          </TableHead>
          <TableHead>
            {i18n.t('products.pricing_options_table.headers.active_users')}
          </TableHead>
          <TableHead>
            {i18n.t('products.pricing_options_table.headers.all_time_revenue')}
          </TableHead>
          <TableHead>
            {i18n.t('products.pricing_options_table.headers.visibility')}
          </TableHead>
          <TableHead>
            {i18n.t('products.pricing_options_table.headers.release_method')}
          </TableHead>
          <TableHead>
            {i18n.t('products.pricing_options_table.headers.stock')}
          </TableHead>
          <TableHead className="text-right">
            {i18n.t('products.pricing_options_table.headers.actions')}
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {pricingOptions.map((pricingOption) => (
          <TableRow key={pricingOption.id}>
            <TableCell>
              {pricingOption.type === 'free'
                ? 'Free'
                : `${pricingOption.price} ${pricingOption.currency}`}
            </TableCell>
            <TableCell>0 (TODO)</TableCell>
            <TableCell>0 (TODO)</TableCell>
            <TableCell>
              <Badge variant="default">
                {i18n.t('products.pricing_options_table.headers.visible')}
              </Badge>
              {/* <Badge variant="outline">
                {i18n.t('products.pricing_options_table.headers.hidden')}
              </Badge> */}
            </TableCell>
            <TableCell>Buy Now (TODO)</TableCell>
            <TableCell>Unlimited (TODO)</TableCell>
            <TableCell className="text-right">
              <DeletePricingOptionDialog
                pricingOptionName={pricingOption.name}
                pricingOptionId={pricingOption.id}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
