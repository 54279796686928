export const toSnakeCase = (s: string) =>
  s
    .split(/(?=[A-Z])/)
    .map((s) => s.toLowerCase())
    .join('_')

export const deepTransform = (
  o: object,
  transformation: (s: string) => string
): Record<string, any> =>
  Object.fromEntries(
    Object.entries(o).map((entry) => {
      const key = transformation(entry[0])
      const value = entry[1]

      const isPresent = value !== null && value !== undefined

      const isObject =
        typeof value == 'object' && !Array.isArray(value) && isPresent

      const isArray =
        typeof value == 'object' && Array.isArray(value) && isPresent

      if (isObject) {
        return [transformation(key), deepTransform(value, transformation)]
      } else if (isArray) {
        return [
          transformation(key),
          value.map((v) => deepTransform(v, transformation)),
        ]
      } else {
        return [transformation(key), value]
      }
    })
  )
