import { environment, getMeta, isProduction } from '@/utils'
import type { BrowserOptions } from '@sentry/react'
import {
  captureConsoleIntegration,
  contextLinesIntegration,
  httpClientIntegration,
  init,
  replayCanvasIntegration,
  replayIntegration,
} from '@sentry/react'

export const setupSentry = () => {
  const dsn = import.meta.env.VITE_SENTRY_DSN
  const tracesSampleRate =
    import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || undefined
  const replaysSessionSampleRate =
    import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || undefined
  const replaysOnErrorSampleRate =
    import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || undefined

  if (isProduction() && dsn) {
    const options: BrowserOptions = {
      dsn,
      environment: environment(),
      tracesSampleRate,
      sendDefaultPii: true,
      replaysSessionSampleRate,
      replaysOnErrorSampleRate,
      integrations: [
        contextLinesIntegration(),
        captureConsoleIntegration({ levels: ['error', 'assert'] }),
        replayIntegration(),
        replayCanvasIntegration(),
        httpClientIntegration(),
      ],
      ignoreErrors: [],
    }
    init(options)
  } else if (getMeta('env') === 'development') {
    console.info('Sentry initialization skipped in development environment')
  } else {
    console.warn('Missing Sentry DSN; skipping initialization')
  }
}
