import {
  Form,
  FormInput,
  FormSelect,
  FormSubmit,
} from '@/components/shared/inertia-form'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import useTypedPage from '@/hooks/use-typed-page'
import routes from '@/routes'
import { i18n } from '@/utils'
import { PlusIcon, TrashIcon } from 'lucide-react'
import React, { useState } from 'react'

interface SocialLinksDialogProps {
  isOpen: boolean
  onClose: () => void
}

interface SocialLinkData {
  platform: string
  url: string
}

const platformOptions = [
  { value: 'instagram', label: 'Instagram' },
  { value: 'tiktok', label: 'TikTok' },
  { value: 'twitter', label: 'Twitter' },
  { value: 'discord', label: 'Discord' },
  { value: 'youtube', label: 'YouTube' },
  { value: 'linkedin', label: 'LinkedIn' },
  { value: 'telegram', label: 'Telegram' },
  { value: 'twitch', label: 'Twitch' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'pinterest', label: 'Pinterest' },
  { value: 'email', label: 'Email' },
  { value: 'url', label: 'Website' },
]

const SocialLinksDialog: React.FC<SocialLinksDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const { currentAccount } = useTypedPage().props

  const [socialLinks, setSocialLinks] = useState<SocialLinkData[]>([
    { platform: '', url: '' },
  ])

  const addSocialLink = () => {
    setSocialLinks([...socialLinks, { platform: '', url: '' }])
  }

  const removeSocialLink = (index: number) => {
    const newLinks = socialLinks.filter((_, i) => i !== index)
    setSocialLinks(newLinks)
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent showCloseButton={false} className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>Add Social Links</DialogTitle>
        </DialogHeader>
        <Form
          async={true}
          model="social_link"
          to={routes.socialLinks.create.path({
            account_id: currentAccount?.id,
          })}
        >
          {socialLinks.map((_link, index) => (
            <div key={index} className="mb-1 grid grid-cols-10 gap-3">
              <div className="col-span-3">
                <FormSelect
                  name={`social_link[${index}].platform`}
                  options={platformOptions}
                  placeholder="Select platform"
                />
              </div>
              <div className="col-span-6">
                <FormInput
                  name={`social_link[${index}].url`}
                  placeholder="Enter URL"
                />
              </div>
              <div className="col-span-1">
                <Button
                  className="text-muted-foreground"
                  type="button"
                  variant="link"
                  size="icon"
                  onClick={() => removeSocialLink(index)}
                >
                  <TrashIcon className="h-4 w-4" />
                </Button>
              </div>
            </div>
          ))}
          <Button
            type="button"
            variant="outline"
            onClick={addSocialLink}
            className="mb-4"
          >
            <PlusIcon className="mr-2 h-4 w-4" /> Add Another Link
          </Button>
          <div className="flex items-center justify-end gap-2">
            <DialogClose asChild>
              <Button variant="outline">{i18n.t('cancel')}</Button>
            </DialogClose>
            <FormSubmit>{i18n.t('save')}</FormSubmit>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default SocialLinksDialog
