import Meta from '@/components/shared/meta'
import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { NewAccountDialog } from '@/components/shared/new-account-dialog'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button, buttonVariants } from '@/components/ui/button'
import { APP_NAME } from '@/constants'
import useTypedPage from '@/hooks/use-typed-page'
import { ContentSection } from '@/layouts/app/sections/content-section'
import { SettingsSection } from '@/layouts/app/sections/settings-section'
import routes from '@/routes'
import { Account } from '@/types'
import { cn, i18n, switchAccount } from '@/utils'
import React from 'react'

interface Props {
  accounts: Account[]
}

export default function AccountsIndex({ accounts }: Props) {
  const { railsContext } = useTypedPage().props
  const [showNewAccountDialog, setShowNewAccountDialog] = React.useState(false)

  const breadcrumbs = [
    {
      title: i18n.t('application.breadcrumbs.home'),
      link: routes.dashboard.show.path(),
    },
    { title: 'Settings', link: routes.settingsProfile.index.path() },
    { title: 'Accounts' },
  ]

  return (
    <>
      <Meta title="Accounts Settings" />
      <ContentSection>
        <ContentSection.Header>
          <DefaultNavbar breadcrumbs={breadcrumbs} />
        </ContentSection.Header>
        <ContentSection.Body>
          <SettingsSection
            title="Accounts"
            desc={`Manage your ${APP_NAME} accounts.`}
            action={
              <Button
                onClick={() => {
                  setShowNewAccountDialog(true)
                }}
              >
                Create Account
              </Button>
            }
          >
            <table className="w-full">
              <tbody>
                {accounts.map((account, index) => (
                  <tr
                    key={account.id}
                    className={cn('group border-border', {
                      'border-t': index !== 0,
                    })}
                  >
                    <td className="py-3">
                      <div className="flex items-center gap-2">
                        <Avatar className="h-8 w-8 border border-border">
                          <AvatarImage
                            src={account.avatar?.src}
                            alt={account.name}
                          />
                          <AvatarFallback>
                            {account.name ? account.name.charAt(0) : ''}
                          </AvatarFallback>
                        </Avatar>
                        <span className="hover:text-primary-500 text-sm font-semibold text-gray-900 no-underline">
                          {account.name}
                        </span>
                      </div>
                    </td>
                    <td className="hidden text-right text-sm sm:table-cell">
                      {!account.isCurrent ? (
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={() =>
                            switchAccount(account, railsContext.path)
                          }
                        >
                          Switch to
                        </Button>
                      ) : (
                        <div
                          className={cn(
                            buttonVariants({ variant: 'outline', size: 'sm' })
                          )}
                        >
                          Current
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </SettingsSection>
        </ContentSection.Body>
      </ContentSection>
      <NewAccountDialog
        open={showNewAccountDialog}
        onOpenChange={setShowNewAccountDialog}
      />
    </>
  )
}
