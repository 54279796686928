import { AppLogotype } from '@/components/shared/app/app-logo'
import LayoutWrapper from '@/components/shared/layout-wrapper'
import { Card } from '@/components/ui/card'
import routes from '@/routes'
import { cn } from '@/utils'
import React from 'react'

export interface AuthLayoutProps {
  children: React.ReactNode
  width?: 'default' | 'medium' | 'wide'
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  width = 'default',
}) => {
  const widthClasses = {
    default: 'sm:w-[480px]',
    medium: 'lg:w-[640px]',
    wide: 'lg:w-[800px]',
  }

  return (
    <>
      <LayoutWrapper>
        <main className="container grid min-h-screen flex-col items-center bg-accent lg:max-w-none lg:px-0">
          <div
            className={cn(
              'mx-auto flex w-full flex-col justify-center space-y-2 lg:p-8',
              widthClasses[width]
            )}
          >
            <div className="mb-4 flex items-center justify-center">
              <AppLogotype size="lg" url={routes.websiteStatic.index.path()} />
            </div>
            <Card className="p-6">{children}</Card>
          </div>
        </main>
      </LayoutWrapper>
    </>
  )
}

export default AuthLayout
