import ContentNicheForm from '@/components/features/onboarding/forms/content-niche-form'
import ReferralSourceForm from '@/components/features/onboarding/forms/referral-source-form'
import SubdomainForm from '@/components/features/onboarding/forms/subdomain-form'
import OnboardingSidebar from '@/components/features/onboarding/onboarding-sidebar'
import { ONBOARDING_STEPS } from '@/constants'
import { useAuthorization } from '@/hooks/use-authorization'
import FullScreenLayout from '@/layouts/full-screen-layout'
import { ReactNode } from 'react'

type OnboardingFormValues = {
  subdomain?: string
  contentNiche?: string
  otherContentNiche?: string
  referralSource?: string
}
interface OnboardingPageProps {
  currentStep: string
  onboardingSteps: string[]
  contentNiches: string[]
  onboarding: OnboardingFormValues
}
const OnboardingPage = ({
  currentStep,
  onboardingSteps,
  contentNiches,
  onboarding,
}: OnboardingPageProps) => {
  const { isCurrentAccountOwner } = useAuthorization()

  return (
    <div className="flex h-screen w-full items-center">
      <OnboardingSidebar
        currentStep={currentStep}
        onboardingSteps={onboardingSteps}
        showOnboardingSteps={isCurrentAccountOwner}
      />
      <div className="flex flex-col items-center px-12 py-6 md:p-8">
        {currentStep === ONBOARDING_STEPS.Subdomain && (
          <SubdomainForm subdomain={onboarding.subdomain} />
        )}
        {currentStep === ONBOARDING_STEPS.ContentNiche && (
          <ContentNicheForm
            contentNiche={onboarding.contentNiche}
            otherContentNiche={onboarding.otherContentNiche}
            contentNiches={contentNiches}
          />
        )}
        {currentStep === ONBOARDING_STEPS.ReferralSource && (
          <ReferralSourceForm
            referralSource={onboarding.referralSource}
            withBackButton={isCurrentAccountOwner}
          />
        )}
      </div>
    </div>
  )
}

OnboardingPage.layout = (page: ReactNode) => (
  <FullScreenLayout>{page}</FullScreenLayout>
)

export default OnboardingPage
