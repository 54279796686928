import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { buttonVariants } from '@/components/ui/button'
import routes from '@/routes'
import { CourseChapter } from '@/types'
import { cn, i18n } from '@/utils'
import { router } from '@inertiajs/react'
import { useEffect, useState } from 'react'

interface ChapterDeleteDialogProps {
  chapter: CourseChapter
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}

export default function ChapterDeleteDialog({
  chapter,
  isOpen,
  onClose,
  onDelete,
}: ChapterDeleteDialogProps) {
  const [isChapterDeleteDialogOpen, setIsChapterDeleteDialogOpen] =
    useState(false)

  useEffect(() => {
    setIsChapterDeleteDialogOpen(isOpen)
  }, [isOpen])

  const handleChapterDelete = () => {
    router.delete(
      routes.contentItemsChapters.destroy.path({
        id: chapter.id,
      }),
      {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
          onDelete()
          onClose()
        },
      }
    )
  }

  const handleClose = () => {
    setIsChapterDeleteDialogOpen(false)
    onClose()
  }

  return (
    <AlertDialog open={isChapterDeleteDialogOpen} onOpenChange={handleClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {i18n.t('course_chapters.destroy.dialog.title')}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {i18n.t('course_chapters.destroy.dialog.description')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{i18n.t('cancel')}</AlertDialogCancel>
          <AlertDialogAction
            className={cn(buttonVariants({ variant: 'destructive' }))}
            onClick={handleChapterDelete}
          >
            {i18n.t('delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
