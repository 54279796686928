import { capitalize } from 'lodash'
import React from 'react'

interface FormErrorProps {
  name?: string
  error?: string | string[]
}

export const FormError = ({ name, error }: FormErrorProps): React.ReactNode => {
  if (!error) return null

  const errorMessage = Array.isArray(error) ? error.join(', ') : error

  return (
    <p className="text-xs text-red-500">
      {name ? `${capitalize(name)} ${errorMessage}` : errorMessage}
    </p>
  )
}
