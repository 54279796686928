import EmailConfirmationBanner from '@/components/shared/email-confirmation-banner'
import { cn } from '@/utils'
import * as React from 'react'

const ContentSectionContext = React.createContext<{
  offset: number
  fixed: boolean
} | null>(null)

interface ContentSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  fixed?: boolean
}

const ContentSection = ({
  className,
  fixed = false,
  ...props
}: ContentSectionProps) => {
  const divRef = React.useRef<HTMLDivElement>(null)
  const [offset, setOffset] = React.useState(0)

  React.useEffect(() => {
    const div = divRef.current

    if (!div) return
    const onScroll = () => setOffset(div.scrollTop)

    // clean up code
    div.removeEventListener('scroll', onScroll)
    div.addEventListener('scroll', onScroll, { passive: true })
    return () => div.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <ContentSectionContext.Provider value={{ offset, fixed }}>
      <div
        ref={divRef}
        data-layout="layout"
        className={cn(
          'h-full overflow-auto',
          fixed && 'flex flex-col',
          className
        )}
        {...props}
      />
    </ContentSectionContext.Provider>
  )
}
ContentSection.displayName = 'ContentSection'

interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  sticky?: boolean
}

const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
  ({ className, sticky, ...props }, ref) => {
    // Check if Layout.Header is used within Layout
    const contextVal = React.useContext(ContentSectionContext)
    if (contextVal === null) {
      throw new Error(
        `ContentSection.Header must be used within ${ContentSection.displayName}.`
      )
    }

    return (
      <>
        <div
          ref={ref}
          data-layout="header"
          className={cn(
            `z-10 flex h-[var(--header-height)] items-center gap-4 border-b py-2 pl-4 md:pl-8 md:pr-4`,
            contextVal.offset > 10 && sticky ? 'shadow' : 'shadow-none',
            contextVal.fixed && 'flex-none',
            sticky && 'sticky top-0',
            className
          )}
          {...props}
        />
        <EmailConfirmationBanner />
      </>
    )
  }
)
Header.displayName = 'Header'

const Body = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  // Check if Layout.Body is used within Layout
  const contextVal = React.useContext(ContentSectionContext)
  if (contextVal === null) {
    throw new Error(
      `ContentSection.Body must be used within ${ContentSection.displayName}.`
    )
  }

  return (
    <div
      ref={ref}
      data-layout="body"
      className={cn(
        'px-4 py-6 md:overflow-hidden md:px-8',
        contextVal && contextVal.fixed && 'flex-1',
        className
      )}
      {...props}
    />
  )
})
Body.displayName = 'Body'

ContentSection.Header = Header
ContentSection.Body = Body

export { ContentSection }
