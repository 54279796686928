import CookieBanner from '@/components/features/website/cookie-banner'
import { Footer } from '@/components/features/website/footer'
import { FooterCTA } from '@/components/features/website/footer-cta'
import { Navbar } from '@/components/features/website/navbar'
import SalesPopup from '@/components/features/website/sales-popup'
import LayoutWrapper from '@/components/shared/layout-wrapper'
import useTypedPage from '@/hooks/use-typed-page'
import routes from '@/routes'
import React from 'react'

const WebsiteLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <LayoutWrapper>
        <Navbar />
        <main className="mx-auto max-w-7xl pb-12 pt-24">
          {children}
          {useTypedPage().url !== routes.websiteStatic.affiliates.path() && (
            <FooterCTA />
          )}
        </main>
        <Footer />
        <CookieBanner />
        <SalesPopup />
      </LayoutWrapper>
    </>
  )
}

export default WebsiteLayout
