import { Button } from '@/components/ui/button'
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import routes from '@/routes'
import { i18n } from '@/utils'
import { router } from '@inertiajs/react'
import { useState } from 'react'

export default function NewCourseDialog() {
  const [courseData, setCourseData] = useState({
    type: 'Items::Course',
    name: '',
    tagline: '',
    description: '',
  })

  const submit = () => {
    router.post(routes.contentItems.create.path(), courseData, {
      preserveState: false,
    })
  }

  return (
    <div>
      <DialogContent className="sm:max-w-[425px]" showCloseButton={false}>
        <DialogHeader>
          <DialogTitle className="text-center">
            {i18n.t('content_items.index.new_course_dialog.title')}
          </DialogTitle>
          <DialogDescription className="text-center">
            {i18n.t('content_items.index.new_course_dialog.description')}
          </DialogDescription>
        </DialogHeader>

        <div>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="course-name" className="col-span-1 text-right">
                {i18n.t('content_items.index.new_course_dialog.labels.name')}
              </Label>
              <Input
                type="text"
                id="course-name"
                className="col-span-3"
                value={courseData.name}
                onChange={(e) =>
                  setCourseData({ ...courseData, name: e.target.value })
                }
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="course-tagline" className="col-span-1 text-right">
                {i18n.t('content_items.index.new_course_dialog.labels.tagline')}
              </Label>
              <Input
                type="text"
                id="course-tagline"
                className="col-span-3"
                value={courseData.tagline}
                onChange={(e) =>
                  setCourseData({ ...courseData, tagline: e.target.value })
                }
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label
                htmlFor="course-description"
                className="col-span-1 text-right"
              >
                {i18n.t(
                  'content_items.index.new_course_dialog.labels.description'
                )}
              </Label>
              <Textarea
                id="course-description"
                className="col-span-3"
                value={courseData.description}
                onChange={(e) =>
                  setCourseData({ ...courseData, description: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <DialogFooter>
          <DialogClose>
            <Button variant="outline">{i18n.t('back')}</Button>
          </DialogClose>
          <Button type="button" onClick={submit}>
            {i18n.t('add')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </div>
  )
}
