import { buttonVariants } from '@/components/ui/button'
import { Link } from '@/components/ui/link'
import routes from '@/routes'
import { cn } from '@/utils'
import { ArrowRight } from 'lucide-react'

export default function ContinueOnboardingBanner() {
  return (
    <div className="mt-4 flex justify-center">
      <Link
        href={routes.dashboard.show.path()}
        className={cn(buttonVariants({ variant: 'ghost' }))}
      >
        Continue Onboarding
        <ArrowRight className="ml-2 h-4 w-4" />
      </Link>
    </div>
  )
}
