import ChapterDeleteDialog from '@/components/features/content/course/dialogs/chapter-delete-dialog'
import LessonDeleteDialog from '@/components/features/content/course/dialogs/lesson-delete-dialog'
import { CourseSettingsSheet } from '@/components/features/content/course/edit/course-settings-sheet'
import LessonActionsMenu from '@/components/features/content/course/edit/lesson-actions-menu'
import CoursePublishedToggleForm from '@/components/features/content/course/forms/course-published-toggle-form'
import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { Badge } from '@/components/ui/badge'
import { Button, buttonVariants } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Link } from '@/components/ui/link'
import { ContentSection } from '@/layouts/app/sections/content-section'
import routes from '@/routes'
import { CourseChapter, CourseLesson } from '@/types'
import Course from '@/types/generated/ContentItems/Course'
import { cn, i18n } from '@/utils'
import {
  BookOpenText,
  Drum,
  ExternalLink,
  Eye,
  GripVerticalIcon,
  Plus,
  Trash,
  Upload,
} from 'lucide-react'
import { useState } from 'react'

interface CourseEditPageProps {
  course: Course
}

export default function CourseEditPage({ course }: CourseEditPageProps) {
  const breadcrumbs = [
    {
      title: i18n.t('application.breadcrumbs.home'),
      link: routes.dashboard.show.path(),
    },
    {
      title: i18n.t('content_items.breadcrumbs.title'),
      link: routes.contentItems.index.path(),
    },
    {
      title: i18n.t('courses.breadcrumbs.title'),
      link: routes.contentItems.index.path(),
    },
    {
      title: course.name,
    },
  ]

  const [isLessonDeleteDialogOpen, setIsLessonDeleteDialogOpen] =
    useState(false)
  const [isChapterDeleteDialogOpen, setIsChapterDeleteDialogOpen] =
    useState(false)
  const [selectedChapter, setSelectedChapter] = useState<CourseChapter | null>(
    null
  )
  const [selectedLesson, setSelectedLesson] = useState<CourseLesson | null>(
    null
  )

  return (
    <ContentSection>
      <ContentSection.Header>
        <DefaultNavbar breadcrumbs={breadcrumbs} />
      </ContentSection.Header>
      <ContentSection.Body>
        <div className="mb-4 flex justify-between gap-2">
          <div className="flex items-center gap-3">
            <BookOpenText className="h-6 w-6 text-muted-foreground" />
            <h1 className="text-lg font-semibold sm:text-xl">{course.name}</h1>
            <div>
              <Badge variant={course.published ? 'default' : 'secondary'}>
                {course.published ? 'Published' : 'Draft'}
              </Badge>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Link
              href={'link_to_course'}
              className={cn(buttonVariants({ variant: 'link', size: 'sm' }))}
            >
              <ExternalLink className="mr-2 h-4 w-4" />
              Preview
            </Link>
            <CoursePublishedToggleForm course={course} />
            <Button variant="outline" size="sm">
              <Eye className="mr-2 h-4 w-4" />
              Draft
            </Button>
            <CourseSettingsSheet />
          </div>
        </div>

        <div className="flex flex-col gap-4 sm:flex-row">
          <Card className="w-full sm:w-[300px]">
            <CardHeader>
              <CardTitle className="flex items-center justify-between">
                <span>Chapters</span>
                <Button variant="outline" size="sm">
                  <Plus className="mr-1 h-4 w-4" />
                  New chapter
                </Button>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <Accordion
                type="single"
                collapsible
                className="w-full"
                defaultValue="item-1"
              >
                {course.chapters.map((chapter, index) => (
                  <AccordionItem key={chapter.id} value={`item-${index + 1}`}>
                    <AccordionTrigger className="flex items-center justify-between rounded-md bg-muted-foreground/10 p-2">
                      <div className="flex items-center gap-2">
                        <Button variant="ghost" size="icon">
                          <GripVerticalIcon className="h-4 w-4" />
                        </Button>
                        <Input
                          value={chapter.title}
                          onChange={() => {
                            // TODO: Implement onChange handler to update chapter title
                          }}
                          className="w-full"
                        />
                      </div>
                      <div className="flex items-center gap-2">
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => {
                            setSelectedChapter(chapter)
                            setIsChapterDeleteDialogOpen(true)
                          }}
                        >
                          <Trash className="h-4 w-4" />
                        </Button>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="space-y-1 pl-6">
                        {chapter.lessons.map((lesson) => (
                          <div
                            key={lesson.id}
                            className="group flex items-center rounded-md bg-muted-foreground/5 p-2"
                          >
                            <GripVerticalIcon className="h-4 w-4" />
                            <Drum className="mr-2 h-4 w-4" />
                            <Input
                              value={lesson.title}
                              onChange={() => {
                                // TODO: Implement onChange handler to update lesson title
                              }}
                              className="w-full"
                            />
                            <LessonActionsMenu
                              lesson={lesson}
                              setIsLessonDeleteDialogOpen={() => {
                                setSelectedLesson(lesson)
                                setIsLessonDeleteDialogOpen(true)
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      <Button variant="outline" className="mt-2 w-full">
                        <Plus className="mr-1 h-4 w-4" />
                        New lesson
                      </Button>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
              <Button variant="outline" className="mt-2 w-full">
                <Plus className="mr-1 h-4 w-4" />
                New chapter
              </Button>
            </CardContent>
          </Card>
          <div className="flex-1">
            <Card>
              <CardHeader>
                <div className="flex items-start justify-between">
                  <div className="flex flex-col gap-2">
                    <div className="text-sm text-muted-foreground">
                      {course.chapters[0].title}
                    </div>
                    <CardTitle className="flex items-center justify-between">
                      {course.chapters[0].lessons[0].title}
                    </CardTitle>
                  </div>
                  <Badge variant="secondary">Draft</Badge>
                </div>
              </CardHeader>
              <CardContent>
                <div className="flex flex-col items-center justify-center rounded-md border bg-muted-foreground/10 p-8 text-center">
                  <Upload className="mb-4 h-12 w-12" />
                  <h3 className="text-lg font-medium">Upload a video...</h3>
                  <p className="mb-4 text-sm">
                    Please use .mp4, .mov, .mpeg, or .webm.
                  </p>
                  <Button variant="outline">Upload file</Button>
                </div>
                <div className="mt-4 flex flex-col">
                  <div>
                    <Label className="mb-2 mr-2 font-semibold">
                      File attachments:
                    </Label>
                    <Button variant="outline" className="mb-4">
                      Upload attachment
                    </Button>
                  </div>
                </div>
                <div className="mt-4 flex justify-end">
                  <Button size="sm">Save changes</Button>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </ContentSection.Body>
      {selectedChapter && (
        <ChapterDeleteDialog
          chapter={selectedChapter}
          isOpen={isChapterDeleteDialogOpen}
          onClose={() => setIsChapterDeleteDialogOpen(false)}
          onDelete={() => setIsChapterDeleteDialogOpen(false)}
        />
      )}
      {selectedLesson && (
        <LessonDeleteDialog
          lesson={selectedLesson}
          isOpen={isLessonDeleteDialogOpen}
          onClose={() => setIsLessonDeleteDialogOpen(false)}
          onDelete={() => setIsLessonDeleteDialogOpen(false)}
        />
      )}
    </ContentSection>
  )
}
