import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { ContentSection } from '@/layouts/app/sections/content-section'
import routes from '@/routes'
import { ContentItemsDigitalDownload } from '@/types'
import { i18n } from '@/utils'

interface DigitalDownloadEditPageProps {
  digitalDownload: ContentItemsDigitalDownload
}

export default function DigitalDownloadEditPage({
  digitalDownload,
}: DigitalDownloadEditPageProps) {
  const breadcrumbs = [
    {
      title: i18n.t('application.breadcrumbs.home'),
      link: routes.dashboard.show.path(),
    },
    {
      title: i18n.t('content_items.breadcrumbs.title'),
      link: routes.contentItems.index.path(),
    },
    {
      title: i18n.t('digital_downloads.breadcrumbs.title'),
      link: routes.contentItems.index.path(),
    },
    {
      title: digitalDownload.files[0].filename,
    },
  ]

  return (
    <ContentSection>
      <ContentSection.Header>
        <DefaultNavbar breadcrumbs={breadcrumbs} />
      </ContentSection.Header>
      <ContentSection.Body>
        {digitalDownload.files.map((file) => (
          <div key={file.signedId}>
            <h1>{file.filename}</h1>
          </div>
        ))}
      </ContentSection.Body>
    </ContentSection>
  )
}
