import { buttonVariants } from '@/components/ui/button'
import { Link } from '@/components/ui/link'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  settingsAccounts,
  settingsAdvanced,
  settingsPassword,
  settingsProfile,
} from '@/routes/generated'
import { cn, i18n } from '@/utils'
import { router, usePage } from '@inertiajs/react'
import {
  BadgeAlertIcon,
  Building,
  LockIcon,
  SettingsIcon,
  User,
  UserIcon,
} from 'lucide-react'
import { useEffect, useState } from 'react'

interface NavItem {
  title: string
  icon: JSX.Element
  href: string
}

const sidebarNavItems: {
  account: NavItem[]
  user: NavItem[]
} = {
  account: [
    {
      title: 'Accounts',
      icon: <SettingsIcon size={16} />,
      href: settingsAccounts.index.path(),
    },
    {
      title: 'Advanced',
      icon: <BadgeAlertIcon size={16} />,
      href: settingsAdvanced.index.path(),
    },
  ],
  user: [
    {
      title: 'Profile',
      icon: <UserIcon size={16} />,
      href: settingsProfile.index.path(),
    },
    // TODO: Add back in when we have notifications feature working
    // {
    //   title: 'Notifications',
    //   icon: <BellIcon size={16} />,
    //   href: '/settings/notifications',
    // },
    {
      title: 'Password',
      icon: <LockIcon size={16} />,
      href: settingsPassword.index.path(),
    },
  ],
}

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
  className?: string
}

export default function SidebarNav({ className, ...props }: SidebarNavProps) {
  const { url } = usePage()
  const [val, setVal] = useState(url ?? '/settings')

  useEffect(() => {
    setVal(url)
  }, [url])

  const handleSelect = (e: string) => {
    setVal(e)
    router.visit(e)
  }

  return (
    <>
      <div className="p-1 lg:hidden">
        <Select value={val} onValueChange={handleSelect}>
          <SelectTrigger className="h-12 w-full">
            <SelectValue placeholder="Theme" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="account" disabled>
              <div className="flex items-center px-2 py-1">
                <Building className="mr-2 h-4 w-4" />
                <span className="font-semibold">
                  {i18n.t('settings.sidebar.account')}
                </span>
              </div>
            </SelectItem>
            {sidebarNavItems.account.map((item) => (
              <SelectItem key={item.href} value={item.href}>
                <div className="flex gap-x-4 px-2 py-1">
                  <span className="text-md">{item.title}</span>
                </div>
              </SelectItem>
            ))}
            <SelectItem value="user" disabled>
              <div className="flex items-center px-2 py-1">
                <User className="mr-2 h-4 w-4" />
                <span className="font-semibold">
                  {i18n.t('settings.sidebar.user')}
                </span>
              </div>
            </SelectItem>
            {sidebarNavItems.user.map((item) => (
              <SelectItem key={item.href} value={item.href}>
                <div className="flex gap-x-4 px-2 py-1">
                  <span className="text-md">{item.title}</span>
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="hidden w-full overflow-x-auto bg-background px-1 py-2 lg:block">
        <nav className={cn('flex flex-col space-y-1', className)} {...props}>
          <div className="mb-4 flex flex-col">
            <div className="flex items-center px-4 py-1 text-muted-foreground">
              <h4 className="text-sm font-semibold">
                {i18n.t('settings.sidebar.account')}
              </h4>
            </div>
            {sidebarNavItems.account.map((item) => (
              <Link
                key={item.href}
                href={item.href}
                className={cn(
                  buttonVariants({ variant: 'ghost' }),
                  url === item.href
                    ? 'w-full bg-muted hover:bg-muted'
                    : 'hover:bg-transparent',
                  'justify-start font-normal'
                )}
              >
                <span className="mr-2">{item.icon}</span>
                {item.title}
              </Link>
            ))}
          </div>
          <div className="flex flex-col">
            <div className="flex items-center px-4 py-1 text-muted-foreground">
              <h4 className="text-sm font-semibold">
                {i18n.t('settings.sidebar.user')}
              </h4>
            </div>
            {sidebarNavItems.user.map((item) => (
              <Link
                key={item.href}
                href={item.href}
                className={cn(
                  buttonVariants({ variant: 'ghost' }),
                  url === item.href
                    ? 'w-full bg-muted hover:bg-muted'
                    : 'hover:bg-transparent',
                  'justify-start font-normal'
                )}
              >
                <span className="mr-2">{item.icon}</span>
                {item.title}
              </Link>
            ))}
          </div>
        </nav>
      </div>
    </>
  )
}
