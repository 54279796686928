import { useForm } from 'react-hook-form'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Switch } from '@/components/ui/switch'
import { useToast } from '@/hooks/use-toast.tsx'
import routes from '@/routes'
import { LinkInBioPageSettings } from '@/types'
import { i18n } from '@/utils'
import { router } from '@inertiajs/react'

interface PageSettingsFormProps {
  linkInBioPageSettings: LinkInBioPageSettings
}

const PageVisibilityForm = ({
  linkInBioPageSettings,
}: PageSettingsFormProps) => {
  const { toast } = useToast()

  const defaultValues = {
    active: linkInBioPageSettings.active,
    maintenance_mode: linkInBioPageSettings.maintenanceMode,
  }

  const form = useForm<typeof defaultValues>({
    defaultValues,
  })

  const onSubmit = (data: typeof defaultValues) => {
    router.patch(
      routes.linkInBioLinkInBioPage.update.path(),
      {
        preserveScroll: true,
        preserveState: true,
        link_in_bio_page: { ...data },
      },
      {
        onSuccess: () => {
          toast({
            description: i18n.t('link_in_bio.update_success'),
          })
        },
        onError: (errors) => {
          toast({
            description: errors.toString(),
          })
        },
      }
    )
  }

  return (
    <Form {...form}>
      <form className="flex max-w-xl flex-col gap-4">
        {/* <FormField
          control={form.control}
          name="active"
          render={({ field }) => (
            <FormItem className="flex items-center justify-between">
              <FormLabel>{i18n.t('forms.fields.active')}</FormLabel>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={(checked) => {
                    field.onChange(checked)
                    onSubmit({ ...form.getValues(), active: checked })
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        /> */}

        <FormField
          control={form.control}
          name="maintenance_mode"
          render={({ field }) => (
            <FormItem className="flex items-center justify-between">
              <FormLabel>{i18n.t('forms.fields.maintenance_mode')}</FormLabel>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={(checked) => {
                    field.onChange(checked)
                    onSubmit({
                      ...form.getValues(),
                      maintenance_mode: checked,
                    })
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  )
}

export default PageVisibilityForm
