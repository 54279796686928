// JsFromRoutes CacheKey f69c0efa05f86f67c7471e5aa0eb3eb9
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/upcoming/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/upcoming/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/subscriptions/:subscription_id/upcoming'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/subscriptions/:subscription_id/upcoming'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/subscriptions/:subscription_id/upcoming'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/subscriptions/:subscription_id/upcoming'),
}
