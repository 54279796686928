// JsFromRoutes CacheKey e7c464dc27d5223fd57b1f0e26b5cbc7
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/pay/payment_methods'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/pay/payment_methods'),
  new: /* #__PURE__ */ definePathHelper('get', '/admin/pay/payment_methods/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/pay/payment_methods/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/pay/payment_methods/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/pay/payment_methods/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/pay/payment_methods/:id'),
}
