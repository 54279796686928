import { GoogleIcon } from '@/components/shared/social-icons'
import { Button } from '@/components/ui/button'
import useTypedPage from '@/hooks/use-typed-page'
import { i18n } from '@/utils'
import { router } from '@inertiajs/react'

// TODO: Finish implementing omniauth

export const Omniauth = () => {
  const isOmniauthable = useTypedPage().props.isOmniauthable as boolean
  const omniauthProviders = useTypedPage().props.omniauthProviders as string[]

  return (
    <>
      {isOmniauthable && omniauthProviders.length > 0 && (
        <>
          <div className="relative my-2">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t" />
            </div>
            <div className="relative flex justify-center text-xs uppercase">
              <span className="bg-background px-2 text-muted-foreground">
                or
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <Button
              onClick={() => {
                router.post('/auth/google_oauth2/callback')
              }}
              variant="outline"
              className="w-full"
              type="button"
            >
              <GoogleIcon className="mr-2 h-4 w-4" />
              {i18n.t('oauth.google_oauth2')}
            </Button>
          </div>
        </>
      )}
    </>
  )
}
