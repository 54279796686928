import { FaqList } from '@/components/shared/faq-list'
import Meta from '@/components/shared/meta'
import { Input } from '@/components/ui/input'
import { APP_NAME } from '@/constants'
import useTypedPage from '@/hooks/use-typed-page'
import { LineChart } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const faqList = [
  {
    question: 'What is an Affiliate Earnings Calculator?',
    answer:
      'An Affiliate Earnings Calculator helps you estimate potential earnings from affiliates based on product price, affiliate percentage, and number of sales.',
  },
  {
    question: 'How do I calculate the total affiliate earnings?',
    answer:
      'To calculate the total affiliate earnings, input the product price, affiliate percentage, and number of sales. The calculator will determine the total affiliate earnings and the creator’s net earnings after affiliate payout.',
  },
  {
    question: 'What is the product price?',
    answer:
      'The product price is the amount of money charged for a single unit of the product.',
  },
  {
    question: 'What is the affiliate percentage?',
    answer:
      'The affiliate percentage is the commission rate given to affiliates for each sale they generate.',
  },
  {
    question: 'What is the number of sales?',
    answer:
      'The number of sales is the total quantity of products sold through affiliates.',
  },
]

const AffiliateEarningsCalculatorPage: React.FC = () => {
  const { name, description } = useTypedPage().props
  const [productPrice, setProductPrice] = useState<number>(100)
  const [affiliatePercentage, setAffiliatePercentage] = useState<number>(10) // percentage
  const [numberOfSales, setNumberOfSales] = useState<number>(50)
  const [totalAffiliateEarnings, setTotalAffiliateEarnings] =
    useState<string>('500.00')
  const [netEarnings, setNetEarnings] = useState<string>('4500.00')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const calculateEarnings = () => {
    const affiliateEarnings =
      productPrice * (affiliatePercentage / 100) * numberOfSales
    const netEarnings = productPrice * numberOfSales - affiliateEarnings
    setTotalAffiliateEarnings(formatter.format(affiliateEarnings))
    setNetEarnings(formatter.format(netEarnings))
  }

  useEffect(() => {
    calculateEarnings()
  }, [productPrice, affiliatePercentage, numberOfSales])

  const metaTitle = `${name} | ${APP_NAME}`
  const metaDescription = description as string

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container mx-auto max-w-5xl">
        <div className="overflow-hidden">
          <div className="mx-auto mb-6 max-w-3xl text-center">
            <h1 className="mb-5 font-heading text-5xl font-medium">
              Affiliate Earnings Calculator
            </h1>
            <p className="text-neutral-500">
              Estimate potential earnings from affiliates based on product
              price, affiliate percentage, and number of sales.
            </p>
          </div>
          <div className="rounded-md border border-border bg-accent p-5">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
              <div className="col-span-1">
                <label htmlFor="product_price" className="font-semibold">
                  Product Price
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What is the price of the product?
                </div>
                <Input
                  type="number"
                  id="product_price"
                  value={productPrice}
                  onChange={(e) => setProductPrice(Number(e.target.value))}
                  placeholder="Product Price"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="affiliate_percentage" className="font-semibold">
                  Affiliate Percentage (%)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What is the commission rate for affiliates?
                </div>
                <Input
                  type="number"
                  id="affiliate_percentage"
                  value={affiliatePercentage}
                  onChange={(e) =>
                    setAffiliatePercentage(Number(e.target.value))
                  }
                  placeholder="Affiliate Percentage (%)"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="number_of_sales" className="font-semibold">
                  Number of Sales
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  How many products were sold through affiliates?
                </div>
                <Input
                  type="number"
                  id="number_of_sales"
                  value={numberOfSales}
                  onChange={(e) => setNumberOfSales(Number(e.target.value))}
                  placeholder="Number of Sales"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="mt-6 rounded-md border border-border bg-background px-4 py-5 sm:p-6">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <LineChart className="mr-2 inline-block" />
                    Total Affiliate Earnings:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="total-affiliate-earnings-value">
                      {totalAffiliateEarnings}
                    </span>
                  </div>
                </div>
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <LineChart className="mr-2 inline-block" />
                    Creator’s Net Earnings:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="net-earnings-value">{netEarnings}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FaqList faqList={faqList} />
      </div>
    </>
  )
}

export default AffiliateEarningsCalculatorPage
