import Changelog from '@/components/shared/navbar/changelog'
import { UserNav } from '@/components/shared/navbar/user-nav'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { HomeIcon } from 'lucide-react'
import React from 'react'

interface BreadcrumbItem {
  title: string
  link?: string
}

interface DefaultNavbarProps {
  breadcrumbs: BreadcrumbItem[]
}

export const DefaultNavbar: React.FC<DefaultNavbarProps> = ({
  breadcrumbs,
}) => {
  return (
    <div className="flex w-full items-center justify-between">
      <Breadcrumb>
        <BreadcrumbList>
          {breadcrumbs.map((item, index) => (
            <React.Fragment key={item.title}>
              {index > 0 && <BreadcrumbSeparator />}
              <BreadcrumbItem>
                {item.link ? (
                  <BreadcrumbLink href={item.link}>
                    {index === 0 ? (
                      <HomeIcon className="h-4 w-4" />
                    ) : (
                      item.title
                    )}
                  </BreadcrumbLink>
                ) : (
                  <BreadcrumbPage>{item.title}</BreadcrumbPage>
                )}
              </BreadcrumbItem>
            </React.Fragment>
          ))}
        </BreadcrumbList>
      </Breadcrumb>
      <div className="flex items-center">
        <Changelog />
        {/* TODO: Add notifications feature from ERB */}
        {/* <NotificationsPanel /> */}

        {/* Dark mode disabled for now */}
        {/* <ThemeSwitch /> */}
        <UserNav />
      </div>
    </div>
  )
}
