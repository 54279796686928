import { I18n as I18nJs, Scope, TranslateOptions } from 'i18n-js'
import enTranslations from '@/locales/en.json'

class I18n {
  private readonly i18n: I18nJs

  constructor() {
    this.i18n = new I18nJs(enTranslations)
  }

  getLocale() {
    return this.i18n.locale
  }

  t(scope: Scope, options?: TranslateOptions) {
    return this.i18n.t(scope, options)
  }

  scopedTranslator(rootScope: Scope) {
    return (scope: Scope, options = {}) =>
      this.i18n.t(scope, Object.assign({ scope: rootScope }, options))
  }

  async loadTranslations() {
    const locale = document.querySelector('meta[name="locale"]')?.textContent
    const localeFilePath = document.querySelector(
      'meta[name="locale-file-path"]'
    )?.textContent

    if (!locale || !localeFilePath) {
      console.error('Locale or locale file path not found')
      return
    }

    const response = await fetch(localeFilePath)
    const translations = await response.json()

    this.i18n.locale = locale
    this.i18n.store(translations)
  }
}
export default new I18n()
