import { FaqList } from '@/components/shared/faq-list'
import Meta from '@/components/shared/meta'
import { Input } from '@/components/ui/input'
import { APP_NAME } from '@/constants'
import useTypedPage from '@/hooks/use-typed-page'
import { DollarSign } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const faqList = [
  {
    question: 'What is a Discount Impact Calculator?',
    answer:
      'A Discount Impact Calculator helps you determine the impact of offering discounts on your overall revenue.',
  },
  {
    question: 'How do I calculate the impact of a discount?',
    answer:
      'To calculate the impact, input the original price, discount percentage, and projected sales. The calculator will determine the expected revenue before and after the discount.',
  },
  {
    question: 'What is the original price?',
    answer:
      'The original price is the price at which you initially plan to sell the product.',
  },
  {
    question: 'What is the discount percentage?',
    answer:
      'The discount percentage is the percentage by which you plan to reduce the original price.',
  },
  {
    question: 'What are projected sales?',
    answer:
      'Projected sales are the estimated number of units you expect to sell.',
  },
]

const DiscountImpactCalculatorPage: React.FC = () => {
  const { name, description } = useTypedPage().props
  const [originalPrice, setOriginalPrice] = useState<number>(100)
  const [discountPercentage, setDiscountPercentage] = useState<number>(10) // percentage
  const [projectedSales, setProjectedSales] = useState<number>(1000)
  const [revenueBeforeDiscount, setRevenueBeforeDiscount] =
    useState<string>('100,000.00')
  const [revenueAfterDiscount, setRevenueAfterDiscount] =
    useState<string>('90,000.00')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const calculateRevenueImpact = () => {
    const revenueBefore = originalPrice * projectedSales
    const discountedPrice = originalPrice * (1 - discountPercentage / 100)
    const revenueAfter = discountedPrice * projectedSales
    setRevenueBeforeDiscount(formatter.format(revenueBefore))
    setRevenueAfterDiscount(formatter.format(revenueAfter))
  }

  useEffect(() => {
    calculateRevenueImpact()
  }, [originalPrice, discountPercentage, projectedSales])

  const metaTitle = `${name} | ${APP_NAME}`
  const metaDescription = description as string

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container mx-auto max-w-5xl">
        <div className="overflow-hidden">
          <div className="mx-auto mb-6 max-w-3xl text-center">
            <h1 className="mb-5 font-heading text-5xl font-medium">
              Discount Impact Calculator
            </h1>
            <p className="text-neutral-500">
              Calculate the impact of offering discounts on your overall
              revenue.
            </p>
          </div>
          <div className="rounded-md border border-border bg-accent p-5">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
              <div className="col-span-1">
                <label htmlFor="original_price" className="font-semibold">
                  Original Price (USD)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What is the original price of your product?
                </div>
                <Input
                  type="number"
                  id="original_price"
                  value={originalPrice}
                  onChange={(e) => setOriginalPrice(Number(e.target.value))}
                  placeholder="Original Price"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="discount_percentage" className="font-semibold">
                  Discount Percentage (%)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What percentage discount do you want to offer?
                </div>
                <Input
                  type="number"
                  id="discount_percentage"
                  value={discountPercentage}
                  onChange={(e) =>
                    setDiscountPercentage(Number(e.target.value))
                  }
                  placeholder="Discount Percentage (%)"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="projected_sales" className="font-semibold">
                  Projected Sales (Units)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  How many units do you expect to sell?
                </div>
                <Input
                  type="number"
                  id="projected_sales"
                  value={projectedSales}
                  onChange={(e) => setProjectedSales(Number(e.target.value))}
                  placeholder="Projected Sales"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="mt-6 rounded-md border border-border bg-background px-4 py-5 sm:p-6">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <DollarSign className="mr-2 inline-block" />
                    Expected Revenue:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="revenue-before-discount-value">
                      Before Discount: {revenueBeforeDiscount}
                    </span>
                  </div>
                  <div className="text-3xl font-bold text-primary">
                    <span id="revenue-after-discount-value">
                      After Discount: {revenueAfterDiscount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FaqList faqList={faqList} />
      </div>
    </>
  )
}

export default DiscountImpactCalculatorPage
