import SignupForm from '@/components/features/auth/forms/signup-form'
import Meta from '@/components/shared/meta'
import { Link } from '@/components/ui/link'
import { APP_NAME } from '@/constants'
import AuthLayout from '@/layouts/auth-layout'
import routes from '@/routes'
import { ReactNode } from 'react'

const SignupPage = () => {
  const metaTitle = `Create an account | ${APP_NAME}`
  const metaDescription = `Create an account to access all features of ${APP_NAME}`
  const slogan = 'Everything you need to sell digital products and memberships'

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
        <div className="hidden space-y-4 md:block">
          <div>
            <h2 className="mb-6 text-xl font-semibold">{slogan}</h2>
            <ul className="space-y-3 text-sm text-muted-foreground">
              <li>🚀 Quick to setup</li>
              <li>🎨 Customizable to your brand</li>
              <li>💰 Charge for single or recurring payments</li>
            </ul>
          </div>
        </div>
        <div>
          <div className="mb-4 flex flex-col space-y-2">
            <h1 className="text-center text-xl font-semibold tracking-tight">
              Create your {APP_NAME} account
            </h1>
            <h2 className="mb-6 block text-center text-sm text-muted-foreground md:hidden">
              {slogan}
            </h2>
          </div>
          <SignupForm />
          <p className="mt-4 text-center text-xs text-muted-foreground opacity-70">
            By signing up, you agree to our{' '}
            <Link
              href={routes.websiteStatic.terms.path()}
              className="underline underline-offset-4 hover:text-primary"
              target="_blank"
            >
              Terms
            </Link>{' '}
            and{' '}
            <Link
              href={routes.websiteStatic.privacy.path()}
              className="underline underline-offset-4 hover:text-primary"
              target="_blank"
            >
              Privacy
            </Link>
            .
          </p>
          <p className="mt-4 border-t border-border pt-5 text-center text-sm text-muted-foreground">
            Already have an account?{' '}
            <Link
              href={routes.usersSessions.new.path()}
              className="underline underline-offset-4 hover:text-primary"
            >
              Log in
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}

SignupPage.layout = (page: ReactNode) => (
  <AuthLayout width="wide">{page}</AuthLayout>
)

export default SignupPage
