import {
  Form,
  FormInput,
  FormSelect,
  FormSubmit,
} from '@/components/shared/inertia-form'
import { UseFormProps } from '@/lib/use-inertia-form'

import routes from '@/routes'
import { LinkInBioSettings, LinkInBioTemplate } from '@/types'
import { i18n } from '@/utils'

const FONT_FAMILIES = [
  {
    value: 'inherit',
    label: 'Atlas Grotesk',
  },
  {
    value: 'Arial',
    label: 'Arial',
  },
  {
    value: 'Times New Roman',
    label: 'Times New Roman',
  },
  {
    value: 'Courier New',
    label: 'Courier New',
  },
]

const BORDER_RADIUS_OPTIONS = [
  { value: '0px', label: 'None' },
  { value: '6px', label: 'Small' },
  { value: '12px', label: 'Large' },
  { value: '9999px', label: 'Full' },
]

interface AccountTemplateFormData {
  link_in_bio_template: {
    template_id: number
    primary_color: string
    background_color: string
    font_family: string
    button_radius: string
  }
}

interface AccountTemplateFormProps {
  settings?: LinkInBioSettings
  templateId: LinkInBioTemplate['id']
}

const AccountTemplateForm = ({
  settings,
  templateId,
}: AccountTemplateFormProps) => {
  const data: AccountTemplateFormData = {
    link_in_bio_template: {
      template_id: templateId,
      primary_color: settings?.primaryColor || '#000000',
      background_color: settings?.backgroundColor || '#FFFFFF',
      font_family: settings?.fontFamily || FONT_FAMILIES[0].value,
      button_radius:
        settings?.buttonRadius?.toString() || BORDER_RADIUS_OPTIONS[0].value,
    },
  }

  const handleSubmit = ({
    transform,
  }: UseFormProps<AccountTemplateFormData>) => {
    transform((data) => ({
      link_in_bio_template: {
        ...data.link_in_bio_template,
        template_id: templateId,
      },
    }))
  }

  return (
    <Form
      onSubmit={handleSubmit}
      model="link_in_bio_template"
      data={data}
      to={routes.linkInBioLinkInBioTemplates.account.path()}
      className="flex flex-col justify-center gap-4"
    >
      <div className="grid grid-cols-2 gap-4">
        <FormInput
          label={i18n.t('forms.fields.primary_color')}
          name="primary_color"
          type="color"
        />
        <FormInput
          label={i18n.t('forms.fields.background_color')}
          name="background_color"
          type="color"
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <FormSelect
          label={i18n.t('forms.fields.font_family')}
          name="font_family"
          placeholder={i18n.t('forms.placeholders.select')}
          options={FONT_FAMILIES}
        />

        <FormSelect
          label={i18n.t('forms.fields.button_radius')}
          name="button_radius"
          placeholder={i18n.t('forms.placeholders.select')}
          options={BORDER_RADIUS_OPTIONS}
        />
      </div>

      <FormSubmit useAutoDisable={false} className="mt-2">
        {i18n.t('save')}
      </FormSubmit>
    </Form>
  )
}

export default AccountTemplateForm
