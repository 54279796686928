// JsFromRoutes CacheKey 478988b224efc13dec55d5da7297b1b4
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/me/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/me/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/me'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/api/v1/me'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/api/v1/me'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/api/v1/me'),
}
