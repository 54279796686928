// JsFromRoutes CacheKey a252f240a6e085bd4af899c90563a631
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/users'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/api/v1/users'),
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/users/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/users/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/users/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/api/v1/users/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/api/v1/users/:id'),
}
