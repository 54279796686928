import AdminBanner from '@/components/features/link-in-bio/public-page/admin-banner'
import ReferralBanner from '@/components/features/link-in-bio/public-page/referral-banner'
import InjectedBodyScripts from '@/components/features/settings/tracking-codes/injected-body-scripts'
import InjectedHeadCodes from '@/components/features/settings/tracking-codes/injected-head-codes'
import Meta from '@/components/shared/meta'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Link } from '@/components/ui/link'
import FullScreenLayout from '@/layouts/full-screen-layout'
import { Account, TrackingCodes } from '@/types'
import { Book, Github, Instagram, Linkedin, Twitter } from 'lucide-react'
import React, { ReactNode } from 'react'

interface SocialLink {
  name: string
  icon: React.ReactNode
  url: string
}

interface Product {
  name: string
  price: string
  description: string
}

interface LinkInBioProps {
  account: Account
  accountLinkInBioTemplate: {
    settings: {
      background_color: string
      primary_color: string
      button_radius: number
    }
  }
  trackingCodes: TrackingCodes
}

const LinkInBio = ({
  account,
  accountLinkInBioTemplate,
  trackingCodes,
}: LinkInBioProps) => {
  const socialLinks: SocialLink[] = [
    { name: 'Instagram', icon: <Instagram />, url: '#' },
    { name: 'Twitter', icon: <Twitter />, url: '#' },
    { name: 'GitHub', icon: <Github />, url: '#' },
    { name: 'LinkedIn', icon: <Linkedin />, url: '#' },
  ]

  const products: Product[] = [
    {
      name: 'Ebook 1',
      price: '$9.99',
      description: 'A guide to mastering your craft',
    },
    {
      name: 'Online Course',
      price: '$49.99',
      description: 'In-depth video tutorials',
    },
    {
      name: 'Exclusive Podcast',
      price: '$4.99/mo',
      description: 'Weekly insider tips',
    },
  ]

  return (
    <>
      <Meta title={`@${account?.subdomain}`}>
        <InjectedHeadCodes trackingCodes={trackingCodes} />
      </Meta>
      <div
        className="flex min-h-screen flex-col items-center justify-center p-6"
        style={{
          backgroundColor: accountLinkInBioTemplate.settings.background_color,
        }}
      >
        <div className="w-full max-w-2xl overflow-hidden rounded-lg bg-background shadow-lg">
          <div className="p-6 sm:p-8">
            <div className="flex flex-col items-center">
              <Avatar className="h-24 w-24">
                <AvatarImage src={account?.avatar?.src} alt={account?.name} />
                <AvatarFallback>
                  {account?.name ? account?.name.charAt(0) : ''}
                </AvatarFallback>
              </Avatar>
              <h1 className="mt-4 text-2xl font-bold text-gray-900">
                {account?.name}
              </h1>
              <p className="text-sm text-muted-foreground">{`@${account?.subdomain}`}</p>
              <div className="mt-4 flex space-x-4">
                {socialLinks.map((link) => (
                  <Link
                    key={link.name}
                    href={link.url}
                    className="text-muted-foreground transition duration-300 hover:text-gray-900"
                    aria-label={link.name}
                  >
                    {React.cloneElement(link.icon as React.ReactElement, {
                      className: 'h-6 w-6',
                    })}
                  </Link>
                ))}
              </div>
            </div>
            <div className="mt-8">
              <h2 className="mb-4 text-xl font-semibold text-gray-900">
                My Products
              </h2>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {products.map((product) => (
                  <div
                    key={product.name}
                    className="rounded-lg border border-border p-4 transition duration-300 hover:shadow-md"
                  >
                    <div className="mb-3 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                      <Book className="h-6 w-6 text-muted-foreground" />
                    </div>
                    <h3 className="font-semibold text-gray-900">
                      {product.name}
                    </h3>
                    <p className="mt-1 text-sm text-muted-foreground">
                      {product.description}
                    </p>
                    <p className="mt-2 text-lg font-bold text-gray-900">
                      {product.price}
                    </p>
                    <button
                      className="mt-3 w-full py-2 text-white transition duration-300"
                      style={{
                        backgroundColor:
                          accountLinkInBioTemplate.settings.primary_color,
                        borderRadius: `${accountLinkInBioTemplate.settings.button_radius}px`,
                      }}
                    >
                      Buy Now
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-auto">
          <ReferralBanner />
        </div>
      </div>

      <InjectedBodyScripts trackingCodes={trackingCodes} />
      <AdminBanner
        onViewAs={() => {}}
        onEditPage={() => {}}
        onGoToDashboard={() => {}}
        onClose={() => {}}
      />
    </>
  )
}

LinkInBio.layout = (page: ReactNode) => (
  <FullScreenLayout>{page}</FullScreenLayout>
)

export default LinkInBio
