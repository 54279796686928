import {
  Form,
  FormCombobox,
  FormInput,
  FormSelect,
  FormSubmit,
} from '@/components/shared/inertia-form'
import { Alert, AlertDescription } from '@/components/ui/alert'
import { Label } from '@/components/ui/label'
import useTypedPage from '@/hooks/use-typed-page'
import { UseFormProps } from '@/lib/use-inertia-form'
import { usersRegistrations } from '@/routes/generated'
import { User } from '@/types'
import { i18n } from '@/utils'
import AvatarInput from './avatar-input'

type ProfileFormData = {
  user: {
    name: string
    email: string
    avatar?: File | null
    time_zone: string
    preferred_language: string
    theme: string
  }
}

interface ProfileFormProps {
  user: User
  timeZones: { name: string; value: string }[]
  availableLocales: [string, string][]
  themes: { label: string; value: string }[]
  isEmailPendingReconfirmation: boolean
  unconfirmedEmail: string | null
}

const ProfileForm = ({ user }: { user: User }) => {
  const {
    timeZones,
    availableLocales,
    isEmailPendingReconfirmation,
    unconfirmedEmail,
  } = useTypedPage().props as unknown as ProfileFormProps

  const data: ProfileFormData = {
    user: {
      name: user.name || '',
      email: user.email,
      avatar: null,
      time_zone: user.timeZone || '',
      preferred_language: user.preferredLanguage || '',
      theme: user.theme || '',
    },
  }

  const handleSubmit = ({ transform }: UseFormProps<ProfileFormData>) => {
    transform((data) => ({
      user: {
        ...data.user,
        avatar: data.user.avatar || undefined,
      },
    }))
  }

  return (
    <Form
      model="user"
      method="patch"
      onSubmit={handleSubmit}
      to={usersRegistrations.update.path()}
      data={data}
    >
      <div className="grid grid-cols-2 gap-3">
        <FormInput
          name="name"
          label={i18n.t('forms.fields.full_name')}
          required
        />

        <FormInput name="email" label={i18n.t('forms.fields.email')} required />
      </div>

      {isEmailPendingReconfirmation && (
        <Alert variant="info" className="py-3">
          <AlertDescription>
            {i18n.t(
              'settings.profile.currently_waiting_confirmation_for_email',
              { email: unconfirmedEmail }
            )}
          </AlertDescription>
        </Alert>
      )}

      <div className="mb-2 mt-3 flex flex-col gap-4">
        <Label>Profile Image</Label>
        <AvatarInput avatarSrc={user.avatar?.src} />
      </div>

      <FormCombobox
        name="time_zone"
        label="Preferred Time Zone"
        options={timeZones.map((tz) => ({
          label: tz.name,
          value: tz.value,
        }))}
      />
      {availableLocales.length > 1 && (
        <FormSelect
          name="preferred_language"
          label={i18n.t('activerecord.attributes.user.preferred_language')}
          options={availableLocales.map((locale: [string, string]) => ({
            label: locale[0],
            value: locale[1],
          }))}
        />
      )}

      {/* TODO: Add back in when we have dark mode enabled*/}
      {/* <FormSelect
        name="theme"
        label={i18n.t('activerecord.attributes.user.theme')}
        options={themes}
      /> */}

      <FormSubmit className="mt-2 w-fit">{i18n.t('save')}</FormSubmit>
    </Form>
  )
}

export default ProfileForm
