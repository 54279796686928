import { CANNY_FEATURE_REQUESTS_URL, SUPPORT_EMAIL } from '@/constants'
import routes from '@/routes'
import {
  DollarSign,
  FileText,
  HelpCircle,
  LayoutDashboard,
  Lightbulb,
  LinkIcon,
  Package,
  Settings,
} from 'lucide-react'

export interface NavLink {
  title: string
  label?: string
  href: string
  icon: JSX.Element
  external?: boolean
}

export interface SideLink extends NavLink {
  sub?: NavLink[]
}

export const sidelinks: SideLink[] = [
  {
    title: 'Dashboard',
    label: '',
    href: routes.dashboard.show.path(),
    icon: <LayoutDashboard size={18} />,
  },
  {
    title: 'Content',
    label: '',
    href: routes.contentItems.index.path(),
    icon: <FileText size={18} />,
  },
  {
    title: 'Products',
    label: '',
    href: routes.products.index.path(),
    icon: <Package size={18} />,
  },
  {
    title: 'Link-in-Bio',
    label: '',
    href: routes.linkInBioBlockables.index.path(),
    icon: <LinkIcon size={18} />,
  },
  // TODO: Add this back in when we have members feature
  // {
  //   title: 'Members',
  //   label: '',
  //   href: routes.application.members.path(),
  //   icon: <Users size={18} />,
  //   sub: [
  //     {
  //       title: 'Customers',
  //       label: '',
  //       href: routes.application.members.path(),
  //       icon: <Users size={18} />,
  //     },
  //     {
  //       title: 'Leads',
  //       label: '',
  //       href: '/leads',
  //       icon: <Users size={18} />,
  //     },
  //   ],
  // },
  // TODO: Add this back in when we have mailing feature
  // {
  //   title: 'Email',
  //   label: '',
  //   href: '/email',
  //   icon: <Mail size={18} />,
  // },
  // TODO: Add this back in when we have leaderboard feature (only enabled for top 100 earners)
  // {
  //   title: 'Leaderboard',
  //   label: '',
  //   href: routes.application.leaderboard.path(),
  //   icon: <Trophy size={18} />,
  // },
  {
    title: 'Payouts',
    label: '',
    href: routes.application.payouts.path(),
    icon: <DollarSign size={18} />,
  },
]

export const bottomSideLinks: SideLink[] = [
  {
    title: 'Request a feature',
    label: '',
    href: CANNY_FEATURE_REQUESTS_URL,
    icon: <Lightbulb size={18} />,
    external: true,
  },
  {
    title: 'Help',
    label: '',
    href: `mailto:${SUPPORT_EMAIL}?subject=Help with ${window.location.hostname}`,
    icon: <HelpCircle size={18} />,
  },
  {
    title: 'Settings',
    label: '',
    href: routes.settingsProfile.index.path(),
    icon: <Settings size={18} />,
  },
]
