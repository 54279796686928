import { FormError } from '@/components/shared/inertia-form/form-error'
import { Label } from '@/components/ui/label'
import {
  NestedObject,
  UseFormProps,
  useInertiaInput,
} from '@/lib/use-inertia-form'
import { cn } from '@/utils'
import * as SwitchPrimitives from '@radix-ui/react-switch'
import * as React from 'react'

interface FormSwitchProps<TForm extends NestedObject = NestedObject>
  extends Omit<
    React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>,
    'onChange'
  > {
  label?: string
  helperText?: string | React.ReactNode
  name: string
  model?: string
  onChange?: (value: boolean, form: UseFormProps<TForm>) => void
}

const FormSwitch = <TForm extends NestedObject = NestedObject>({
  label,
  name,
  required,
  onChange,
  id,
  model,
  helperText,
  className,
  ...props
}: FormSwitchProps<TForm>) => {
  const { form, inputName, inputId, value, setValue, error } = useInertiaInput<
    boolean,
    TForm
  >({ name, model })

  return (
    <div className="flex w-full flex-col gap-2">
      {label && (
        <Label required={required} htmlFor={id || inputId}>
          {label}
        </Label>
      )}
      <SwitchPrimitives.Root
        id={id || inputId}
        name={inputName}
        checked={value}
        onCheckedChange={(checked) => {
          if (onChange) onChange(checked, form)
          setValue(checked)
        }}
        className={cn(
          'peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input',
          { 'border-destructive': error },
          className
        )}
        {...props}
      >
        <SwitchPrimitives.Thumb
          className={cn(
            'pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0'
          )}
        />
      </SwitchPrimitives.Root>
      {helperText && (
        <p className="text-xs text-muted-foreground">{helperText}</p>
      )}
      <FormError name={label || name} error={error} />
    </div>
  )
}

export default FormSwitch
