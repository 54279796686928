import { Form, FormInput, FormSubmit } from '@/components/shared/inertia-form'
import { Link } from '@/components/ui/link'
import routes from '@/routes'
import { i18n } from '@/utils'

interface ResetPasswordFormData {
  user: {
    email: string
  }
}

const data: ResetPasswordFormData = {
  user: {
    email: '',
  },
}

const ResetPasswordForm = () => {
  return (
    <Form model="user" to={routes.usersPasswords.create.path()} data={data}>
      <FormInput
        name="email"
        type="email"
        placeholder={i18n.t('forms.fields.email')}
        autoComplete="email"
        helperText={
          <>
            Remember your password?{' '}
            <Link
              href={routes.usersSessions.new.path()}
              className="underline underline-offset-4 hover:text-primary"
            >
              Log in
            </Link>
          </>
        }
      />
      <FormSubmit>Send reset password instructions</FormSubmit>
    </Form>
  )
}

export default ResetPasswordForm
