import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import FaqBlockable from '@/types/blocks/faq'
import { FaqQuestion } from '@/types/blocks/faq-question'

export default function FaqPreview(props: FaqBlockable) {
  const { title, headline, description, style, questionsAttributes } = props
  return (
    <div id="faq-block" className="">
      <p>
        Title:
        <span>{title}</span>
      </p>
      <p>
        Headline:
        <span>{headline}</span>
      </p>
      <p>
        Description:
        <span>{description}</span>
      </p>
      {style === 'accordion' ? (
        <Accordion type="single" collapsible>
          {Object.entries(questionsAttributes).map(
            ([question_idx, attributes]: [string, FaqQuestion]) => {
              const { question, answer } = attributes
              return (
                <AccordionItem key={question_idx} value={question_idx}>
                  <AccordionTrigger>{question}</AccordionTrigger>
                  <AccordionContent>{answer}</AccordionContent>
                </AccordionItem>
              )
            }
          )}
        </Accordion>
      ) : (
        <ol>
          {Object.entries(questionsAttributes).map(
            ([question_idx, attributes]: [string, FaqQuestion]) => {
              const { question, answer } = attributes
              return (
                <li key={question_idx}>
                  <p>{question}</p>
                  <p>
                    <span>{answer}</span>
                  </p>
                </li>
              )
            }
          )}
        </ol>
      )}
    </div>
  )
}
