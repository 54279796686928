import ImageBlockable from '@/types/blocks/image'

const aspectRatioClasses: { [key: string]: string } = {
  '1:1': 'aspect-w-1 aspect-h-1 h-0',
  '16:9': 'aspect-w-16 aspect-h-9 h-0',
}

const layoutClasses: { [key: string]: string } = {
  column: 'flex-col',
  carousel: 'flex-row overflow-x-scroll',
}

const mobileStretchClasses: { [key: string]: string | null } = {
  true: null,
  false: 'px-2',
}

export default function ImagePreview(props: ImageBlockable) {
  const {
    title,
    headline,
    description,
    images,
    aspectRatio,
    layout,
    mobileStretch,
  } = props
  return (
    <div
      className={`flex flex-col items-center ${mobileStretchClasses[mobileStretch.toString()]}`}
    >
      <div>
        title:
        <span>{title}</span>
      </div>

      <div>
        headline:
        <span>{headline}</span>
      </div>

      <div>
        description:
        <span>{description}</span>
      </div>

      <div className="w-full py-2">
        <div className={`flex gap-2 ${layoutClasses[layout]}`}>
          {images.map((image, index) => {
            const src =
              image instanceof File
                ? URL.createObjectURL(image)
                : typeof image === 'string'
                  ? image
                  : image.src
            return (
              <div
                key={`img_${index}`}
                className={`w-full ${layout === 'carousel' ? 'flex-shrink-0' : ''}`}
              >
                <div className={`${aspectRatioClasses[aspectRatio]}`}>
                  <img
                    src={src}
                    className="m-0 w-full"
                    alt={`Image ${index + 1}`}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
