import ResendConfirmationForm from '@/components/features/auth/forms/resend-confirmation-form'
import Meta from '@/components/shared/meta'
import { Link } from '@/components/ui/link'
import { APP_NAME } from '@/constants'
import AuthLayout from '@/layouts/auth-layout'
import routes from '@/routes'
import { ReactNode } from 'react'

const ResendConfirmationPage = () => {
  const metaTitle = `Resend confirmation | ${APP_NAME}`
  const metaDescription = `Resend confirmation for ${APP_NAME}`

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="mb-4 flex flex-col space-y-2">
        <h1 className="text-center text-xl font-semibold tracking-tight">
          Resend confirmation
        </h1>
      </div>

      <ResendConfirmationForm />

      <p className="mt-4 border-t border-border pt-5 text-center text-sm text-muted-foreground">
        <Link
          href={routes.usersPasswords.new.path()}
          className="underline underline-offset-4 hover:text-primary"
        >
          Forgot your password?
        </Link>
        <span className="mx-2">|</span>
        <Link
          href={routes.usersSessions.new.path()}
          className="underline underline-offset-4 hover:text-primary"
        >
          Log in
        </Link>
      </p>
    </>
  )
}

ResendConfirmationPage.layout = (page: ReactNode) => (
  <AuthLayout>{page}</AuthLayout>
)

export default ResendConfirmationPage
