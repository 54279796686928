import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { buttonVariants } from '@/components/ui/button'
import routes from '@/routes'
import { Product } from '@/types'
import { cn, i18n } from '@/utils'
import { router } from '@inertiajs/react'
import { useEffect, useState } from 'react'

interface ProductDeleteDialogProps {
  product: Product
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}

export default function ProductDeleteDialog({
  product,
  isOpen,
  onClose,
  onDelete,
}: ProductDeleteDialogProps) {
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false)

  useEffect(() => {
    setIsAlertDialogOpen(isOpen)
  }, [isOpen])

  const handleProductDelete = () => {
    router.delete(
      routes.products.destroy.path({
        prefix_id: product.prefixId,
      }),
      {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
          onDelete()
          onClose()
        },
      }
    )
  }

  const handleClose = () => {
    setIsAlertDialogOpen(false)
    onClose()
  }

  return (
    <AlertDialog open={isAlertDialogOpen} onOpenChange={handleClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {i18n.t('products.destroy.dialog.title')}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {i18n.t('products.destroy.dialog.description')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{i18n.t('cancel')}</AlertDialogCancel>
          <AlertDialogAction
            className={cn(buttonVariants({ variant: 'destructive' }))}
            onClick={handleProductDelete}
          >
            {i18n.t('delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
