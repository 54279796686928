import { Button, buttonVariants } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import routes from '@/routes'
import { User } from '@/types'
import { cn, i18n } from '@/utils'
import { Link, useForm } from '@inertiajs/react'
import React, { useState } from 'react'

interface DeleteAccountProps {
  user: User
}

const DeleteAccount: React.FC<DeleteAccountProps> = ({ user }) => {
  const { delete: destroy } = useForm()
  const [isOpen, setIsOpen] = useState(false)
  const [confirmEmail, setConfirmEmail] = useState('')

  const handleDeleteAccount = () => {
    if (confirmEmail === user.email) {
      destroy(routes.usersRegistrations.destroy.path({ id: user.id }))
      setIsOpen(false)
    }
  }

  return (
    <div className="flex-end flex flex-wrap">
      <div className="flex-1">
        <h3 className="mb-2 text-lg font-medium">
          {i18n.t('settings.profile.danger_zone')}
        </h3>
        <p className="mb-4 text-sm text-muted-foreground">Be careful here...</p>
        <div className="mt-2 flex gap-2">
          <Link
            className={cn(buttonVariants({ variant: 'outline' }))}
            href={routes.usersSessions.destroy.path({ id: user.id })}
            method="delete"
          >
            {i18n.t('sign_out')}
          </Link>
          <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
              <Button
                variant="outline"
                className="text-destructive hover:text-destructive"
              >
                {i18n.t('devise.registrations.edit.cancel_my_account')}
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>
                  {i18n.t('devise.registrations.edit.are_you_sure')}
                </DialogTitle>
                <DialogDescription>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: i18n.t(
                        'devise.registrations.edit.cancel_my_account_confirm_description_html'
                      ),
                    }}
                  />
                </DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="confirm-email" className="text-right">
                    Email
                  </Label>
                  <Input
                    id="confirm-email"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    className="col-span-3"
                  />
                </div>
              </div>
              <DialogFooter>
                <Button
                  type="submit"
                  onClick={handleDeleteAccount}
                  disabled={confirmEmail !== user.email}
                  variant="destructive"
                >
                  {i18n.t('devise.registrations.edit.cancel_my_account')}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  )
}

export default DeleteAccount
