// JsFromRoutes CacheKey 392d4759c52347aba3276ac7fed476ff
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/link_in_bio_templates'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/link_in_bio_templates'),
  new: /* #__PURE__ */ definePathHelper('get', '/admin/link_in_bio_templates/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/link_in_bio_templates/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/link_in_bio_templates/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/link_in_bio_templates/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/link_in_bio_templates/:id'),
}
