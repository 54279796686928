import { FaqList } from '@/components/shared/faq-list'
import Meta from '@/components/shared/meta'
import { Input } from '@/components/ui/input'
import { APP_NAME } from '@/constants'
import useTypedPage from '@/hooks/use-typed-page'
import { DollarSign } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const faqList = [
  {
    question: 'What is a Subscription Revenue Calculator?',
    answer:
      'A Subscription Revenue Calculator helps you estimate your expected monthly and annual revenue from subscriptions.',
  },
  {
    question: 'How do I calculate subscription revenue?',
    answer:
      'To calculate subscription revenue, input the number of subscribers and the subscription price. The calculator will determine the total monthly and annual revenue.',
  },
  {
    question: 'What is the number of subscribers?',
    answer:
      'The number of subscribers is the total count of people who have subscribed to your service.',
  },
  {
    question: 'What is the subscription price?',
    answer:
      'The subscription price is the amount charged to each subscriber on a monthly basis.',
  },
]

const SubscriptionRevenueCalculatorPage: React.FC = () => {
  const { name, description } = useTypedPage().props
  const [numberOfSubscribers, setNumberOfSubscribers] = useState<number>(100)
  const [subscriptionPrice, setSubscriptionPrice] = useState<number>(10) // USD
  const [monthlyRevenue, setMonthlyRevenue] = useState<string>('1000.00')
  const [annualRevenue, setAnnualRevenue] = useState<string>('12000.00')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const calculateRevenue = () => {
    const monthly = numberOfSubscribers * subscriptionPrice
    const annual = monthly * 12
    setMonthlyRevenue(formatter.format(monthly))
    setAnnualRevenue(formatter.format(annual))
  }

  useEffect(() => {
    calculateRevenue()
  }, [numberOfSubscribers, subscriptionPrice])

  const metaTitle = `${name} | ${APP_NAME}`
  const metaDescription = description as string

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container mx-auto max-w-5xl">
        <div className="overflow-hidden">
          <div className="mx-auto mb-6 max-w-3xl text-center">
            <h1 className="mb-5 font-heading text-5xl font-medium">
              Subscription Revenue Calculator
            </h1>
            <p className="text-neutral-500">
              Calculate your expected monthly and annual revenue from
              subscriptions.
            </p>
          </div>
          <div className="rounded-md border border-border bg-accent p-5">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
              <div className="col-span-1">
                <label
                  htmlFor="number_of_subscribers"
                  className="font-semibold"
                >
                  Number of Subscribers
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  How many subscribers do you have?
                </div>
                <Input
                  type="number"
                  id="number_of_subscribers"
                  value={numberOfSubscribers}
                  onChange={(e) =>
                    setNumberOfSubscribers(Number(e.target.value))
                  }
                  placeholder="Number of Subscribers"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="subscription_price" className="font-semibold">
                  Subscription Price (USD)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What is the price of your subscription?
                </div>
                <Input
                  type="number"
                  id="subscription_price"
                  value={subscriptionPrice}
                  onChange={(e) => setSubscriptionPrice(Number(e.target.value))}
                  placeholder="Subscription Price (USD)"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="mt-6 rounded-md border border-border bg-background px-4 py-5 sm:p-6">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <DollarSign className="mr-2 inline-block" />
                    Monthly Revenue (MRR):
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="monthly-revenue-value">{monthlyRevenue}</span>
                  </div>
                </div>
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <DollarSign className="mr-2 inline-block" />
                    Annual Revenue (ARR):
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="annual-revenue-value">{annualRevenue}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FaqList faqList={faqList} />
      </div>
    </>
  )
}

export default SubscriptionRevenueCalculatorPage
