import { FormError } from '@/components/shared/form-error'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { i18n } from '@/utils'
import { initialProductData } from '../new-product-dialog'
import { Product } from '@/types'

type NameStepProps = {
  productData: typeof initialProductData
  setProductData: React.Dispatch<
    React.SetStateAction<typeof initialProductData>
  >
  errors: { [K in keyof Product]?: string | null }
}

export default function NameStep({
  productData,
  setProductData,
  errors,
}: NameStepProps) {
  return (
    <div className="grid gap-4 py-4">
      <div className="grid grid-cols-4 items-center gap-4">
        <Label htmlFor="product-name" className="col-span-1 text-right">
          {i18n.t('products.new_product.name_step.product_name')}
        </Label>
        <Input
          id="product-name"
          placeholder="Product Name"
          className="col-span-3"
          onChange={(e) => {
            setProductData((currentData) => ({
              ...currentData,
              name: e.target.value,
            }))
          }}
          value={productData.name}
        />
        {errors.name && (
          <>
            <div className="col-span-1"></div>
            <FormError error={errors.name} className="col-span-3 mt-0" />
          </>
        )}
      </div>
    </div>
  )
}
