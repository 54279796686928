// JsFromRoutes CacheKey 29d3aa1ca0bacb730bfaeb84793f5589
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/admin/users/:user_id/impersonate/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/users/:user_id/impersonate/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/users/:user_id/impersonate'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/users/:user_id/impersonate'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/users/:user_id/impersonate'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/users/:user_id/impersonate'),
}
