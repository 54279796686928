// JsFromRoutes CacheKey 98ab42c5d8a89700e8304349d61c8887
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/content/courses/:course_prefix_id'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/content/courses/:course_prefix_id/:prefix_id/edit'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/content/courses/:course_prefix_id/:prefix_id'),
}
