// JsFromRoutes CacheKey d0491364bc2db2461a835844b05ef348
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/password/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/password/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/api/v1/password'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/api/v1/password'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/api/v1/password'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/api/v1/password'),
}
