import { FormProps, Form as InertiaForm } from '@/lib/use-inertia-form'

interface IFormProps<T extends Record<keyof T, unknown>> extends FormProps<T> {}

const Form = <T extends Record<keyof T, unknown>>({
  children,
  railsAttributes = true,
  ...props
}: IFormProps<T>) => {
  return (
    <div>
      {/* Using `railsAttributes` will append "_attributes" to the keys of nested data
          for use with `accepts_nested_attributes_for` in an ActiveRecord object */}
      <InertiaForm
        railsAttributes={railsAttributes}
        className="flex flex-col gap-3"
        {...props}
      >
        {children}
      </InertiaForm>
    </div>
  )
}

export default Form
