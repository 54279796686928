import TextBlockable from '@/types/blocks/text'
import { cn } from '@/utils'
import showdown from 'showdown'

const horizontalAlignmentClasses = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
}

const TextPreview = ({
  title,
  headline,
  background,
  horizontalAlignment,
  body,
}: TextBlockable) => {
  const converter = new showdown.Converter()
  const markdownBody = converter.makeHtml(body)

  return (
    <div className="block p-2">
      <div
        className={cn(
          'flex flex-col rounded border p-2 font-medium',
          horizontalAlignmentClasses[horizontalAlignment]
        )}
      >
        <div>
          title:
          <span>{title}</span>
        </div>
        <div>
          headline:
          <span>{headline}</span>
        </div>
        <div>
          is background:
          <span>{background}</span>
        </div>
        <div>
          body:
          <div dangerouslySetInnerHTML={{ __html: markdownBody }}></div>
        </div>
      </div>
    </div>
  )
}

export default TextPreview
