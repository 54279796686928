// JsFromRoutes CacheKey 5bd6827fb9a1f983ad9f16378458d1b5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/app/creator/account_invitations'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/account_invitations'),
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/account_invitations/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/account_invitations/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/account_invitations/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/account_invitations/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/account_invitations/:id'),
}
