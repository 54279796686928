import { FaqList } from '@/components/shared/faq-list'
import Meta from '@/components/shared/meta'
import { Input } from '@/components/ui/input'
import { APP_NAME } from '@/constants'
import useTypedPage from '@/hooks/use-typed-page'
import { DollarSign } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const faqList = [
  {
    question: 'What is a Content ROI Calculator?',
    answer:
      'A Content ROI Calculator helps you estimate the return on investment for creating specific content.',
  },
  {
    question: 'How do I calculate the ROI?',
    answer:
      'To calculate the ROI, input the time spent, cost of production, and earnings. The calculator will determine the ROI percentage.',
  },
  {
    question: 'What is the cost of production?',
    answer:
      'The cost of production is the total cost incurred to create the content.',
  },
  {
    question: 'What are the earnings?',
    answer: 'The earnings are the total revenue generated from the content.',
  },
]

const ContentROICalculatorPage: React.FC = () => {
  const { name, description } = useTypedPage().props
  const [timeSpent, setTimeSpent] = useState<number>(10) // hours
  const [costOfProduction, setCostOfProduction] = useState<number>(100) // USD
  const [earnings, setEarnings] = useState<number>(200) // USD
  const [roi, setRoi] = useState<string>('100.00%')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const calculateROI = () => {
    const roiValue = ((earnings - costOfProduction) / costOfProduction) * 100
    setRoi(formatter.format(roiValue / 100))
  }

  useEffect(() => {
    calculateROI()
  }, [timeSpent, costOfProduction, earnings])

  const metaTitle = `${name} | ${APP_NAME}`
  const metaDescription = description as string

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container mx-auto max-w-5xl">
        <div className="overflow-hidden">
          <div className="mx-auto mb-6 max-w-3xl text-center">
            <h1 className="mb-5 font-heading text-5xl font-medium">
              Content ROI Calculator
            </h1>
            <p className="text-neutral-500">
              Estimate the return on investment for creating specific content.
            </p>
          </div>
          <div className="rounded-md border border-border bg-accent p-5">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
              <div className="col-span-1">
                <label htmlFor="time_spent" className="font-semibold">
                  Time Spent (hours)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  How much time did you spend creating the content?
                </div>
                <Input
                  type="number"
                  id="time_spent"
                  value={timeSpent}
                  onChange={(e) => setTimeSpent(Number(e.target.value))}
                  placeholder="Time Spent (hours)"
                  className=""
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="cost_of_production" className="font-semibold">
                  Cost of Production (USD)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What is the cost of producing the content?
                </div>
                <Input
                  type="number"
                  id="cost_of_production"
                  value={costOfProduction}
                  onChange={(e) => setCostOfProduction(Number(e.target.value))}
                  placeholder="Cost of Production (USD)"
                  className=""
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="earnings" className="font-semibold">
                  Earnings (USD)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  How much did you earn from the content?
                </div>
                <Input
                  type="number"
                  id="earnings"
                  value={earnings}
                  onChange={(e) => setEarnings(Number(e.target.value))}
                  placeholder="Earnings (USD)"
                  className=""
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="mt-6 rounded-md border border-border bg-background px-4 py-5 sm:p-6">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <DollarSign className="inline-block" /> ROI Percentage:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="roi-value">{roi}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FaqList faqList={faqList} />
      </div>
    </>
  )
}

export default ContentROICalculatorPage
