import { Product } from '@/types'
import { format, isSameYear } from 'date-fns'
import dayjs from 'dayjs'

export function formatSize(bytes: number): string {
  const units = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte']

  const unitIndex = Math.max(
    0,
    Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), units.length - 1)
  )

  return Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: units[unitIndex],
  }).format(+Math.round(bytes / 1024 ** unitIndex))
}

type FormatAmountParams = {
  currency: string
  amount: number
  locale?: string
  maximumFractionDigits?: number
  minimumFractionDigits?: number
}

export function formatAmount({
  currency,
  amount,
  locale = 'en-US',
  minimumFractionDigits,
  maximumFractionDigits,
}: FormatAmountParams) {
  if (!currency) {
    return
  }

  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(amount)
}

export function secondsToHoursAndMinutes(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  if (hours) {
    return `${hours}h`
  }

  if (minutes) {
    return `${minutes}m`
  }

  return '0h'
}

export const formatDate = (date: number) =>
  dayjs(date).format('MMMM D, YYYY h:mm A')

export function formatTransactionDate(date: string) {
  if (isSameYear(new Date(), new Date(date))) {
    return format(new Date(date), 'MMM d')
  }

  return format(new Date(date), 'P')
}

export function getInitials(value: string) {
  const formatted = value.toUpperCase().replace(/[\s.-]/g, '')

  if (formatted.split(' ').length > 1) {
    return `${formatted.charAt(0)}${formatted.charAt(1)}`
  }

  if (value.length > 1) {
    return formatted.charAt(0) + formatted.charAt(1)
  }

  return formatted.charAt(0)
}

export function getLowestPrice(product: Product): number {
  if (!product.pricingOptions || product.pricingOptions.length === 0) {
    return 0
  }

  const prices = product.pricingOptions
    .map((option) => option.price)
    .filter((price): price is number => price !== undefined)

  if (prices.length === 0) {
    return 0
  }

  return Math.min(...prices)
}
