// JsFromRoutes CacheKey 7f135f6539892ab556d047d494487101
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/auth/reset-password/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/auth/reset-password/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/auth/reset-password'),
  create: /* #__PURE__ */ definePathHelper('post', '/auth/reset-password'),
}
