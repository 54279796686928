import Meta from '@/components/shared/meta'
import { buttonVariants } from '@/components/ui/button'
import { Link } from '@/components/ui/link'
import { APP_NAME, BUSINESS_EMAIL } from '@/constants'
import { cn } from '@/utils'
import { CircleDollarSign } from 'lucide-react'

export default function AffiliatesPage() {
  const affiliateCommission = 30
  const metaTitle = `Affiliate Program | ${APP_NAME}`
  const metaDescription = `Earn recurring ${affiliateCommission}% commissions by referring customers to ${APP_NAME}. No limits on earnings!`

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <section className="container mb-16 flex flex-col items-center justify-center">
        <div className="mb-5 flex justify-center">
          <CircleDollarSign className="h-10 w-10" />
        </div>
        <h1 className="mb-6 text-center font-heading text-4xl sm:text-5xl">
          {APP_NAME} Affiliate Program
        </h1>
        <p className="mb-6 text-center">
          Earn recurring{' '}
          <span className="font-semibold">
            {affiliateCommission}% commissions
          </span>{' '}
          by referring customers to {APP_NAME}. No limits on earnings!
        </p>
        <div className="flex justify-center">
          <Link
            className={cn(buttonVariants({ variant: 'default', size: 'lg' }))}
            href={`mailto:${BUSINESS_EMAIL}`}
          >
            Become a {APP_NAME} Affiliate
          </Link>
        </div>
      </section>

      <section className="container mb-10">
        <div className="rounded-3xl border border-border bg-accent px-4 py-12 sm:px-6 lg:px-8">
          <div className="mx-auto text-center">
            <h2 className="mb-6 text-3xl font-bold">
              Why Join the {APP_NAME} Affiliate Program?
            </h2>
            <div className="grid gap-6 md:grid-cols-3">
              <div className="p-6">
                <div className="mb-4 text-4xl">💸</div>
                <h3 className="mb-2 text-xl font-semibold">
                  {affiliateCommission}% Commission
                </h3>
                <p>
                  Earn recurring {affiliateCommission}% on all payments for each
                  client you refer.
                </p>
              </div>
              <div className="p-6">
                <div className="mb-4 text-4xl">♾️</div>
                <h3 className="mb-2 text-xl font-semibold">
                  Unlimited Earnings
                </h3>
                <p>
                  No cap on your earnings. The more you refer, the more you
                  earn!
                </p>
              </div>
              <div className="p-6">
                <div className="mb-4 text-4xl">📊</div>
                <h3 className="mb-2 text-xl font-semibold">
                  Real-Time Dashboard
                </h3>
                <p>
                  Track your performance and earnings in real-time with our
                  custom dashboard.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container mx-auto py-16 text-center">
        <h2 className="mb-6 text-3xl font-bold">Testimonials</h2>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {/* Testimonial 1 */}
          <div className="bg-base-100 rounded-xl border border-border p-6 shadow-sm">
            <p className="mb-4 italic">{`"${APP_NAME}'s affiliate program is amazing. The commissions are great and it's easy to promote!"`}</p>
            <p className="font-bold">Carmen Diaz</p>
          </div>
          {/* Testimonial 2 */}
          <div className="bg-base-100 rounded-xl border border-border p-6 shadow-sm">
            <p className="mb-4 italic">
              "I love being able to help my audience find the right tools while
              earning at the same time!"
            </p>
            <p className="font-bold">Jane Samuel</p>
          </div>
          {/* Testimonial 3 */}
          <div className="bg-base-100 rounded-xl border border-border p-6 shadow-sm">
            <p className="mb-4 italic">
              "Great conversion rates and recurring commissions, affiliate's
              dream!"
            </p>
            <p className="font-bold">Andy Cohen</p>
          </div>
        </div>
      </section>

      <section className="container pt-16">
        <div className="rounded-3xl border-2 border-primary bg-primary px-10 pb-8 pt-16">
          <div className="flex flex-col items-center text-center">
            <h2 className="mb-4 font-heading text-4xl text-background">
              Join the {APP_NAME} Affiliate Program
            </h2>
            <p className="mb-8 max-w-2xl text-xl text-background/60">
              Sign up now and start earning today!
            </p>
            <Link
              href={`mailto:${BUSINESS_EMAIL}`}
              className={cn(
                buttonVariants({ variant: 'secondary', size: 'lg' }),
                'text-lg font-semibold'
              )}
            >
              Become {APP_NAME}'s Affiliate
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}
