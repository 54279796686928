import EmptyState from '@/components/shared/empty-state'
import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { ContentSection } from '@/layouts/app/sections/content-section'
import routes from '@/routes'
import { formatAmount, i18n } from '@/utils'
import { DollarSignIcon, HandCoinsIcon } from 'lucide-react'

export default function PayoutsPage() {
  const breadcrumbs = [
    {
      title: i18n.t('application.breadcrumbs.home'),
      link: routes.dashboard.show.path(),
    },
    { title: i18n.t('payouts.breadcrumbs.payouts') },
  ]

  return (
    <ContentSection>
      <ContentSection.Header>
        <DefaultNavbar breadcrumbs={breadcrumbs} />
      </ContentSection.Header>
      <ContentSection.Body>
        <div className="mb-4 grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
          <Card className="bg-accent">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {i18n.t('payouts.cards.balance.title')}
              </CardTitle>
              <DollarSignIcon className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {formatAmount({
                  currency: 'USD',
                  amount: 953,
                  maximumFractionDigits: 0,
                })}
              </div>
            </CardContent>
          </Card>
          <Card className="bg-accent">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {i18n.t('payouts.cards.past_7_days.title')}
              </CardTitle>
              <DollarSignIcon className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="mb-1 text-2xl font-bold">
                {formatAmount({
                  currency: 'USD',
                  amount: 2535,
                  maximumFractionDigits: 0,
                })}
              </div>
              <Badge variant="uptrend">
                {i18n.t('payouts.cards.past_7_days.trend', { percentage: 180 })}
              </Badge>
            </CardContent>
          </Card>
          <Card className="bg-accent">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {i18n.t('payouts.cards.past_30_days.title')}
              </CardTitle>
              <DollarSignIcon className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="mb-1 text-2xl font-bold">
                {formatAmount({
                  currency: 'USD',
                  amount: 11234,
                  maximumFractionDigits: 0,
                })}
              </div>
              <Badge variant="uptrend">
                {i18n.t('payouts.cards.past_30_days.trend', { percentage: 19 })}
              </Badge>
            </CardContent>
          </Card>
          <Card className="bg-accent">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {i18n.t('payouts.cards.total_earnings.title')}
              </CardTitle>
              <DollarSignIcon className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {formatAmount({
                  currency: 'USD',
                  amount: 334573,
                  maximumFractionDigits: 0,
                })}
              </div>
            </CardContent>
          </Card>
        </div>
        <EmptyState
          icon={HandCoinsIcon}
          title={i18n.t('payouts.empty_state.title')}
          description={i18n.t('payouts.empty_state.description')}
          action={<Button disabled>Add Payment Method</Button>}
        />
      </ContentSection.Body>
    </ContentSection>
  )
}
