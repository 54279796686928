import PricingDetailsStep from '@/components/features/products/new-product/steps/pricing-details-step'
import PricingTypeStep from '@/components/features/products/new-product/steps/pricing-type-step'
import ReviewStep from '@/components/features/products/new-product/steps/review-step'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import routes from '@/routes'
import { i18n } from '@/utils'
import { deepTransform, toSnakeCase } from '@/utils/objects'
import { PlusIcon } from 'lucide-react'
import { useState, useEffect } from 'react'
import { router, usePage } from '@inertiajs/react'
import PricingOption from '@/types/generated/PricingOption'
import { Product } from '@/types'

const steps = [
  {
    title: i18n.t('products.new_pricing_option.steps.zero.title'),
    description: i18n.t('products.new_pricing_option.steps.zero.description'),
  },
  {
    title: i18n.t('products.new_pricing_option.steps.one.title'),
    description: i18n.t('products.new_pricing_option.steps.one.description'),
  },
  {
    title: i18n.t('products.new_pricing_option.steps.two.title'),
    description: i18n.t('products.new_pricing_option.steps.two.description'),
  },
]

type NewPricingOptionDialogProps = {
  product: Product
}

export default function NewPricingOptionDialog(
  props: NewPricingOptionDialogProps
) {
  const errors = usePage().props.errors ?? {}
  const pricingOptionErrors = (errors.pricingOption ?? {}) as {
    [K in keyof PricingOption]?: string | null
  }
  const [currentStep, setCurrentStep] = useState(0)
  const [pricingData, setPricingData] = useState({
    productId: props.product.id,
    type: 'paid',
    price: 0,
    name: '',
    waitlist: false,
    freeTrial: false,
    currency: 'USD',
  })

  useEffect(() => {
    if (!Object.keys(pricingOptionErrors).length) return

    const handledErrors = [
      'type',
      'name',
      'price',
      'currency',
      'waitlist',
      'freeTrial',
    ]
    const unhandledErrorExists =
      Object.keys(pricingOptionErrors).filter(
        (error) => !handledErrors.includes(error)
      ).length > 0
    if (pricingOptionErrors.type) {
      setCurrentStep(() => 0)
    } else if (
      pricingOptionErrors.name ||
      pricingOptionErrors.price ||
      pricingOptionErrors.currency ||
      pricingOptionErrors.waitlist ||
      pricingOptionErrors.freeTrial
    ) {
      setCurrentStep(() => 1)
    } else if (unhandledErrorExists) {
      router.visit(routes.products.index.path(), { preserveState: false })
      // toast({ title: "An error occurred while creating your product", description: "Please try again" })
    }
  }, [errors])

  const handleNext = () => setCurrentStep((prevStep) => prevStep + 1)
  const handleBack = () => setCurrentStep((prevStep) => prevStep - 1)

  const setPricingAttribute = (property: string, value: any) => {
    setPricingData((currentData) => {
      return {
        ...currentData,
        [property]: value,
      }
    })
  }

  const handleSubmit = () => {
    router.post(
      routes.pricingOptions.create.path(),
      { pricing_option: deepTransform(pricingData, toSnakeCase) },
      { preserveState: 'errors' }
    )
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>
          <PlusIcon className="mr-2 h-4 w-4" />
          {i18n.t('products.new_pricing_option.trigger')}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]" showCloseButton={false}>
        <div className="relative h-1 w-full bg-gray-200">
          <div
            className="absolute left-0 top-0 h-1 bg-blue-500"
            style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
          />
        </div>
        <DialogHeader>
          <DialogTitle className="text-center">
            {steps[currentStep].title}
          </DialogTitle>
          <DialogDescription className="text-center">
            {steps[currentStep].description}
          </DialogDescription>
        </DialogHeader>

        {currentStep === 0 ? (
          <PricingTypeStep
            pricingData={pricingData}
            setPricingData={setPricingAttribute}
            errors={pricingOptionErrors}
          />
        ) : currentStep === 1 ? (
          <PricingDetailsStep
            pricingData={pricingData}
            setPricingData={setPricingAttribute}
            errors={pricingOptionErrors}
          />
        ) : currentStep === 2 ? (
          <ReviewStep />
        ) : null}

        <DialogFooter>
          {currentStep > 0 && (
            <Button variant="outline" onClick={handleBack}>
              {i18n.t('back')}
            </Button>
          )}
          {currentStep < steps.length - 1 ? (
            <Button type="button" onClick={handleNext}>
              {i18n.t('continue')}
            </Button>
          ) : (
            <Button type="submit" onClick={handleSubmit}>
              {i18n.t('confirm')}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
