import { Link } from '@/components/ui/link'
import { APP_NAME } from '@/constants'
import React from 'react'

type LogoSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

interface AppLogoProps {
  color?: string
  size?: LogoSize
  url?: string
}

const logoSizes = {
  xs: '12',
  sm: '16',
  md: '20',
  lg: '24',
  xl: '28',
  '2xl': '48',
}

const textSizes = {
  xs: 'text-sm',
  sm: 'text-lg',
  md: 'text-xl',
  lg: 'text-2xl',
  xl: 'text-3xl',
  '2xl': 'text-5xl',
}

export const Logo: React.FC<{ size?: LogoSize; color?: string }> = ({
  size = 'md',
  color = 'primary',
}) => {
  const dimensions = logoSizes[size]
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={dimensions}
      height={dimensions}
      viewBox="0 0 900.000000 897.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        className={`fill-${color}`}
        transform="translate(0.000000,897.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M4405 8961 c11 -19 -32 -28 -159 -34 -71 -3 -134 -11 -139 -16 -6 -6
-31 -11 -57 -11 -25 0 -54 -7 -64 -15 -11 -8 -27 -15 -37 -15 -26 0 -183 -54
-226 -78 -12 -7 -31 -12 -42 -12 -11 0 -22 -4 -25 -9 -6 -10 -87 -51 -99 -51
-4 0 -26 -13 -49 -30 -22 -16 -47 -30 -55 -30 -7 0 -16 -7 -19 -15 -4 -8 -13
-15 -22 -15 -8 0 -22 -7 -31 -16 -9 -8 -41 -33 -71 -56 -88 -65 -174 -142
-353 -318 -427 -418 -2467 -2458 -2467 -2466 0 -5 -16 -26 -35 -46 -37 -38
-105 -125 -105 -132 0 -3 -18 -31 -40 -64 -21 -33 -58 -95 -80 -138 -23 -44
-45 -86 -50 -94 -4 -8 -16 -35 -25 -60 -9 -25 -23 -58 -29 -75 -23 -54 -46
-130 -46 -151 0 -11 -7 -29 -15 -40 -8 -10 -15 -34 -15 -52 0 -18 -4 -41 -9
-51 -8 -15 -21 -133 -38 -371 -3 -36 0 -101 6 -145 6 -44 15 -125 21 -180 15
-146 21 -178 36 -200 8 -11 14 -31 14 -45 0 -14 7 -38 15 -54 8 -15 15 -36 15
-46 0 -10 6 -31 14 -47 8 -15 22 -48 31 -73 9 -25 21 -52 25 -60 5 -8 27 -50
50 -94 22 -43 59 -105 80 -138 22 -33 40 -62 40 -64 0 -7 58 -80 102 -128 21
-23 38 -45 38 -50 0 -8 2063 -2071 2466 -2466 165 -161 295 -280 339 -310 66
-44 81 -56 88 -67 4 -7 16 -13 26 -13 11 0 21 -7 25 -15 3 -8 12 -15 19 -15 8
0 33 -13 55 -30 23 -16 45 -30 49 -30 12 0 93 -41 99 -51 3 -5 14 -9 24 -9 10
0 31 -6 47 -14 80 -39 188 -76 222 -76 10 0 26 -7 37 -15 10 -8 39 -15 64 -15
26 0 50 -4 56 -10 12 -12 259 -34 384 -34 125 0 372 22 384 34 6 6 30 10 56
10 25 0 54 7 64 15 11 8 27 15 37 15 34 0 142 37 222 76 16 8 37 14 47 14 10
0 21 4 24 9 6 10 87 51 99 51 4 0 26 14 49 30 22 17 47 30 55 30 7 0 16 7 19
15 4 8 14 15 25 15 10 0 22 6 26 13 7 11 25 25 89 68 13 8 43 33 66 55 23 21
60 54 83 72 22 19 125 118 228 220 178 176 187 187 176 209 -7 13 -17 23 -23
23 -19 0 -292 192 -321 226 -5 6 -15 13 -22 15 -44 14 -393 363 -442 443 -13
20 -26 36 -29 36 -4 0 -22 23 -40 50 -18 28 -37 50 -42 50 -5 0 -12 11 -16 25
-3 14 -10 25 -15 25 -5 0 -13 8 -16 18 -4 9 -26 44 -50 77 -24 32 -43 65 -43
72 0 7 -4 13 -9 13 -5 0 -19 19 -31 42 -12 24 -35 62 -51 86 -16 24 -29 47
-29 51 0 4 -11 28 -25 52 -14 24 -28 51 -31 59 -23 55 -47 101 -55 106 -5 3
-9 14 -9 24 0 10 -8 33 -17 52 -9 18 -22 45 -30 60 -7 14 -13 34 -13 45 0 10
-7 27 -15 37 -8 11 -15 30 -15 42 0 12 -6 27 -14 33 -8 6 -16 24 -19 39 -20
94 -37 156 -46 165 -6 6 -11 23 -11 37 0 14 -4 30 -10 36 -5 5 -13 38 -16 72
-4 34 -13 67 -20 73 -8 6 -14 29 -14 50 0 21 -4 50 -10 64 -5 14 -13 59 -16
98 -3 40 -12 88 -19 107 -7 19 -16 67 -19 105 -4 39 -10 106 -16 150 -5 44 -9
152 -10 240 0 124 -4 164 -15 179 -21 28 -21 294 0 322 11 15 14 53 15 169 0
83 5 193 11 245 5 52 13 132 18 178 5 46 13 88 20 95 6 6 11 34 11 62 0 28 7
77 15 108 8 32 15 68 15 80 0 13 6 31 14 42 7 11 16 40 18 65 6 54 26 137 41
165 6 11 14 36 18 55 8 40 26 94 44 130 7 14 15 40 18 58 4 18 18 56 32 86 14
29 25 58 25 64 0 7 13 38 29 70 16 31 40 80 54 107 42 87 118 229 138 257 10
14 19 28 19 31 0 3 20 35 45 71 25 37 45 69 45 72 0 10 178 241 223 290 26 27
47 53 47 58 0 13 215 229 285 285 33 27 92 74 130 104 39 30 86 69 106 86 20
17 39 31 42 31 3 0 34 19 69 42 65 43 120 76 173 103 17 9 44 24 60 35 17 10
49 29 73 41 23 13 42 26 42 30 0 9 -601 605 -655 651 -22 18 -56 47 -75 65
-19 17 -60 49 -90 71 -30 23 -62 48 -71 56 -9 9 -23 16 -31 16 -9 0 -18 7 -22
15 -3 8 -12 15 -19 15 -8 0 -33 14 -55 30 -23 17 -45 30 -49 30 -12 0 -93 41
-99 51 -3 5 -14 9 -25 9 -11 0 -30 5 -42 12 -43 24 -200 78 -226 78 -10 0 -26
7 -37 15 -10 8 -39 15 -65 15 -25 0 -50 5 -56 11 -6 6 -70 13 -143 16 -111 5
-136 9 -152 25 -14 14 -34 18 -99 18 -48 0 -78 -4 -74 -9z"
        />
        <path
          d="M7878 6293 c-25 -2 -52 -11 -58 -19 -7 -7 -24 -14 -37 -14 -64 0
-263 -88 -401 -178 -98 -64 -272 -235 -340 -334 -70 -103 -80 -119 -105 -170
-13 -26 -28 -53 -34 -60 -6 -7 -20 -39 -31 -70 -12 -32 -26 -66 -30 -75 -13
-25 -32 -81 -42 -122 -5 -18 -13 -38 -19 -44 -6 -6 -11 -24 -11 -40 0 -15 -4
-37 -10 -47 -5 -10 -13 -44 -16 -76 -4 -33 -12 -66 -19 -74 -7 -8 -15 -46 -18
-85 -4 -38 -11 -106 -17 -150 -14 -103 -14 -786 0 -890 6 -44 13 -109 16 -145
3 -36 12 -78 19 -95 7 -16 18 -57 23 -90 15 -88 22 -117 38 -152 8 -17 14 -40
14 -50 0 -11 7 -28 15 -39 8 -10 15 -31 15 -45 0 -15 7 -32 15 -39 8 -7 15
-18 15 -26 0 -13 25 -76 41 -104 5 -8 13 -24 18 -35 5 -11 21 -37 35 -58 14
-21 26 -40 26 -43 0 -36 274 -354 305 -354 6 0 22 -11 34 -24 20 -21 47 -39
161 -106 8 -5 35 -19 60 -30 25 -12 57 -28 72 -36 14 -8 36 -14 48 -14 15 0
142 120 426 404 222 222 404 407 404 412 0 5 17 27 38 50 44 48 102 121 102
128 0 2 18 31 40 64 21 33 58 95 80 138 23 44 45 86 50 94 4 8 16 35 25 60 9
25 23 59 29 75 23 54 46 130 46 150 0 11 6 29 14 40 7 11 16 38 19 60 4 22 11
67 16 100 5 33 10 92 11 130 2 81 12 121 28 117 9 -1 12 26 12 93 0 67 -3 94
-12 93 -16 -4 -26 36 -28 117 -1 39 -6 97 -11 130 -5 33 -12 78 -16 100 -3 22
-12 49 -19 60 -8 11 -14 29 -14 40 0 20 -23 96 -46 150 -6 17 -20 50 -29 75
-9 25 -21 52 -25 60 -5 8 -27 50 -50 94 -22 43 -59 105 -80 138 -22 33 -40 62
-40 64 0 7 -58 80 -102 128 -21 23 -38 45 -38 50 0 5 -117 126 -259 268 -202
201 -264 258 -283 256 -12 -1 -44 -3 -70 -5z"
        />
      </g>
    </svg>
  )
}

export const AppLogo: React.FC<AppLogoProps> = ({
  size = 'md',
  color = 'primary',
  url,
}) => {
  const LogoContent = (
    <>
      <Logo size={size} color={color} />
      <span className="sr-only">{APP_NAME}</span>
    </>
  )

  return url ? (
    <Link href={url} className="flex">
      {LogoContent}
    </Link>
  ) : (
    <div className="flex">{LogoContent}</div>
  )
}

interface AppLogotypeProps {
  size?: LogoSize
  url?: string
  color?: string
}

export const AppLogotype: React.FC<AppLogotypeProps> = ({
  size = 'md',
  url,
  color = 'primary',
}) => {
  const LogotypeContent = (
    <div className={`flex items-center font-heading ${textSizes[size]} gap-2`}>
      <Logo size={size} color={color} />
      {APP_NAME.toLowerCase()}
      <span className={`sr-only text-${color}`}>{APP_NAME}</span>
    </div>
  )

  return url ? (
    <Link href={url} className="flex items-center">
      {LogotypeContent}
    </Link>
  ) : (
    <div className="flex items-center">{LogotypeContent}</div>
  )
}
