import { AppLogotype } from '@/components/shared/app/app-logo'
import {
  InstagramIcon,
  LinkedInIcon,
  TikTokIcon,
  XIcon,
} from '@/components/shared/social-icons'
import { Link } from '@/components/ui/link'
import { Separator } from '@/components/ui/separator'
import { APP_NAME, SOCIAL_LINKS, SUPPORT_EMAIL } from '@/constants'
import routes from '@/routes'
import { ReactNode } from 'react'

interface LinkProps {
  href: string
  children: ReactNode
  className?: string
  target?: string
  rel?: string
}

const FooterLink: React.FC<LinkProps> = ({
  href,
  children,
  className = 'text-muted-foreground hover:text-primary',
  ...props
}) => (
  <Link href={href} className={className} {...props}>
    {children}
  </Link>
)

interface SocialLinkProps extends LinkProps {
  Icon: React.ElementType
}

const SocialLink: React.FC<SocialLinkProps> = ({ href, Icon, children }) => (
  <FooterLink
    href={href}
    className="group flex items-center text-muted-foreground hover:text-primary"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Icon className="mr-2.5 h-4 w-4 fill-muted-foreground group-hover:fill-primary" />
    {children}
  </FooterLink>
)

interface FooterSectionProps {
  title: string
  children: ReactNode
}

const FooterSection: React.FC<FooterSectionProps> = ({ title, children }) => (
  <div className="flex flex-col gap-2">
    <h3 className="font-heading text-lg">{title}</h3>
    {children}
  </div>
)

interface FooterLink {
  label: string
  href: string
  Icon?: React.ElementType
}

const footerSections = [
  {
    title: 'Product',
    links: [
      {
        label: 'Home',
        href: routes.websiteStatic.index.path(),
      },
      {
        label: 'Pricing',
        href: routes.websiteStatic.pricing.path(),
      },
      {
        label: 'Free Tools',
        href: routes.websiteTools.index.path(),
      },
      {
        label: 'Sign in',
        href: routes.usersSessions.new.path(),
      },
    ],
  },
  {
    title: 'Company',
    links: [
      {
        label: 'Story',
        href: routes.websiteStatic.story.path(),
      },
      {
        label: 'Contact Us',
        href: routes.websiteStatic.talkToUs.path(),
      },
      {
        label: 'Support',
        href: `mailto:${SUPPORT_EMAIL}`,
      },
      // TODO: Uncomment when pricing and affiliates program is ready
      // {
      //   label: 'Affiliates',
      //   href: routes.websiteStatic.affiliates.path(),
      // },
    ],
  },
  {
    title: 'Socials',
    links: [
      {
        label: 'Instagram',
        href: SOCIAL_LINKS.Instagram,
        Icon: InstagramIcon,
      },
      {
        label: 'TikTok',
        href: SOCIAL_LINKS.TikTok,
        Icon: TikTokIcon,
      },
      {
        label: 'X (Twitter)',
        href: SOCIAL_LINKS.X,
        Icon: XIcon,
      },
      {
        label: 'LinkedIn',
        href: SOCIAL_LINKS.LinkedIn,
        Icon: LinkedInIcon,
      },
    ],
  },
]

export const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer id="footer" className="container max-w-7xl pb-12">
      <div className="rounded-3xl border border-border bg-accent px-10 pb-6 pt-10 text-primary">
        <div className="grid grid-cols-2 gap-x-6 gap-y-8 md:grid-cols-3 lg:gap-x-12 xl:grid-cols-5">
          <div className="col-span-full xl:col-span-2">
            <AppLogotype size="lg" url={routes.websiteStatic.index.path()} />
            <div className="mt-2 flex items-center text-muted-foreground">
              <span>Helping Creators Monetize Their Creativity</span>
            </div>
          </div>

          {footerSections.map((section) => (
            <FooterSection key={section.title} title={section.title}>
              {section.links.map((link: FooterLink) =>
                link.Icon ? (
                  <div className="flex">
                    <SocialLink
                      key={link.label}
                      href={link.href}
                      Icon={link.Icon}
                    >
                      {link.label}
                    </SocialLink>
                  </div>
                ) : (
                  <div className="flex">
                    <FooterLink key={link.label} href={link.href}>
                      {link.label}
                    </FooterLink>
                  </div>
                )
              )}
            </FooterSection>
          ))}
        </div>

        <Separator className="my-6" />
        <section className="flex flex-col justify-between gap-2 text-sm text-muted-foreground md:flex-row">
          <h3>
            &copy; {currentYear} {APP_NAME}. All rights reserved.
          </h3>
          <div className="flex flex-row gap-2">
            <FooterLink
              href={routes.websiteStatic.privacy.path()}
              target="_blank"
            >
              Privacy Policy
            </FooterLink>
            |
            <FooterLink
              href={routes.websiteStatic.terms.path()}
              target="_blank"
            >
              Terms of Service
            </FooterLink>
          </div>
        </section>
      </div>
    </footer>
  )
}
