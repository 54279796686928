// JsFromRoutes CacheKey 6dbaf27eeca7b389d1cab15d9bf5ea2c
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  leaderboard: /* #__PURE__ */ definePathHelper('get', '/app/creator/leaderboard'),
  plans: /* #__PURE__ */ definePathHelper('get', '/app/creator/plans'),
  members: /* #__PURE__ */ definePathHelper('get', '/app/creator/members'),
  payouts: /* #__PURE__ */ definePathHelper('get', '/app/creator/payouts'),
  email: /* #__PURE__ */ definePathHelper('get', '/app/creator/email'),
  checkout: /* #__PURE__ */ definePathHelper('get', '/app/creator/checkout'),
  integrations: /* #__PURE__ */ definePathHelper('get', '/app/creator/integrations'),
}
