import LayoutWrapper from '@/components/shared/layout-wrapper'
import React from 'react'

export interface FullScreenLayoutProps {
  children: React.ReactNode
}

const FullScreenLayout: React.FC<FullScreenLayoutProps> = ({ children }) => {
  return (
    <>
      <LayoutWrapper>
        <main className="min-h-screen bg-background">{children}</main>
      </LayoutWrapper>
    </>
  )
}

export default FullScreenLayout
