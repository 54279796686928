import { InertiaSharedProps } from '@/types'
import { Page, PageProps } from '@inertiajs/core'
import { usePage } from '@inertiajs/react'

export default function useTypedPage<T extends PageProps = PageProps>(): Page<
  T & InertiaSharedProps
> {
  const page = usePage()
  return page as Page<T & InertiaSharedProps>
}
