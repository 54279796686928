// JsFromRoutes CacheKey 0aa1a62b705c3b0e031a1f84cddcd63b
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  backupCodes: /* #__PURE__ */ definePathHelper('get', '/user/two_factor/backup_codes'),
  verify: /* #__PURE__ */ definePathHelper('get', '/user/two_factor/verify'),
  new: /* #__PURE__ */ definePathHelper('get', '/user/two_factor/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/user/two_factor/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/user/two_factor'),
  update: /* #__PURE__ */ definePathHelper('patch', '/user/two_factor'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/user/two_factor'),
  create: /* #__PURE__ */ definePathHelper('post', '/user/two_factor'),
}
