// JsFromRoutes CacheKey 4eb75f95eafef77a17a0bf94bb2dccc4
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  invoice: /* #__PURE__ */ definePathHelper('get', '/app/creator/charges/:id/invoice'),
  index: /* #__PURE__ */ definePathHelper('get', '/app/creator/charges'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/charges'),
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/charges/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/charges/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/charges/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/charges/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/charges/:id'),
}
