import ChangePasswordForm from '@/components/features/auth/forms/change-password-form'
import Meta from '@/components/shared/meta'
import { Link } from '@/components/ui/link'
import { APP_NAME } from '@/constants'
import AuthLayout from '@/layouts/auth-layout'
import routes from '@/routes'
import { ReactNode } from 'react'

interface ChangePasswordPageProps {
  resetPasswordToken: string
}

const ChangePasswordPage = ({
  resetPasswordToken,
}: ChangePasswordPageProps) => {
  const metaTitle = `Change password | ${APP_NAME}`
  const metaDescription = `Change your password for ${APP_NAME}`

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="mb-4 flex flex-col space-y-2">
        <h1 className="text-center text-xl font-semibold tracking-tight">
          Change your password
        </h1>
      </div>

      <ChangePasswordForm resetPasswordToken={resetPasswordToken} />

      <p className="mt-4 border-t border-border pt-5 text-center text-sm text-muted-foreground">
        <Link
          href={routes.usersConfirmations.new.path()}
          className="underline underline-offset-4 hover:text-primary"
        >
          Didn't receive confirmation instructions?
        </Link>
      </p>
    </>
  )
}

ChangePasswordPage.layout = (page: ReactNode) => <AuthLayout>{page}</AuthLayout>

export default ChangePasswordPage
