import { FaqList } from '@/components/shared/faq-list'
import Meta from '@/components/shared/meta'
import { Input } from '@/components/ui/input'
import { APP_NAME } from '@/constants'
import useTypedPage from '@/hooks/use-typed-page'
import { DollarSign } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const faqList = [
  {
    question: 'What is a Sales Tax Calculator?',
    answer:
      'A Sales Tax Calculator helps you calculate the total price including tax based on the customer’s location.',
  },
  {
    question: 'How do I calculate sales tax?',
    answer:
      'To calculate sales tax, input the product price and the customer’s location. The calculator will determine the total price including tax and provide a tax breakdown.',
  },
  {
    question: 'What is the product price?',
    answer:
      'The product price is the amount charged for the product before tax.',
  },
  {
    question: 'What is the customer’s location?',
    answer:
      'The customer’s location is used to determine the applicable sales tax rate.',
  },
]

const SalesTaxCalculatorPage: React.FC = () => {
  const { name, description } = useTypedPage().props
  const [productPrice, setProductPrice] = useState<number>(100) // USD
  const [customerLocation, setCustomerLocation] = useState<string>('CA') // Default to California
  const [salesTax, setSalesTax] = useState<string>('8.00') // Example tax rate
  const [totalPrice, setTotalPrice] = useState<string>('108.00')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const taxRates: { [key: string]: number } = {
    CA: 0.0825, // 8.25% for California
    NY: 0.08875, // 8.875% for New York
    TX: 0.0625, // 6.25% for Texas
    FL: 0.06, // 6% for Florida
    IL: 0.0875, // 8.75% for Illinois
    PA: 0.06, // 6% for Pennsylvania
    OH: 0.0725, // 7.25% for Ohio
    GA: 0.04, // 4% for Georgia
    IN: 0.07, // 7% for Indiana
    MO: 0.04225, // 4.225% for Missouri
    OK: 0.045, // 4.5% for Oklahoma
    WA: 0.065, // 6.5% for Washington
    NV: 0.0841, // 8.41% for Nevada
    UT: 0.0485, // 4.85% for Utah
    AZ: 0.056, // 5.6% for Arizona
    ID: 0.06, // 6% for Idaho
    WY: 0.04, // 4% for Wyoming
    DC: 0.06, // 6% for District of Columbia
    AL: 0.04, // 4% for Alabama
    KS: 0.065, // 6.5% for Kansas
    KY: 0.06, // 6% for Kentucky
    LA: 0.0445, // 4.45% for Louisiana
    MN: 0.06875, // 6.875% for Minnesota
    MS: 0.07, // 7% for Mississippi
    NC: 0.0475, // 4.75% for North Carolina
    SD: 0.045, // 4.5% for South Dakota
    TN: 0.0975, // 9.75% for Tennessee
    VA: 0.053, // 5.3% for Virginia
    WV: 0.06, // 6% for West Virginia
    WI: 0.05, // 5% for Wisconsin

    // Add more states as needed
  }

  const calculateTotalPrice = () => {
    const taxRate = taxRates[customerLocation] || 0
    const taxAmount = productPrice * taxRate
    const total = productPrice + taxAmount
    setSalesTax(formatter.format(taxAmount))
    setTotalPrice(formatter.format(total))
  }

  useEffect(() => {
    calculateTotalPrice()
  }, [productPrice, customerLocation])

  const metaTitle = `${name} | ${APP_NAME}`
  const metaDescription = description as string

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container mx-auto max-w-5xl">
        <div className="overflow-hidden">
          <div className="mx-auto mb-6 max-w-3xl text-center">
            <h1 className="mb-5 font-heading text-5xl font-medium">
              Sales Tax Calculator
            </h1>
            <p className="text-neutral-500">
              Calculate the total price including tax based on the customer’s
              location.
            </p>
          </div>
          <div className="rounded-md border border-border bg-accent p-5">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
              <div className="col-span-1">
                <label htmlFor="product_price" className="font-semibold">
                  Product Price (USD)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What is the price of the product?
                </div>
                <Input
                  type="number"
                  id="product_price"
                  value={productPrice}
                  onChange={(e) => setProductPrice(Number(e.target.value))}
                  placeholder="Product Price (USD)"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="customer_location" className="font-semibold">
                  Customer’s Location
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  Where is the customer located?
                </div>
                <Input
                  type="text"
                  id="customer_location"
                  value={customerLocation}
                  onChange={(e) => setCustomerLocation(e.target.value)}
                  placeholder="Customer’s Location (e.g., CA)"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="mt-6 rounded-md border border-border bg-background px-4 py-5 sm:p-6">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <DollarSign className="mr-2 inline-block" />
                    Sales Tax:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <div className="flex flex-col" id="sales-tax-value">
                      {salesTax}
                      <span className="mt-2 text-sm text-muted-foreground">
                        {taxRates[customerLocation]
                          ? `(${(taxRates[customerLocation] * 100).toFixed(2)}%)`
                          : 'N/A'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <DollarSign className="mr-2 inline-block" />
                    Total Price:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="total-price-value">{totalPrice}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FaqList faqList={faqList} />
      </div>
    </>
  )
}

export default SalesTaxCalculatorPage
