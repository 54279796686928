import PageVisibilityForm from '@/components/features/settings/link-in-bio/page-visibility-form'
import TrackingCodesForm from '@/components/features/settings/tracking-codes/tracking-codes-form'
import Meta from '@/components/shared/meta'
import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { Separator } from '@/components/ui/separator'
import { ContentSection } from '@/layouts/app/sections/content-section'
import { SettingsSection } from '@/layouts/app/sections/settings-section'
import routes from '@/routes'
import { LinkInBioPageSettings, TrackingCodes } from '@/types'
import { i18n } from '@/utils'

interface TrackingCodeEditProps {
  linkInBioPageSettings: LinkInBioPageSettings
  trackingCodes: TrackingCodes
}

export default function TrackingCodeEdit({
  linkInBioPageSettings,
  trackingCodes,
}: TrackingCodeEditProps) {
  const breadcrumbs = [
    {
      title: i18n.t('application.breadcrumbs.home'),
      link: routes.dashboard.show.path(),
    },
    { title: 'Settings', link: routes.settingsProfile.index.path() },
    { title: 'Advanced' },
  ]

  return (
    <>
      <Meta title="Advanced Settings" />
      <ContentSection>
        <ContentSection.Header>
          <DefaultNavbar breadcrumbs={breadcrumbs} />
        </ContentSection.Header>
        <ContentSection.Body>
          <SettingsSection
            title="Advanced"
            desc="Manage your advanced settings, add tracking codes, and more."
          >
            <h2 className="mb-4 font-semibold text-muted-foreground">
              Tracking Codes
            </h2>
            <TrackingCodesForm trackingCodes={trackingCodes} />
            <Separator className="my-8" />
            <h2 className="mb-4 font-semibold text-muted-foreground">
              Link In Bio Visibility
            </h2>
            <PageVisibilityForm linkInBioPageSettings={linkInBioPageSettings} />
          </SettingsSection>
        </ContentSection.Body>
      </ContentSection>
    </>
  )
}
