import React from 'react'

interface FormErrorProps {
  name?: string
  error?: string | string[]
  className?: string
}

export const FormError = ({ name, error }: FormErrorProps): React.ReactNode => {
  if (!error) return null

  const errorMessage = Array.isArray(error) ? error.join(', ') : error

  return (
    <p className="mt-2 text-xs text-red-500">
      {name ? `${name} ${errorMessage}` : errorMessage}
    </p>
  )
}
