import { Link } from '@/components/ui/link'
import useTypedPage from '@/hooks/use-typed-page'
import routes from '@/routes'
import { cn } from '@/utils'
import { Blocks, Palette, Settings } from 'lucide-react'

interface Tab {
  name: string
  href: string
  icon: React.ReactNode
}

const tabs: Tab[] = [
  {
    name: 'Compose',
    href: routes.linkInBioBlockables.index.path(),
    icon: <Blocks className="mr-2 h-4 w-4" />,
  },
  {
    name: 'Design',
    href: routes.linkInBioLinkInBioTemplates.index.path(),
    icon: <Palette className="mr-2 h-4 w-4" />,
  },
  {
    name: 'Settings',
    href: '',
    icon: <Settings className="mr-2 h-4 w-4" />,
  },
]

export function KeyNavItems() {
  const { url } = useTypedPage()

  return (
    <div className="mb-6 inline-flex h-10 items-center justify-center rounded-xl bg-muted p-1 text-muted-foreground">
      <nav className="flex space-x-1" aria-label="Tabs">
        {tabs.map((tab) => (
          <Link
            key={tab.name}
            href={tab.href}
            className={cn(
              'inline-flex items-center justify-center whitespace-nowrap rounded-lg px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
              tab.href === url
                ? 'bg-background text-foreground shadow-sm'
                : 'hover:bg-muted-hover hover:text-muted-foreground-hover'
            )}
          >
            {tab.icon}
            {tab.name}
          </Link>
        ))}
      </nav>
    </div>
  )
}
