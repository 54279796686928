// JsFromRoutes CacheKey cdd974129d1583c6800bf13c689a1a8c
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/'),
  affiliates: /* #__PURE__ */ definePathHelper('get', '/affiliates'),
  terms: /* #__PURE__ */ definePathHelper('get', '/terms'),
  privacy: /* #__PURE__ */ definePathHelper('get', '/privacy'),
  cookiePolicy: /* #__PURE__ */ definePathHelper('get', '/cookie_policy'),
  story: /* #__PURE__ */ definePathHelper('get', '/story'),
  pricing: /* #__PURE__ */ definePathHelper('get', '/pricing'),
  updates: /* #__PURE__ */ definePathHelper('get', '/updates'),
  updatesLoadMore: /* #__PURE__ */ definePathHelper('get', '/updates/load_more'),
  talkToUs: /* #__PURE__ */ definePathHelper('get', '/talk-to-us'),
  addToMailingList: /* #__PURE__ */ definePathHelper('post', '/add_to_mailing_list'),
}
