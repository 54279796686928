// JsFromRoutes CacheKey e40d4a4be475233f82adb6c51ae830a9
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/accounts/:account_id/transfer/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/accounts/:account_id/transfer/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/app/creator/accounts/:account_id/transfer'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/accounts/:account_id/transfer'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/app/creator/accounts/:account_id/transfer'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/accounts/:account_id/transfer'),
}
