import DeleteAccount from '@/components/features/settings/profile/delete-account'
import ProfileForm from '@/components/features/settings/profile/profile-form'
import Meta from '@/components/shared/meta'
import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { Separator } from '@/components/ui/separator'
import { ContentSection } from '@/layouts/app/sections/content-section'
import { SettingsSection } from '@/layouts/app/sections/settings-section'
import routes from '@/routes'
import { settingsProfile } from '@/routes/generated'
import { User } from '@/types'
import { i18n } from '@/utils'

interface SettingsProfilePageProps {
  currentUser: User
}

const breadcrumbs = [
  {
    title: i18n.t('application.breadcrumbs.home'),
    link: routes.dashboard.show.path(),
  },
  { title: 'Settings', link: settingsProfile.index.path() },
  { title: 'Profile' },
]

export default function SettingsPageProfile({
  currentUser,
}: SettingsProfilePageProps) {
  if (!currentUser) return null

  return (
    <>
      <Meta title="Profile Settings" />
      <ContentSection>
        <ContentSection.Header>
          <DefaultNavbar breadcrumbs={breadcrumbs} />
        </ContentSection.Header>
        <ContentSection.Body>
          <SettingsSection
            title="Profile"
            desc="This is how others will see you on the site."
          >
            <ProfileForm user={currentUser} />
            <Separator className="my-6" />
            <DeleteAccount user={currentUser} />
          </SettingsSection>
        </ContentSection.Body>
      </ContentSection>
    </>
  )
}
