import { buttonVariants } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Link } from '@/components/ui/link'
import { APP_NAME, BUSINESS_EMAIL } from '@/constants'
import { cn } from '@/utils'
import { ChevronRightIcon } from 'lucide-react'

export function HelpCard() {
  return (
    <Card className="mt-6">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-0">
        <CardTitle className="text-lg font-medium">Have questions?</CardTitle>
      </CardHeader>
      <CardContent className="flex items-center justify-between gap-3">
        <div className="text-sm text-muted-foreground">
          We're here to help you sell your products! Please reach out to us if
          you have any questions.
        </div>
        <Link
          target="_blank"
          className={cn(buttonVariants({ variant: 'outline' }))}
          href={`mailto:${BUSINESS_EMAIL}?subject=Need some help with ${APP_NAME}`}
        >
          Get in touch
          <ChevronRightIcon className="ml-1 h-4 w-4" />
        </Link>
      </CardContent>
    </Card>
  )
}
