import { DefaultNavbar } from '@/components/shared/navbar/default-navbar'
import { Badge } from '@/components/ui/badge'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import useTypedPage from '@/hooks/use-typed-page'
import { ContentSection } from '@/layouts/app/sections/content-section'
import routes from '@/routes'
import { i18n } from '@/utils'
import { BarChart, DollarSign, Download, Trophy } from 'lucide-react'

const LeaderboardPage = () => {
  const { currentAccount } = useTypedPage().props
  const currentAccountPosition = 88 // This should be dynamically set based on the actual user's position
  const currentMonth = new Date().toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  })

  function getOrdinal(n: number): string {
    const s = ['th', 'st', 'nd', 'rd']
    const v = n % 100
    return s[(v - 20) % 10] || s[v] || s[0]
  }

  function formatNumber(num: number): string {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const breadcrumbs = [
    {
      title: i18n.t('application.breadcrumbs.home'),
      link: routes.dashboard.show.path(),
    },
    { title: i18n.t('leaderboard.breadcrumbs.leaderboard') },
  ]

  return (
    <ContentSection>
      <ContentSection.Header>
        <DefaultNavbar breadcrumbs={breadcrumbs} />
      </ContentSection.Header>
      <ContentSection.Body>
        <h2 className="mb-4 text-2xl font-bold tracking-tight">
          {i18n.t('leaderboard.title', { month: currentMonth })}
        </h2>
        <div className="mb-6 grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
          <Card className="bg-accent">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {i18n.t('leaderboard.cards.position.title')}
              </CardTitle>
              <Trophy className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {currentAccountPosition}
                <sup>{getOrdinal(currentAccountPosition)}</sup>
              </div>
            </CardContent>
          </Card>
          <Card className="bg-accent">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {i18n.t('leaderboard.cards.revenue.title')}
              </CardTitle>
              <DollarSign className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="mb-1 text-2xl font-bold">$12,293.00</div>
              <Badge variant="uptrend">
                {i18n.t('leaderboard.cards.revenue.trend', { percentage: 825 })}
              </Badge>
            </CardContent>
          </Card>
          <Card className="bg-accent">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {i18n.t('leaderboard.cards.position.title')}
              </CardTitle>
              <BarChart className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{currentAccountPosition}</div>
            </CardContent>
          </Card>
        </div>
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow className="text-left text-muted-foreground max-sm:text-sm">
                <TableHead className="pb-4">
                  {i18n.t('leaderboard.table.headers.position')}
                </TableHead>
                <TableHead className="pb-4 max-lg:hidden">
                  {i18n.t('leaderboard.table.headers.username')}
                </TableHead>
                <TableHead className="pb-4">
                  {i18n.t('leaderboard.table.headers.revenue')}
                </TableHead>
                <TableHead className="pb-4">
                  {i18n.t('leaderboard.table.headers.content_niche')}
                </TableHead>
                <TableHead className="pb-4">
                  {i18n.t('leaderboard.table.headers.primary_product_type')}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {[...Array(100)].map((_, index) => {
                const position = index + 1
                const isCurrentAccount = position === currentAccountPosition
                return (
                  <TableRow
                    key={position}
                    className={`border-t border-border ${isCurrentAccount ? 'bg-yellow-50' : ''}`}
                  >
                    <TableCell className="py-4 pl-4 font-semibold">
                      {position}
                      <sup>{getOrdinal(position)}</sup>
                    </TableCell>
                    <TableCell className="py-4 max-lg:hidden">
                      {isCurrentAccount ? (
                        <span className="font-semibold">
                          {currentAccount?.name}
                        </span>
                      ) : (
                        <div className="h-5 w-32 animate-pulse rounded-md bg-neutral-200"></div>
                      )}
                    </TableCell>
                    <TableCell className="py-4">
                      ${formatNumber(Math.floor(Math.random() * 41001) + 2000)}
                      .00
                    </TableCell>
                    <TableCell className="py-4">
                      <span className="text-nowrap rounded-md bg-accent p-2 py-1 text-xs font-semibold text-neutral-500">
                        {/* TODO: Replace with actual niche */}
                        {isCurrentAccount
                          ? currentAccount?.name
                          : 'Example Niche'}
                      </span>
                    </TableCell>
                    <TableCell className="py-4">
                      <div className="flex">
                        <span className="flex items-center gap-2 rounded-md bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-400">
                          <Download className="h-4 w-4 stroke-indigo-400" />
                          {/* TODO: Replace with actual product type */}
                          Downloadable
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
              {currentAccountPosition > 100 && (
                <>
                  <TableRow>
                    <TableCell colSpan={5} className="py-4 pl-4">
                      {i18n.t('leaderboard.more_entries', {
                        count: currentAccountPosition - 101,
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow className="border-t border-border bg-yellow-50">
                    <TableCell className="py-4 pl-4 font-semibold">
                      {currentAccountPosition}
                      <sup>{getOrdinal(currentAccountPosition)}</sup>
                    </TableCell>
                    <TableCell className="py-4 max-lg:hidden">
                      <span className="font-semibold">
                        {currentAccount?.name}
                      </span>
                    </TableCell>
                    <TableCell className="py-4">
                      ${formatNumber(Math.floor(Math.random() * 41001) + 2000)}
                      .00
                    </TableCell>
                    <TableCell className="py-4">
                      <span className="rounded-md bg-accent p-2 py-1 text-xs font-semibold text-neutral-500">
                        {/* TODO: Replace with actual niche */}
                        Your Niche
                      </span>
                    </TableCell>
                    <TableCell className="py-4">
                      <div className="flex">
                        <span className="flex items-center gap-2 rounded-md bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-400">
                          <Download className="h-4 w-4" />
                          {/* TODO: Replace with actual product type */}
                          Downloadable
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </div>
      </ContentSection.Body>
    </ContentSection>
  )
}

export default LeaderboardPage
