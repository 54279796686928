import { FormError } from '@/components/shared/inertia-form/form-error'
import { Label } from '@/components/ui/label'
import { NestedObject, useInertiaInput } from '@/lib/use-inertia-form'
import * as ReactSelect from '@radix-ui/react-select'
import { ChevronDown, ChevronUp } from 'lucide-react'
import FormSelectItem from './form-select-item'

export type Option = { value: string; label: string }

export interface IFormSelectProps
  extends Omit<ReactSelect.SelectProps, 'children'> {
  options: Option[]
  label?: string
  name: string
  model?: string
  placeholder?: string
}

const FormSelect = <TForm extends NestedObject = NestedObject>({
  options = [],
  label,
  required,
  name,
  model,
  placeholder,
}: IFormSelectProps) => {
  const { inputName, inputId, value, setValue, error } = useInertiaInput<
    string | number,
    TForm
  >({
    name,
    model,
  })

  // Remove the useMemo and use options directly
  const data = options

  const handleChange = (option: string | null) => {
    setValue(option || '')
  }

  return (
    <div className="flex w-full flex-col gap-2">
      {label && (
        <Label required={required} htmlFor={inputId}>
          {label}
        </Label>
      )}

      <ReactSelect.Root onValueChange={handleChange} value={String(value)}>
        <ReactSelect.Trigger
          id={inputId}
          name={inputName}
          className="flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-start text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1"
        >
          <ReactSelect.Value placeholder={placeholder} />
          <ReactSelect.Icon asChild>
            <ChevronDown className="h-4 w-4 opacity-50" />
          </ReactSelect.Icon>
        </ReactSelect.Trigger>
        <ReactSelect.Portal>
          <ReactSelect.Content
            position="popper"
            className="relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
          >
            <ReactSelect.ScrollUpButton className="flex cursor-default items-center justify-center py-1">
              <ChevronUp className="h-4 w-4" />
            </ReactSelect.ScrollUpButton>

            <ReactSelect.Viewport className="h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)] p-1">
              {data.map((option) => (
                <FormSelectItem key={option.value} option={option} />
              ))}
            </ReactSelect.Viewport>

            <ReactSelect.ScrollDownButton className="flex cursor-default items-center justify-center py-1">
              <ChevronDown className="h-4 w-4" />
            </ReactSelect.ScrollDownButton>
          </ReactSelect.Content>
        </ReactSelect.Portal>
      </ReactSelect.Root>
      <FormError name={name} error={error} />
    </div>
  )
}

export default FormSelect
