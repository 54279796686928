import WizardStep from '@/components/features/dashboard/wizard/wizard-step'
import useTypedPage from '@/hooks/use-typed-page'
import { InertiaSharedProps, User } from '@/types'
import { i18n } from '@/utils'

type DashboardWizardProps = InertiaSharedProps & {
  currentUser: User
  completedSteps: number
  totalSteps: number
  currentStep: number
  hasAccountAvatarAttached: boolean
  hasAnySocialLinks: boolean
  hasAnyContent: boolean
  hasAnyProducts: boolean
}

export function DashboardWizard() {
  const {
    currentUser,
    hasAccountAvatarAttached,
    completedSteps,
    totalSteps,
    currentStep,
    hasAnySocialLinks,
    hasAnyContent,
    hasAnyProducts,
  } = useTypedPage<DashboardWizardProps>().props

  const wizardSteps = [
    {
      title: i18n.t('dashboard.wizard_steps.profile_picture.title'),
      description: i18n.t('dashboard.wizard_steps.profile_picture.description'),
      buttonText: i18n.t('dashboard.wizard_steps.profile_picture.button'),
      buttonPath: '/edit',
      optional: false,
      completed: hasAccountAvatarAttached,
    },
    {
      title: i18n.t('dashboard.wizard_steps.social_links.title'),
      description: i18n.t('dashboard.wizard_steps.social_links.description'),
      buttonText: i18n.t('dashboard.wizard_steps.social_links.button'),
      buttonPath: '/edit',
      optional: false,
      completed: hasAnySocialLinks,
    },
    {
      title: i18n.t('dashboard.wizard_steps.content.title'),
      description: i18n.t('dashboard.wizard_steps.content.description'),
      buttonText: i18n.t('dashboard.wizard_steps.content.button'),
      buttonPath: '/edit',
      optional: false,
      completed: hasAnyContent,
    },
    {
      title: i18n.t('dashboard.wizard_steps.product.title'),
      description: i18n.t('dashboard.wizard_steps.product.description'),
      buttonText: i18n.t('dashboard.wizard_steps.product.button'),
      buttonPath: '/edit',
      optional: false,
      completed: hasAnyProducts,
    },
    {
      title: i18n.t('dashboard.wizard_steps.payment.title'),
      description: i18n.t('dashboard.wizard_steps.payment.description'),
      buttonText: i18n.t('dashboard.wizard_steps.payment.button'),
      buttonPath: '/edit',
      optional: false,
      completed: false,
    },
  ]

  return (
    <>
      <div className="mb-1 text-xl font-semibold">
        Welcome, {currentUser?.firstName} 👋
      </div>
      <span className="text-sm text-gray-500">{`${completedSteps} of ${totalSteps} tasks completed`}</span>

      <div className="mt-2 flex max-w-6xl flex-col">
        {wizardSteps.map((step, index) => (
          <WizardStep
            key={index}
            step={index + 1}
            currentStep={currentStep}
            lastStep={index === wizardSteps.length - 1}
            completed={step.completed}
            title={step.title}
            description={step.description}
            buttonText={step.buttonText}
            optional={step.optional}
          />
        ))}
      </div>
    </>
  )
}
