import { FormError } from '@/components/shared/inertia-form/form-error'
import FormRadioGroupItem from '@/components/shared/inertia-form/form-radio-group/form-radio-group-item'
import { Label } from '@/components/ui/label'
import {
  NestedObject,
  UseFormProps,
  useInertiaInput,
} from '@/lib/use-inertia-form'
import { cn } from '@/utils'
import * as ReactRadioGroup from '@radix-ui/react-radio-group'

export type Option = { value: string; label: string }

interface FormRadioGroupProps<TForm extends NestedObject = NestedObject> {
  label?: string
  name: string
  model?: string
  options: Option[]
  className?: string
  onChange?: (value: string, form: UseFormProps<TForm>) => void
}

const FormRadioGroup = <TForm extends NestedObject = NestedObject>({
  label,
  name,
  model,
  options,
  className,
  onChange,
}: FormRadioGroupProps<TForm>) => {
  const { form, inputName, inputId, value, setValue, error } = useInertiaInput<
    string,
    TForm
  >({ name, model })

  return (
    <div className="flex flex-col gap-2">
      {label && <Label htmlFor={inputId}>{label}</Label>}

      <ReactRadioGroup.Root
        className={cn('flex gap-2', className)}
        onValueChange={(newValue) => {
          if (onChange) onChange(newValue, form)
          setValue(newValue)
        }}
        value={value}
        name={inputName}
      >
        {options.map((option) => (
          <div key={option.value} className="flex items-center gap-1">
            <FormRadioGroupItem option={option} />
            <Label htmlFor={option.value}>{option.label}</Label>
          </div>
        ))}
      </ReactRadioGroup.Root>
      <FormError name={label || name} error={error} />
    </div>
  )
}

export default FormRadioGroup
