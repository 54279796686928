// JsFromRoutes CacheKey 504990a80a873ae849bf8136610a81b0
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/app/creator/link-in-bio/blocks'),
  create: /* #__PURE__ */ definePathHelper('post', '/app/creator/link-in-bio/blockables'),
  edit: /* #__PURE__ */ definePathHelper('get', '/app/creator/link-in-bio/blockables/:block_id/edit'),
  new: /* #__PURE__ */ definePathHelper('get', '/app/creator/link-in-bio/blockables/new'),
  update: /* #__PURE__ */ definePathHelper('patch', '/app/creator/link-in-bio/blockables/:id'),
}
