import { TrackingCodes } from '@/types'
import React from 'react'

interface InjectedBodyScriptsProps {
  trackingCodes: TrackingCodes
}

const InjectedBodyScripts: React.FC<InjectedBodyScriptsProps> = ({
  trackingCodes,
}) => {
  if (!trackingCodes.footerCode) {
    return null
  }

  return <div dangerouslySetInnerHTML={{ __html: trackingCodes.footerCode }} />
}

export default InjectedBodyScripts
