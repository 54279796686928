import { useSortable } from '@dnd-kit/sortable'
// import {CSS} from '@dnd-kit/utilities'
import BlockRow from './block-row'
import Block from '@/types/blocks/block'

export default function SortableBlockRow({
  block,
  icon,
  label,
}: {
  block: Block
  icon: string
  label: string
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    // transform,
    // transition,
  } = useSortable({ id: block.id })

  // const style = {
  //   transform: CSS.Transform.toString(transform),
  //   transition,
  // }

  return (
    <BlockRow
      ref={setNodeRef}
      // style={style}
      {...attributes}
      {...listeners}
      block={block}
      icon={icon}
      label={label}
    />
  )
}
