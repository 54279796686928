import { FaqList } from '@/components/shared/faq-list'
import Meta from '@/components/shared/meta'
import { Input } from '@/components/ui/input'
import { APP_NAME } from '@/constants'
import useTypedPage from '@/hooks/use-typed-page'
import { DollarSign } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const faqList = [
  {
    question: 'What is a Revenue Split Calculator?',
    answer:
      'A Revenue Split Calculator helps you determine the net earnings of a creator after deducting platform and payment processor fees from the product price.',
  },
  {
    question: 'How do I calculate my net earnings?',
    answer:
      'To calculate your net earnings, input the product price, platform fee percentage, and payment processor fee. The calculator will deduct these fees from the product price to give you the net earnings.',
  },
  {
    question: 'What is the platform fee?',
    answer:
      'The platform fee is a percentage of the product price that the platform charges for using its services.',
  },
  {
    question: 'What is the payment processor fee?',
    answer:
      'The payment processor fee is a percentage of the product price that the payment processor charges for handling the transaction.',
  },
  {
    question: 'Why is it important to calculate net earnings?',
    answer:
      'Calculating net earnings helps you understand how much money you will actually take home after all fees are deducted, allowing you to make informed decisions about your creator business.',
  },
]

const RevenueSplitCalculatorPage: React.FC = () => {
  const { name, description } = useTypedPage().props
  const [productPrice, setProductPrice] = useState<number>(100)
  const [platformFee, setPlatformFee] = useState<number>(10) // percentage
  const [processorFee, setProcessorFee] = useState<number>(2.9) // percentage
  const [netEarnings, setNetEarnings] = useState<string>('87.10')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const calculateNetEarnings = () => {
    const platformFeeAmount = (productPrice * platformFee) / 100
    const processorFeeAmount = (productPrice * processorFee) / 100
    const calculatedNetEarnings =
      productPrice - platformFeeAmount - processorFeeAmount
    setNetEarnings(formatter.format(calculatedNetEarnings))
  }

  useEffect(() => {
    calculateNetEarnings()
  }, [productPrice, platformFee, processorFee])

  const metaTitle = `${name} | ${APP_NAME}`
  const metaDescription = description as string

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} />
      <div className="container mx-auto max-w-5xl">
        <div className="overflow-hidden">
          <div className="mx-auto mb-6 max-w-3xl text-center">
            <h1 className="mb-5 font-heading text-5xl font-medium">
              Revenue Split Calculator
            </h1>
            <p className="text-neutral-500">
              Calculate your net earnings below by providing the product price,
              platform fee percentage, and payment processor fee.
            </p>
          </div>
          <div className="rounded-md border border-border bg-accent p-5">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
              <div className="col-span-1">
                <label htmlFor="product_price" className="font-semibold">
                  Product Price (USD)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What is the price of your product?
                </div>
                <Input
                  type="number"
                  id="product_price"
                  value={productPrice}
                  onChange={(e) => setProductPrice(Number(e.target.value))}
                  placeholder="Product Price"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="platform_fee" className="font-semibold">
                  Platform Fee Percentage (%)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What percentage does the platform take?
                </div>
                <Input
                  type="number"
                  id="platform_fee"
                  value={platformFee}
                  onChange={(e) => setPlatformFee(Number(e.target.value))}
                  placeholder="Platform Fee (%)"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="processor_fee" className="font-semibold">
                  Payment Processor Fee Percentage (%)
                </label>
                <div className="mb-2 text-sm text-neutral-500">
                  What percentage does the payment processor take?
                </div>
                <Input
                  type="number"
                  id="processor_fee"
                  value={processorFee}
                  onChange={(e) => setProcessorFee(Number(e.target.value))}
                  placeholder="Processor Fee (%)"
                  className="form-field__input"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="mt-6 rounded-md border border-border bg-background px-4 py-5 sm:p-6">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div className="mb-4 sm:mb-0">
                  <h3 className="mb-2 text-lg font-medium">
                    <DollarSign className="mr-2 inline-block" />
                    Your Net Earnings are:
                  </h3>
                  <div className="text-3xl font-bold text-primary">
                    <span id="net-earnings-value">{netEarnings}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FaqList faqList={faqList} />
      </div>
    </>
  )
}

export default RevenueSplitCalculatorPage
