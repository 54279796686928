import * as ReactRadioGroup from '@radix-ui/react-radio-group'
import { Circle } from 'lucide-react'

import { cn } from '@/utils'
import { Option } from './form-radio-group'

interface FormRadioGroupItemProps {
  option: Option
  className?: string
}

const FormRadioGroupItem = ({ option, className }: FormRadioGroupItemProps) => {
  return (
    <ReactRadioGroup.Item
      id={option.value}
      value={option.value}
      className={cn(
        'aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
    >
      <ReactRadioGroup.Indicator className="flex items-center justify-center">
        <Circle className="h-2.5 w-2.5 fill-current text-current" />
      </ReactRadioGroup.Indicator>
    </ReactRadioGroup.Item>
  )
}

export default FormRadioGroupItem
